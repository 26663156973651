import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const Input = styled('input')({
    display: 'none',
});

export default function InteractiveFile({ label, action, actionUrl, defaultDistricts, dataEndpoint, nameField, field, identifier, rowProps }) {
    const [open, setOpen] = useState(false);
    const [vertical] = useState('top');
    const [horizontal] = useState('left');
    const [savedIrns, setSavedIrns] = useState();
    const [csvIrns, setCsvIrns] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const validateDistricts = (data) => {
        let intTypedIrns = data.map(irn => Number(irn));
        return defaultDistricts.filter(district =>
            intTypedIrns.includes(district.value)
        );
    }

    const parseData = data => {
        let csvData = [];
        let lbreak = data.split("\n");
        lbreak.forEach(res => {
            let columns = res.split(",");
            csvData.push(columns[0]);
        });

        return csvData;
    }

    const onFileChange = (event) => {
        if (event.target.files) {
            let reader = new FileReader();
            reader.readAsBinaryString(event.target.files[0]);
            reader.onload = function (e) {
                let availableDistricts = parseData(e.target.result);
                let availableIrns = validateDistricts(availableDistricts);

                dataEndpoint[field] = availableIrns;

                // Update row with irns parsed
                rowProps.onChange(availableIrns);
                // Display alert if no irns to set from csv file.
                if (availableIrns.length === 0) return  alert('CSV File not valid, no irns to set')

                setCsvIrns(availableDistricts.length);
                setSavedIrns(availableIrns.length);

                if(dataEndpoint.uuid) {
                    delete dataEndpoint['tableData'];
                    axios.put(`${actionUrl}?${identifier}=eq.${dataEndpoint[identifier]}`, dataEndpoint, {
                      headers: {
                        'ngrok-skip-browser-warning': true
                      }
                    })
                        .then(response => {
                            setOpen(true);
                        })
                        .catch(error => {
                            alert(error.response.data.details);
                        })
                }
            }
        }
    };

    const exportToCsv = (filename, rows) => {
        if (!rows) {
            rows = defaultDistricts;
        }

        var csvFile = rows.map(function (d) {
            let fixOrder = [];
            fixOrder.push(d.value, '"' + d.label + '"');
            return fixOrder.join();
        }).join('\n');

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    let downloadName = 'export-' + nameField + '-Irns.csv';

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            {
                action === 'Upload' &&
                <>
                    <Input accept=".csv" id="icon-upload-file" type="file" onChange={onFileChange} />
                    <label htmlFor="icon-upload-file">
                        <IconButton color="primary" component="span">
                            <FileUploadIcon />
                        </IconButton>
                    </label>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        autoHideDuration={12000}
                        onClose={handleClose}
                        key={vertical + horizontal}
                    >
                        <MuiAlert severity="info">{`The file contains ${csvIrns} records of which ${savedIrns} are valid and have been saved.`}</MuiAlert>
                    </Snackbar>
                </>}
            {
                action === 'Download' &&
                <>
                    <IconButton color="primary" component="span" onClick={() => exportToCsv(downloadName, dataEndpoint[field])} >
                        <FileDownloadIcon />
                    </IconButton>
                </>}
        </Stack>
    );
}
