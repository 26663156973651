import React, {useState, useEffect, useRef, useContext} from 'react';
import '../../App.scss';
import { styled, ThemeProvider } from "@mui/material/styles";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import ScatterChart from '../../components/ScatterChart/ScatterChart';
import BubbleChart from '../../components/BubbleChart/BubbleChart';
import HistogramChart from "../../components/HistogramChart/HistogramChart";
import {
  fetchById,
  simpleFetch,
  clearHooks,
  normalizeDistributionData,
  localHour,
  buildOnLoad
} from "../../utils/helpsers";
import MapView from "../../components/MapChart/MapView";
import Button from "@mui/material/Button";
import RangeSlider from "../../components/RangeSlider/RangeSlider"
import ApaReferenceAccordion from "../../components/ApaReference/ApaReferenceAccordion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LayoutPage from "../../layout/LayoutPage";
import SaveGroupForm from "../../components/SaveGroup/SaveGroup";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import BoardBuilder from "../../components/BoardBuilder/BoardBuilder";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useQuery from "../../utils/userLocation";

import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Select from '../../components/Select/Select';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import axios from 'axios';
import customTheme from '../../utils/theme';

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import {TokenContext} from "../../utils/Context";


HighchartsMore(Highcharts)

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.color.secondary,
  fontWeight: 'bold',
  marginTop: 10,
  '&:hover': {
    backgroundColor: theme.color.secondary,
  },
}));

const MainButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "5px",
  border: "1px solid #773DBD",
  '&:hover': {
    backgroundColor: "#773DBD",
  }
}));

const SecondaryButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "5px",
  marginLeft: "5px"
}));

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0)",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: "11px",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    top:"131px !important",
    left:"91px",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: "#221349",
    color: 'white',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },

    "fieldset": {
      borderColor: "white",
    },

    " input, label, svg": {
      color: "white",
    }
  },
}));

const BaseIcon = (muiIcon) => {
  return styled(muiIcon)(({ theme }) => ({
    cursor: "pointer",
    fontSize: "24px"
  }));
};

const SaveBoardIcon = BaseIcon(SaveOutlinedIcon);
const ShareBoardIcon = BaseIcon(ShareOutlinedIcon);
const FilterIcon = BaseIcon(FilterAltOutlinedIcon);

function DistrictExplorer() {
  // User session metadata and data
  const { user } = useAuth0();
  const metadata = user[`${process.env.REACT_APP_AUTH0_KEY}/district`];
  const enabledShare = metadata.enable_share;

  // User session token
  const {userToken} = useContext(TokenContext);

  const history = useHistory();
  const query = useQuery();

  // Object value/label from select component
  const [districtInfo, setDistrictInfo] = useState();
  const [groupInfo, setGroupInfo] = useState();
  // Radio selected value
  const [groupType, setGroupType] = useState();

  const [groupCount, setGroupCount] = useState()

  const [DomainGroup, setDomainGroup] = useState();
  const [axisX, setAxisX] = useState(null);
  const [xFooterData, setXFooterData] = useState();
  const [axisY, setAxisY] = useState(null);
  const [yFooterData, setYFooterData] = useState();
  const [axisZ, setAxisZ] = useState(null);
  const [zFooterData, setZFooterData] = useState();

  const [scatterData, setScatterData] = useState();
  const [irns, setIrns] = useState();
  const [histogramX, setHistogramX] = useState();
  const [histogramY, setHistogramY] = useState();
  const [histogramZ, setHistogramZ] = useState();
  const [varZEnabled, setVarZEnabled] = useState(false);

  const [year, setYear] = useState({});

  const [boardData, setBoardData] = useState();
  const [existingBoard, setExistingBoard] = useState();

  //Data for Map
  const [mapViewData, setMapViewData] = useState();

  // X-Y-Z Ranges Group
  const [statsVariableX, setStatsVariableX] = useState();
  const [statsVariableY, setStatsVariableY] = useState();
  const [statsVariableZ, setStatsVariableZ] = useState();

  // Range set with slider component
  const [xCustomRange, setXCustomRange] = useState();
  // Range set with slider component
  const [yCustomRange, setYCustomRange] = useState();
  // Range set with slider component
  const [zCustomRange, setZCustomRange] = useState();

  const [boardsName, setBoardsName] = useState(null);
  const [boardsRepeated, setBoardsRepeated] = useState(false);

  const [showFilters, setShowFilters] = useState(true);

  const [postResponse, setPostResponse] = useState(null);
  const [repeatedError, setRepeatedError] = useState(false);
  const [displayResponseMessage, setDisplayResponseMessage] = useState(false);

  const handleFilter = () => {
    setBtnFilter(null)
  }

  // Set the board version
  const setBoardVersion = (
    groupCountVar,
    yearVar,
    irnsVar,
    groupTypeVar,
    groupInfoVar,
    xCustomRangeVar,
    axisXVar,
    statsVariableXVar,
    histogramXVar,
    yCustomRangeVar,
    axisYVar,
    statsVariableYVar,
    histogramYVar,
    zCustomRangeVar,
    axisZVar,
    statsVariableZVar,
    histogramZVar
  ) => {
    setGroupCount(groupCountVar);
    setYear(yearVar);
    setIrns(irnsVar);
    setGroupType(groupTypeVar)
    setGroupInfo(groupInfoVar);
    // X axis data.
    setXCustomRange(xCustomRangeVar);
    setAxisX(axisXVar);
    setStatsVariableX(statsVariableXVar);
    setHistogramX(histogramXVar);
    // Y axis data.
    setYCustomRange(yCustomRangeVar);
    setAxisY(axisYVar);
    setStatsVariableY(statsVariableYVar)
    setHistogramY(histogramYVar);
    // Z axis data.
    setZCustomRange(zCustomRangeVar);
    setAxisZ(axisZVar);
    setStatsVariableZ(statsVariableZVar)
    setHistogramZ(histogramZVar);
  }

  const [btnFilter, setBtnFilter] = useState(null);
  const [btnSaved, setBtnSaved] = useState(null);
  const [btnShared, setBtnShared] = useState(null);

  const openFilters = Boolean(btnFilter);
  const openSaved = Boolean(btnSaved);
  const openShared = Boolean(btnShared);

  const [activeVersion, setActiveVersion] = useState(null);
  const [lastVersion, setLastVersion] = useState(null);
  const [sortedVersions, setSortedVersions] = useState([]);

  const Text = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root, MuiOutlinedInput-input': {
      color: '#black',
    },

    '& .MuiInputLabel-root': {
      color: '#black',
      top: '0px',
    },

    '& label.Mui-focused': {
      color: '#black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#207ad3',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#black',
      },
      '&:hover fieldset': {
        borderColor: '#black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#black',
      },
    },
  }));

  useEffect(() => {
    if (!lastVersion && existingBoard) {
      let unsorted = Object.keys(existingBoard.data);
      unsorted = unsorted.map(version => {
        let label = version === existingBoard.data[version]['created_at'] ? `${localHour(version)}` : `${version} - ${localHour(existingBoard.data[version]['created_at'])}`;
        return { 'label': label, 'value': version, "datetime": existingBoard.data[version]['created_at'] }
      }
      );
      let sortedDates = unsorted.sort((a, b) => a.datetime - b.datetime);

      let lastVersion = sortedDates[sortedDates.length - 1];

      setSortedVersions(sortedDates);
      setActiveVersion(lastVersion);
      if (lastVersion) {
        boardVersion.current = lastVersion.value;
      }
      setLastVersion(lastVersion);
    }
  }, [existingBoard, lastVersion]);

  const clickFilters = (event) => {
    setBtnFilter(event.currentTarget);
  };

  const closeFilters = () => {
    setBtnFilter(null);
  };

  const clickSaved = (event) => {
    setBtnSaved(event.currentTarget);
  };

  const closeSaved = () => {
    setBtnSaved(null);
  };

  const clickShared = (event) => {
    setBtnShared(event.currentTarget);
  };

  const closeShared = () => {
    setBtnShared(null);
  };

  const [activeUser, setActiveUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [usersFormat, setUsersFormat] = useState([]);
  const [shareWith, setShareWith] = useState([]);

  const [action, setAction] = useState();

  const shareWithUsers = () => {
    if (existingBoard) {
      if (shareWith.length === 0) {
        setDisplayResponseMessage(true);
        setPostResponse(true);
        closeShared();
      } else {
        let uuid = existingBoard.uuid;
        let bodyJson = existingBoard;

        bodyJson["available_users_share"] = shareWith;

        bodyJson = JSON.stringify(bodyJson);

        fetch(
          `${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${uuid}`,
          {
            method: "PUT",
            headers: {
              "ngrok-skip-browser-warning": true, 
              Authorization: "Bearer " + userToken,
              "Content-Type": "application/json",
            },
            body: bodyJson,
          }
        ).then((response) => {
          setDisplayResponseMessage(true);
          setPostResponse(response);
          closeShared();
        });
      }
    }
  };

  useEffect(() => {
    if (usersFormat.length === 0) {
      async function getGroups() {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/groups`, 
          { 
            headers: {
              'ngrok-skip-browser-warning': true
            }
          });
        return response.data;
      }

      getGroups().then( groups => {
        setUserGroups(groups);
        axios.get(`${process.env.REACT_APP_API_URL}/users`, {
          headers: {
            'ngrok-skip-browser-warning': true  
          }
        }
        )
            .then(res => {
              setUsers(res.data);

              let currentUser = res.data.find((element, index) => {
                let valid = false;

                if (element.email === user.email) {
                  valid = true;
                }

                return valid;
              });

              setActiveUser(currentUser);
            });

        let filteredUsers = users.filter((userF) => userF.group_id === activeUser.group_id ? userF : false);
        let usersFormatted = filteredUsers.map( (userF) => {
          let userGroup = userGroups.find(userGroup => userGroup.id === userF.group_id);
          let filteredResult = null;
          if (userF && userGroup) {
            filteredResult = { 'value': userF.email, 'label': userF.name + ' @ ' + userGroup.name };
          }
          return filteredResult;
        });
        setUsersFormat(usersFormatted);
      });
    }
  }, [activeUser.group_id, user.email, userGroups, users, usersFormat.length]);

  const handleChange = (selection) => {
    if (existingBoard.data[selection.value].histogramsData) {
      setActiveVersion(selection)
      // Cleaning group info hook.
      let selectedVersion = existingBoard.data[selection.value];

      setBoardVersion(
        selectedVersion.groupCount,
        selectedVersion.year,
        selectedVersion.irns,
        selectedVersion.groupType,
        undefined,
        null,
        null,
        selectedVersion.statsVariables.statsVariableX,
        selectedVersion.histogramsData.histogramX,
        null,
        null,
        selectedVersion.statsVariables.statsVariableY,
        selectedVersion.histogramsData.histogramY,
        null,
        null,
        selectedVersion.statsVariables.statsVariableZ,
        selectedVersion.histogramsData.histogramZ
      );

      setTimeout(() => setBoardVersion(
        selectedVersion.groupCount,
        selectedVersion.year,
        selectedVersion.irns,
        selectedVersion.groupType,
        selectedVersion.groupInfo,
        selectedVersion.sliderRanges.xRange,
        selectedVersion.axisVars.axisX,
        selectedVersion.statsVariables.statsVariableX,
        selectedVersion.histogramsData.histogramX,
        selectedVersion.sliderRanges.yRange,
        selectedVersion.axisVars.axisY,
        selectedVersion.statsVariables.statsVariableY,
        selectedVersion.histogramsData.histogramY,
        selectedVersion.sliderRanges.zRange,
        selectedVersion.axisVars.axisZ,
        selectedVersion.statsVariables.statsVariableZ,
        selectedVersion.histogramsData.histogramZ
      ), 100);
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/custom_boards?created_by=eq.${user.email}`,
        { headers: {
            'ngrok-skip-browser-warning': true  
          }
        }
      )
      .then((res) => {
        setBoardsName(res.data);
      });
  }, [user.email]);

  const changeName = () => {
    if (existingBoard) {
      let uuid = existingBoard.uuid;
      let bodyJson = existingBoard;

      bodyJson.label = boardName.current;
      bodyJson = JSON.stringify(bodyJson);
      let boardsLength = Object.keys(boardsName).length;

      for (const [key, value] of Object.entries(boardsName)) {
        if (value.label === boardName.current) {
          setRepeatedError(true);
          setBoardsRepeated(true);
          break;
        }
        if (+key + 1 === boardsLength) {
          fetch(
            `${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${uuid}`,
            {
              method: "PUT",
              headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + userToken,
                "Content-Type": "application/json",
              },
              body: bodyJson,
            }
          ).then(() => {
            setAction("");
          });
        }
      }
    }
  };

  const changeVersion = () => {
    if (existingBoard) {
      let uuid = existingBoard.uuid;
      let bodyJson = existingBoard;

      if (action === 'edit-version' && existingBoard.data[activeVersion.value]['done_by'] === user.email) {
        bodyJson.data[boardVersion.current] = bodyJson.data[activeVersion.value];
        if (boardVersion.current !== activeVersion.value) {
          delete bodyJson.data[activeVersion.value];
        }
      }

      bodyJson = JSON.stringify(bodyJson);

      fetch(`${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${uuid}`, {
        method: 'PUT',
        headers: {
          'ngrok-skip-browser-warning': true, 
          'Authorization': 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        },
        body: bodyJson
      }).then(() => {
        window.location.reload();
      })
    }
  }

  const boardName = useRef('');
  const boardVersion = useRef('');

  // Reference district request.
  useEffect(() => {
    if (metadata) {
      const userSessionType = metadata.hasOwnProperty('sessionType') ? metadata.sessionType : 'forbidden';

      switch (userSessionType) {
        case 'district-access':
          window.localStorage.setItem('session-type', 'district-access');
          break;
        case 'full-access':
          window.localStorage.setItem('session-type', 'full-access');
          break;
        case 'multiple-district-access':
          window.localStorage.setItem('session-type', 'multiple-district-access');
          break;
        case 'forbidden':
          history.push('/AccessDenied');
          break;
        default:
          history.push('/AccessDenied');
          break;
      }
    } else {
      history.push('/AccessDenied');
    }
  }, [history, user, metadata]);

  /**
   * Range selectors data request.
   * On change X axis variable, the range slider data will be the variable historical related with district's in the groups.
   */
  useEffect(() => {
    if (axisX && !xCustomRange) {
      setStatsVariableX();
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_stats?_var=${axisX.value_var}&_irn=${districtInfo.value}&_irns=${irns}&_year=${year.value}`)
        .then(data => setTimeout(() => {
          setStatsVariableX([data[0].min_var, data[0].max_var]);
          setXCustomRange([data[0].min_var, data[0].max_var])
        }, 10));
      // Set distribution data for X axis histogram chart.
      normalizeDistributionData({ varName: axisX.value_var, varIrn: districtInfo.value, irns: irns, hook: setXFooterData, year: year.value }, userToken);
    }
  }, [axisX, districtInfo, irns, year, xCustomRange, userToken])

  /**
   * Range selectors data request.
   * On change Y axis variable, the range slider data will be the variable historical related with district's in the groups.
   */
  useEffect(() => {
    if (axisY && !yCustomRange) {
      setStatsVariableY();
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_stats?_var=${axisY.value_var}&_irn=${districtInfo.value}&_irns=${irns}&_year=${year.value}`)
        .then(data => setTimeout(() => {
          setStatsVariableY([data[0].min_var, data[0].max_var]);
          setYCustomRange([data[0].min_var, data[0].max_var]);
        }, 10))
      // Set distribution data for Y axis histogram chart.
      normalizeDistributionData({ varName: axisY.value_var, varIrn: districtInfo.value, irns: irns, hook: setYFooterData, year: year.value }, userToken);
    }
  }, [axisY, districtInfo, irns, year, yCustomRange, userToken])

  /**
   * Range selectors data request.
   * On change Z axis variable, the range slider data will be the variable historical related with district's in the groups.
   */
  useEffect(() => {
    if (axisZ && !zCustomRange) {
      setStatsVariableZ();
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_stats?_var=${axisZ.value_var}&_irn=${districtInfo.value}&_irns=${irns}&_year=${year.value}`)
        .then(data => setTimeout(() => {
          setStatsVariableZ([data[0].min_var, data[0].max_var]);
          setZCustomRange([data[0].min_var, data[0].max_var]);
        }, 10));
      // Set distribution data for Y axis histogram chart.
      normalizeDistributionData({ varName: axisZ.value_var, varIrn: districtInfo.value, irns: irns, hook: setZFooterData, year: year.value }, userToken);
    }
  }, [axisZ, districtInfo, irns, year, zCustomRange, userToken])

  const updateRanges = (newRange, hook) => {
    hook(newRange);
  }

  useEffect(() => {
    let year_value = year.value ? year.value : 2020;

    simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_source_variable?file_year=eq.${year_value}&visible=eq.true&order=label.asc`)
      .then(data => {
        setDomainGroup(data)
      });
  }, [year])

  // Getting group count and adding district selected irn to irn's group
  useEffect(() => {
    if (groupInfo && districtInfo) {
      let year_value = year.value ? year.value : 2020;

      simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_source_variable?file_year=eq.${year_value}&visible=eq.true&order=label.asc`)
        .then(data => setDomainGroup(data));

      let groupId = groupInfo.label;
      let urlGroupSelected = `${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&label=eq.`;

      if (groupType.value === 'Enrollment+Brackets') {
        groupId = groupInfo.value;
        urlGroupSelected = `${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&value=eq.`;
      }
      // Getting group count.
      if (groupType.value === 'custom_groups') {
        let varKey = (Object.keys(groupInfo.custom_ranges).length);
        let varData = groupInfo.custom_ranges[`var${varKey}`];
        setGroupCount(varData.districtsCount);
        setIrns([...varData.irns, districtInfo.value]);
      } else if (groupType.value === 'custom_boards') {
        setBoardVersion(
          groupInfo.data.groupCount,
          groupInfo.data.year,
          groupInfo.data.irns,
          groupType,
          groupInfo,
          groupInfo.data.sliderRanges.xRange,
          groupInfo.data.axisVars.axisX,
          groupInfo.data.statsVariables.statsVariableX,
          groupInfo.data.histogramsData.histogramX,
          groupInfo.data.sliderRanges.yRange,
          groupInfo.data.axisVars.axisY,
          groupInfo.data.statsVariables.statsVariableY,
          groupInfo.data.histogramsData.histogramY,
          groupInfo.data.sliderRanges.zRange,
          groupInfo.data.axisVars.axisZ,
          groupInfo.data.statsVariables.statsVariableZ,
          groupInfo.data.histogramsData.histogramZ
        );
      } else {
        // @TODO BackEnd must update the group ID label to remove the 's.
        groupId = groupId === "All Ohio School Districts" ? "All Ohio's School Districts" : groupId;

        fetchById(urlGroupSelected, groupId)
          .then(data => {
            setGroupCount(data[0].count);
            data[0].irns = data[0].irns + `,${districtInfo.value}`;
            setIrns(data[0].irns);
          });
      }
    }
  }, [groupInfo, districtInfo, groupType, year]);

  /**
   * Updating X axis histogram chart.
   * When a custom board is loaded this effect is not required. The data will be set by the custom board loaded.
   */
  useEffect(() => {
    let histogramXData = { "categories": [], "data": [], "district": {} };

    if (axisX && irns && xCustomRange) {
      // X select data
      fetch(`${process.env.REACT_APP_API_URL}/hvd_tabloide?select=irn,x:${axisX.value_var},district_name,irn&irn=in.(${irns})&file_year=eq.${year.value}&order=${axisX.value_var}.desc`, { headers: { 'Authorization': 'Bearer ' + userToken, 'ngrok-skip-browser-warning': true   } })
        .then(response => {
          setHistogramX('loading')
          return response.json()
        })
        .then(data => data.forEach(item => {
          histogramXData["categories"].push(item.district_name);
          if (item.irn === districtInfo.value) {
            histogramXData["data"].push({ y: item.x, selected: true, color: '#F5B335' });
            histogramXData["district"] = item;
          } else {
            histogramXData["data"].push(item.x);
          }
        }))
        .then(() => setHistogramX(histogramXData))
        .catch(() => setHistogramX('no-data'));
    }
  }, [axisX, irns, districtInfo, year, xCustomRange, userToken])

  /**
   * Updating Y axis histogram chart.
   * When a custom board is loaded this effect is not required. The data will be set by the custom board loaded.
   */
  useEffect(() => {
    let histogramYData = { "categories": [], "data": [], "district": {} };
    if (axisY && irns && yCustomRange) {
      // X select data
      fetch(`${process.env.REACT_APP_API_URL}/hvd_tabloide?select=irn,y:${axisY.value_var},district_name&irn=in.(${irns})&file_year=eq.${year.value}&order=${axisY.value_var}.desc`, { headers: { 'Authorization': 'Bearer ' + userToken, 'ngrok-skip-browser-warning': true } })
        .then(response => {
          setHistogramY('loading')
          return response.json()
        })
        .then(data => data.forEach(item => {
          histogramYData["categories"].push(item.district_name);
          if (item.irn === districtInfo.value) {
            histogramYData["data"].push({ y: item.y, selected: true, color: '#F5B335' });
            histogramYData["district"] = item;
          } else {
            histogramYData["data"].push(item.y);
          }
        }))
        .then(() => setHistogramY(histogramYData))
        .catch(() => setHistogramY('no-data'));
    }
  }, [axisY, irns, districtInfo, year, yCustomRange, userToken])

  /**
   * Updating Z axis histogram chart.
   * When a custom board is loaded this effect is not required. The data will be set by the custom board loaded.
   */
  useEffect(() => {
    let histogramZData = { "categories": [], "data": [], "district": {} };
    if (axisZ && irns && zCustomRange) {
      // X select data
      fetch(`${process.env.REACT_APP_API_URL}/hvd_tabloide?select=irn,z:${axisZ.value_var},district_name&irn=in.(${irns})&file_year=eq.${year.value}&order=${axisZ.value_var}.desc`, { headers: { 'Authorization': 'Bearer ' + userToken, 'ngrok-skip-browser-warning': true } })
        .then(response => {
          setHistogramZ('loading')
          return response.json()
        })
        .then(data => data.forEach(item => {
          histogramZData["categories"].push(item.district_name);
          if (item.irn === districtInfo.value) {
            histogramZData["data"].push({ y: item.z, selected: true, color: '#F5B335' });
            histogramZData["district"] = item;
          } else {
            histogramZData["data"].push(item.z);
          }
        }))
        .then(() => setHistogramZ(histogramZData))
        .catch(() => setHistogramZ('no-data'));
    }
  }, [axisZ, irns, districtInfo, year, zCustomRange, userToken])

  // Updating Scatter Chart.
  useEffect(() => {
    if (axisX && axisY && axisZ && irns) {
      simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_tabloide?select=irn,x:${axisX.value_var},y:${axisY.value_var},z:${axisZ.value_var},district_name&irn=in.(${irns})&file_year=eq.${year.value}`)
        .then(data => data.forEach(item => {
          if (item.irn === districtInfo.value) {
            item.selected = true
            return setScatterData(data);
          }
        }))
        .catch(() => setScatterData('no-data'));
    }
  }, [axisX, axisY, axisZ, irns, districtInfo, year])

  useEffect(() => {
    if (irns && Object.keys(year).length > 0) {
      //Getting data Map for group
      simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_polygons?irn=in.(${irns})&file_year=eq.${year.value}`)
        .then(polygons => {
          setMapViewData(polygons.map(item => {
            return {
              ...item,
              color: '#4646FF'
            }
          }));
        });
    }
  }, [irns, year]);

  let zAxisDefinition = 'Select a data point to display as a bubble by size on the bottom graph'
  let slideHelpText = 'Slide to select the range you wish to compare'

  useEffect(() => {
    if (axisX && axisY !== undefined) {
      window._paq.push(["trackGoal", 7]);
    }
  }, [axisX, axisY]);

  useEffect(() => {
    if (query.get('shared') && DomainGroup && !groupInfo) {
      setShowFilters(false);

      simpleFetch(`${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.` + query.get('shared'))
        .then((customBoards) => {
          let boardRetrieve = customBoards[0];

          if ((boardRetrieve.available_users_share && boardRetrieve.available_users_share.some(userShare => userShare.value === user.email)) || boardRetrieve.created_by === user.email) {
            let unsorted = Object.keys(customBoards[0].data);
            let sortedDates = unsorted.sort((a, b) => a - b);
            let lastVersion = sortedDates[sortedDates.length - 1];

            let boardRetrieve = "irns" in customBoards[0].data ? customBoards[0].data : customBoards[0].data[lastVersion];
            setExistingBoard(customBoards[0]);
            boardName.current = customBoards[0].label;

            if (customBoards[0]['available_users_share']) {
              setShareWith(customBoards[0]['available_users_share']);
            }
            if (boardRetrieve.referenceDistrict) {
              setDistrictInfo(boardRetrieve.referenceDistrict);
            } else {
              setDistrictInfo({
                "value": 45187,
                "label": "Ada Ex Vill SD, Hardin (045187)"
              });
            }

            setBoardVersion(
              boardRetrieve.groupInfo.count,
              boardRetrieve.year,
              boardRetrieve.irns,
              boardRetrieve.groupType,
              boardRetrieve.groupInfo,
              boardRetrieve.sliderRanges.xRange,
              boardRetrieve.axisVars.axisX,
              boardRetrieve.statsVariables.statsVariableX,
              boardRetrieve.histogramsData.histogramX,
              boardRetrieve.sliderRanges.yRange,
              boardRetrieve.axisVars.axisY,
              boardRetrieve.statsVariables.statsVariableY,
              boardRetrieve.histogramsData.histogramY,
              boardRetrieve.sliderRanges.zRange,
              boardRetrieve.axisVars.axisZ,
              boardRetrieve.statsVariables.statsVariableZ,
              boardRetrieve.histogramsData.histogramZ
            );
          } else {
            history.push('/AccessDenied');
          }
        });
    }
        // Build board on load if no query found.
        if(!query.get('shared') && !groupInfo) {
          // Build .
          buildOnLoad({user: user, callback: setBoardData, token: userToken});
      }
  }, [query, DomainGroup, groupInfo, history, user, userToken]);

  useEffect(() => {
    if (boardData) {
      setDistrictInfo(boardData.referenceDistrict);
      setYear(boardData.year);
      setGroupType(boardData.groupType)
      setGroupInfo(boardData.groupInfo);
      setIrns(boardData.irns)
      setGroupCount(boardData.groupCount);
    }
  }, [boardData])

  return (
    <LayoutPage
      pageTitle={
        districtInfo ? (
          <>
            District Explorer:&nbsp;{" "}
            <span className="district-name clr-secondary">
              {districtInfo.label}
            </span>
          </>
        ) : (
          "District Explorer"
        )
      }
      description="Build custom groups based on filters"
      type="dashboard"
      customClass="district-explorer"
    >
      <div className="header__bottom">
        <div className="layout-wrapper">
          <section
            className={`section section--data-filters ${
              showFilters
                ? "district-filters--active"
                : "district-filters--hidden"
            }`}
          >
            <Grid
              container
              className={`align-container ${
                showFilters ? "basicFilters" : "sharedFilters"
              }`}
              spacing={0}
              textAlign="center"
              style={{ marginBottom: 20 }}
            >
              <Grid item xs={5} sm={2} display="flex">
                <TooltipStyled title="Filters">
                  <MainButton
                    id="button-filters"
                    aria-controls={openFilters ? "menu-filters" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilters ? "true" : undefined}
                    variant="contained"
                    onClick={clickFilters}
                    style={openFilters ? { backgroundColor: "#221349", borderColor: "#221349" } : {backgroundColor: "#773DBD"}}
                  >
                    <FilterIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"} sx={{marginBottom:0}} variant="caption" display="block" gutterBottom>
                      Filters
                    </Typography>
                  </MainButton>
                </TooltipStyled >
                <StyledMenu
                  id="menu-filters"
                  MenuListProps={{
                    "aria-labelledby": "button-filters",
                  }}
                  anchorEl={btnFilter}
                  open={openFilters}
                  onClose={closeFilters}
                >
                  <MenuItem disableRipple>
                    <BoardBuilder
                      group={true}
                      year={true}
                      boardData={setBoardData}
                      onSubmit={() => handleFilter()}
                    />
                  </MenuItem>
                </StyledMenu>
                {enabledShare && (
                  <>
                  <TooltipStyled  title="Save Board">
                    <SecondaryButton
                      id="button-saved"
                      aria-controls={openSaved ? "menu-saved" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openSaved ? "true" : undefined}
                      variant="contained"
                      disabled={!districtInfo ? true : false}
                      style={districtInfo ? {border: "1px solid #773DBD"} : { border: "1px solid #808080"}}
                      onClick={clickSaved}
                    >
                      <SaveBoardIcon
                        style={{
                          color:
                            boardData || existingBoard ? "#773DBD" : "#BFBDBD",
                        }}
                      />
                    </SecondaryButton>
                  </TooltipStyled >
                    <StyledMenu
                      id="menu-saved"
                      MenuListProps={{
                        "aria-labelledby": "button-saved",
                      }}
                      anchorEl={btnSaved}
                      open={openSaved}
                      onClose={closeSaved}
                    >
                      <MenuItem disableRipple>
                        <div className={"district-insights__actions"}>
                          {groupCount && Object.keys(year).length > 0 && (
                            <SaveGroupForm
                              type={"explorer"}
                              groupData={{
                                irns: irns,
                                groupType: groupType,
                                groupInfo: groupInfo,
                                groupCount: groupCount,
                                year: { value: year.value, label: year.value },
                                axisVars: {
                                  axisX: axisX,
                                  axisY: axisY,
                                  axisZ: axisZ,
                                },
                                sliderRanges: {
                                  xRange: xCustomRange || statsVariableX,
                                  yRange: yCustomRange || statsVariableY,
                                  zRange: zCustomRange || statsVariableZ,
                                },
                                histogramsData: {
                                  histogramX: histogramX,
                                  histogramY: histogramY,
                                  histogramZ: histogramZ,
                                },
                                statsVariables: {
                                  statsVariableX: statsVariableX,
                                  statsVariableY: statsVariableY,
                                  statsVariableZ: statsVariableZ,
                                },
                              }}
                              endpoint={`${process.env.REACT_APP_API_URL}/custom_boards`}
                              layout={true}
                              existingData={existingBoard}
                              onChange={setBoardVersion}
                              onSave={setExistingBoard}
                            />
                          )}
                        </div>
                      </MenuItem>
                    </StyledMenu>
                    <TooltipStyled  title="Share Board">
                      <SecondaryButton
                        id="button-shared"
                        aria-controls={openShared ? "menu-shared" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openShared ? "true" : undefined}
                        variant="contained"
                        disabled={
                          !existingBoard ||
                          existingBoard.created_by !== user.email
                            ? true
                            : false
                        }
                        style={existingBoard ? {border: "1px solid #773DBD"} : { border: "1px solid #808080"}}s
                        onClick={clickShared}
                      >
                        <ShareBoardIcon
                          style={{ color: existingBoard ? "#773DBD" : "#BFBDBD" }}
                        />
                      </SecondaryButton>
                    </TooltipStyled >
                    <StyledMenu
                      id="menu-shared"
                      MenuListProps={{
                        "aria-labelledby": "button-shared",
                      }}
                      anchorEl={btnShared}
                      open={openShared}
                      onClose={closeShared}
                    >
                      <MenuItem disableRipple>
                        {usersFormat && (
                          <Grid container spacing={0} textAlign="center">
                            <Grid item sm={12} display="flex">
                              <Select
                                options={usersFormat}
                                id={"shared-with"}
                                label=""
                                defaultValue={shareWith}
                                isMultiple={true}
                                onChange={(e) => {
                                  setShareWith(e);
                                }}
                              />
                            </Grid>
                            <Grid item sm={12} display="flex">
                              <ThemeProvider theme={customTheme}>
                                <ActionButton
                                  variant="contained"
                                  color="primary"
                                  onClick={() => shareWithUsers()}
                                >
                                  Share with users
                                </ActionButton>
                              </ThemeProvider>
                            </Grid>
                          </Grid>
                        )}
                      </MenuItem>
                    </StyledMenu>
                  </>
                )}
              </Grid>
              {existingBoard && (
                <>
                  <Grid item xs={9} sm={3} className="group-filters__wrapper">
                    {action === "edit-name" ? (
                      <Text
                        autoComplete="off"
                        className="form-field form-field--group-name"
                        id="BoardName"
                        defaultValue={boardName.current}
                        onChange={(e) => (boardName.current = e.target.value)}
                        fullWidth
                        variant="outlined"
                        label="Board Name"
                        required
                      />
                    ) : (
                      <div className="group-counter__result">
                        {existingBoard.label}
                      </div>
                    )}
                  </Grid>
                  <Grid className="editButtonItem" item xs={2} sm={1}>
                    {action === "edit-name" ? (
                      <ThemeProvider theme={customTheme}>
                        <ActionButton
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => changeName()}
                        >
                          Rename Board
                        </ActionButton>
                        <ActionButton
                          className="cancelChangeVersionName"
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => setAction("")}
                        >
                          Cancel
                        </ActionButton>
                      </ThemeProvider>
                    ) : (
                      <IconButton
                        aria-label="done"
                        onClick={() => setAction("edit-name")}
                      >
                        <EditIcon style={{ color: "#221349" }} />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    {action === "edit-version" && activeVersion ? (
                      <Text
                        autoComplete="off"
                        className="form-field form-field--group-name"
                        id="VersionName"
                        defaultValue={boardVersion.current}
                        onChange={(e) =>
                          (boardVersion.current = e.target.value)
                        }
                        fullWidth
                        variant="outlined"
                        label="Version Name"
                        required
                      />
                    ) : (
                      activeVersion && (
                        <Select
                          labelId="data-version"
                          id="data-version"
                          options={sortedVersions}
                          onChange={handleChange}
                          value={activeVersion}
                          label="Version"
                        />
                      )
                    )}
                  </Grid>
                  <Grid className="editButtonItem" item xs={2} sm={1}>
                    {action === "edit-version" ? (
                      <ThemeProvider theme={customTheme}>
                        <ActionButton
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => changeVersion()}
                        >
                          Rename Version
                        </ActionButton>
                        <ActionButton
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => setAction("")}
                        >
                          Cancel
                        </ActionButton>
                      </ThemeProvider>
                    ) : (
                      activeVersion &&
                      existingBoard.data[activeVersion.value] &&
                      existingBoard.data[activeVersion.value]["done_by"] ===
                        user.email && (
                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setAction("edit-version");
                            boardVersion.current = activeVersion.value;
                          }}
                        >
                          <EditIcon style={{ color: "#221349" }} />
                        </IconButton>
                      )
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            {groupInfo && groupCount && (
              <div className="data-filters__item group-counter__wrapper">
                <div className="group-counter">
                  <div className="group-counter__versus">{`${districtInfo.label} - vs - ${groupInfo.label}`}</div>
                  <div className="group-counter__result">{`Year: ${year.label} | ${groupCount} districts in the comparison group.`}</div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>

      <div className="main-content__top layout-wrapper">
        {mapViewData && Object.keys(year).length > 0 && (
          <section className="section section--district-map">
            <MapView
            
              data={mapViewData}
              districtIrn={districtInfo.value}
              height="100%"
              zoom="6.5"
            />
          </section>
        )}
        {Object.keys(year).length > 0 && (
          <section className="section section--extra-filters">
            {(axisX || axisY || axisZ) && (
              <div className="slides-info">
                <TooltipStyled 
                  title={slideHelpText}
                  aria-label={slideHelpText}
                  className={"slides-tooltip"}
                >
                  <InfoOutlinedIcon />
                </TooltipStyled >
              </div>
            )}
            <div className="data-filters__item">
              <Autocomplete
                id={"x-axis"}
                value={axisX}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                className={"form-select"}
                options={DomainGroup}
                fullWidth={true}
                onChange={(event, newValue) => {
                  // Matomo code.
                  if (newValue) {
                    window._paq.push([
                      "trackEvent",
                      "Board DistrictExplorer",
                      "Clicked: X Axis",
                      `Selected: ${newValue.label}`,
                    ]);
                  }
                  setXCustomRange();
                  setAxisX(null);
                  setTimeout(() => {
                    setAxisX(newValue);
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="X Axis (Horizontal)"
                    required={true}
                  />
                )}
              />
              {axisX && statsVariableX && xCustomRange && (
                <div className="var__range-slider">
                  <RangeSlider
                    ranges={statsVariableX}
                    values={xCustomRange}
                    id={"x-axis"}
                    unit={axisX.var_type}
                    onChange={(val) => updateRanges(val, setXCustomRange)}
                    markData={
                      histogramX?.district
                        ? [
                            {
                              label: histogramX.district.district_name,
                              value: histogramX.district.x,
                            },
                          ]
                        : null
                    }
                  />
                </div>
              )}
            </div>
            <div className="data-filters__item">
              <Autocomplete
                id={"y-axis"}
                value={axisY}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                className={"form-select"}
                options={DomainGroup}
                fullWidth={true}
                onChange={(event, newValue) => {
                  // Matomo code.
                  if (newValue) {
                    window._paq.push([
                      "trackEvent",
                      "Board DistrictExplorer",
                      "Clicked: Y Axis",
                      `Selected: ${newValue.label}`,
                    ]);
                  }
                  setYCustomRange();
                  setAxisY(null);
                  setTimeout(() => {
                    setAxisY(newValue);
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Y Axis (Vertical)"}
                    required={true}
                  />
                )}
              />
              {axisY && statsVariableY && yCustomRange && (
                <div className="var__range-slider">
                  <RangeSlider
                    ranges={statsVariableY}
                    values={yCustomRange}
                    id={"y-axis"}
                    unit={axisY.var_type}
                    onChange={(val) => updateRanges(val, setYCustomRange)}
                    markData={
                      histogramY?.district
                        ? [
                            {
                              label: histogramY.district.district_name,
                              value: histogramY.district.y,
                            },
                          ]
                        : null
                    }
                  />
                </div>
              )}
            </div>
            <div className="data-filters__item z-data-filter">
              <TooltipStyled 
                title={zAxisDefinition}
                aria-label={zAxisDefinition}
              >
                <span style={{margin: "-18px 0px"}} className={"help-tooltip z-axis-tooltip"}>ⓘ</span>
              </TooltipStyled>
              <Autocomplete
                id={"z-axis"}
                value={axisZ}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                className={"form-select"}
                options={DomainGroup}
                fullWidth={true}
                onChange={(event, newValue) => {
                  // Matomo code.
                  if (newValue) {
                    window._paq.push([
                      "trackEvent",
                      "Board DistrictExplorer",
                      "Clicked: Z Axis",
                      `Selected: ${newValue.label}`,
                    ]);
                  }
                  setZCustomRange();
                  setAxisZ(null);
                  setTimeout(() => {
                    setAxisZ(newValue);
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Bubble size"}
                    required={true}
                  />
                )}
              />
              {axisZ && statsVariableZ && zCustomRange && (
                <div className="var__range-slider">
                  <RangeSlider
                    ranges={statsVariableZ}
                    values={zCustomRange}
                    id={"z-axis"}
                    unit={axisZ.var_type}
                    onChange={(val) => updateRanges(val, setZCustomRange)}
                    markData={
                      histogramZ?.district
                        ? [
                            {
                              label: histogramZ.district.district_name,
                              value: histogramZ.district.z,
                            },
                          ]
                        : null
                    }
                  />
                </div>
              )}
            </div>
            <div className="extra-filters__actions">
              <Button
                variant={"contained"}
                style={{ backgroundColor: "#221349", color: "#fff" }}
                className="reset-data"
                onClick={() =>
                  clearHooks(
                    setHistogramX,
                    setAxisX,
                    setHistogramY,
                    setAxisY,
                    setHistogramZ,
                    setAxisZ,
                    setScatterData
                  )
                }
              >
                Clear axis
              </Button>
            </div>
          </section>
        )}

        {histogramX && axisX && (
          <section className="section section--histogram-x">
            <HistogramChart
              data={histogramX}
              title={axisX.label}
              description={axisX.description}
              id={"histogram-x"}
              ranges={statsVariableX}
              customRanges={xCustomRange}
            />
            <div className="chart-footer-data">
              {xFooterData && (
                <div className="chart-footer__var-distribution">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-x-distribution-content`}
                      id={`panel-x-distribution-header`}
                      className="accordion-summary"
                    >
                      <strong>See descriptive stats</strong>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <p>
                        {axisX.label} has <b>{xFooterData.points}</b> records
                        with: max <b>{xFooterData.max}</b>, min{" "}
                        <b>{xFooterData.min}</b>, mean <b>{xFooterData.mean}</b>
                        , median <b>{xFooterData.median}</b>, mode{" "}
                        <b>{xFooterData.mode}</b>, 1st quartile{" "}
                        <b>{xFooterData["1st quartile"]}</b>, 3rd quartile{" "}
                        <b>{xFooterData["3rd quartile"]}</b>, and{" "}
                        <b>{xFooterData.nulls}</b> nulls.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              <ApaReferenceAccordion apaData={axisX} />
            </div>
          </section>
        )}

        {histogramY && axisY && (
          <section className="section section--histogram-y">
            <HistogramChart
              data={histogramY}
              title={axisY.label}
              description={axisY.description}
              id={"histogram-y"}
              ranges={statsVariableY}
              customRanges={yCustomRange}
            />
            <div className="chart-footer-data">
              {yFooterData && (
                <div className="chart-footer__var-distribution">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-y-distribution-content`}
                      id={`panel-y-distribution-header`}
                      className="accordion-summary"
                    >
                      <strong>See descriptive stats</strong>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <p>
                        {axisY.label} has <b>{yFooterData.points}</b> records
                        with: max <b>{yFooterData.max}</b>, min{" "}
                        <b>{yFooterData.min}</b>, mean <b>{yFooterData.mean}</b>
                        , median <b>{yFooterData.median}</b>, mode{" "}
                        <b>{yFooterData.mode}</b>, 1st quartile{" "}
                        <b>{yFooterData["1st quartile"]}</b>, 3rd quartile{" "}
                        <b>{yFooterData["3rd quartile"]}</b>, and{" "}
                        <b>{yFooterData.nulls}</b> nulls.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              <ApaReferenceAccordion apaData={axisY} />
            </div>
          </section>
        )}
        {histogramZ && axisZ && (
          <section className="section section--histogram-z">
            <HistogramChart
              data={histogramZ}
              title={axisZ.label}
              description={axisZ.description}
              id={"histogram-z"}
              ranges={statsVariableZ}
              customRanges={zCustomRange}
            />
            <div className="chart-footer-data">
              {zFooterData && (
                <div className="chart-footer__var-distribution">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-z-distribution-content`}
                      id={`panel-z-distribution-header`}
                      className="accordion-summary"
                    >
                      <strong>See descriptive stats</strong>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <p>
                        {axisZ.label} has <b>{zFooterData.points}</b> records
                        with: max <b>{zFooterData.max}</b>, min{" "}
                        <b>{zFooterData.min}</b>, mean <b>{zFooterData.mean}</b>
                        , median <b>{zFooterData.median}</b>, mode{" "}
                        <b>{zFooterData.mode}</b>, 1st quartile{" "}
                        <b>{zFooterData["1st quartile"]}</b>, 3rd quartile{" "}
                        <b>
                          {
                            zFooterData[
                              "3rd q, and <b>{yFooterData.nulls}</b> nulls.uartile"
                            ]
                          }
                        </b>
                        , and <b>{zFooterData.nulls}</b> nulls.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              <ApaReferenceAccordion apaData={axisZ} />
            </div>
          </section>
        )}
        {scatterData && axisX && axisY && axisZ && (
          <section className="section section--scatter-data">
            <div className="chart-options text-right pt-2 pb-1 pr-2">
              <Button
                variant={varZEnabled ? "outlined" : "contained"}
                style={{ backgroundColor: "#773DBD", color: "#fff" }}
                onClick={() => setVarZEnabled(!varZEnabled)}
              >
                Turn {varZEnabled ? "off" : "on"} Bubble size
              </Button>
              {varZEnabled && (
                <div className={"z-axis-description"}>
                  <span>Bubble size represents the {axisZ.label}</span>
                  <InfoOutlinedIcon />
                </div>
              )}
            </div>
            {varZEnabled ? (
              <BubbleChart
                options={scatterData}
                x={axisX}
                y={axisY}
                z={axisZ.label}
                xCustomRange={xCustomRange}
                yCustomRange={yCustomRange}
              />
            ) : (
              axisX &&
              axisY &&
              axisZ && (
                <ScatterChart
                  options={scatterData}
                  x={axisX}
                  y={axisY}
                  xCustomRange={xCustomRange}
                  yCustomRange={yCustomRange}
                />
              )
            )}
          </section>
        )}
      </div>
      <ReportButtons
        boardType={"Board Explorer"}
        collectedData={{
          referenceDistrict: districtInfo,
          irns: irns,
          groupType: groupType,
          groupInfo: groupInfo,
          groupCount: groupCount,
          year: { value: year.value, label: year.value },
          axisVars: { axisX: axisX, axisY: axisY, axisZ: axisZ },
          sliderRanges: {
            xRange: xCustomRange || statsVariableX,
            yRange: yCustomRange || statsVariableY,
            zRange: zCustomRange || statsVariableZ,
          },
          histogramsData: {
            histogramX: histogramX,
            histogramY: histogramY,
            histogramZ: histogramZ,
          },
          statsVariables: {
            statsVariableX: statsVariableX,
            statsVariableY: statsVariableY,
            statsVariableZ: statsVariableZ,
          },
        }}
      />
      {postResponse && (
        <Snackbar
          className="custom-groups__save-message"
          open={displayResponseMessage}
          autoHideDuration={6000}
          onClose={() => setDisplayResponseMessage(false)}
          message={
            postResponse.ok === true || postResponse.status === 201 ? (
              <p className="success">Custom Board: saved!</p>
            ) : (
              <p className="error">
                Something went wrong. Error{" "}
                <strong>{postResponse.status}</strong>
              </p>
            )
          }
          action={
            <div onClose={() => setDisplayResponseMessage(false)}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setDisplayResponseMessage(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      )}
      {boardsRepeated && (
        <Snackbar
          className="custom-groups__save-message"
          open={repeatedError}
          autoHideDuration={6000}
          onClose={() => setRepeatedError(false)}
          message="You already used this name for a board"
          action={
            <div onClose={() => setRepeatedError(false)}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setRepeatedError(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      )}
    </LayoutPage>
  );
}

export default DistrictExplorer;
