import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    color: {
        primary: '#221349',
        primary_dark: '#190E34',
        secondary: '#773DBD',
    }
})

export default theme;
