import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { fetchById, simpleFetch } from "../../utils/helpsers";
import Grid from '@mui/material/Grid';
import DataGridTable from "../../components/Table/DataGridTable";
import LayoutPage from "../../layout/LayoutPage";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

HighchartsMore(Highcharts);

/**
 *
 * @param {object} data
 *  The columns/rows fetched by district.
 * @returns {Object}
 *  The same object structure but updated date values.
 */
const normalizeData = (data) => {
  data[0].columns = (data[0].columns).map(item => {
    if (item.field === "election_date") {
      return ({ ...item, type: 'date' })
    } else {
      return item
    }
  });

  data[0].rows = (data[0].rows).map(item => ({ ...item, election_date: new Date(item.election_date.replaceAll("-", "/")) }));

  return data[0]
}

function ElectionHistory() {
  // Data to feed select fields.
  const [districtOptions, setDistrictOptions] = useState([]);

  // Object value/label from select component
  const [districtInfo, setDistrictInfo] = useState();
  const [defaultValue, setDefaultValue] = useState("")

  const [levyRef, setLevyRef] = useState();
  const [bondRef, setBondRef] = useState();
  const [incomeTaxRef, setIncomeTaxRef] = useState();

  // User session metadata and data
  const { user } = useAuth0();
  const metadata = user[`${process.env.REACT_APP_AUTH0_KEY}/district`];

  const history = useHistory();

  useEffect(() => {
    if (metadata) {
      const userSessionType = metadata.hasOwnProperty('sessionType') ? metadata.sessionType : 'forbidden';

      switch (userSessionType) {
        case 'district-access':
          const districtData = {
            value: parseInt(metadata.districtIrn),
            label: metadata.districtLabel
          };
          setDistrictInfo(districtData);
          break;
        case 'full-access':
          window.localStorage.setItem('session-type', 'full-access');
          break;
        case 'multiple-district-access':
          window.localStorage.setItem('session-type', 'multiple-district-access');
          break;
        case 'forbidden':
          history.push('/AccessDenied');
          break;
        default:
          history.push('/AccessDenied');
          break;
      }
    } else {
      history.push('/AccessDenied');
    }
  }, [history, user, metadata]);

  useEffect(() => {
    setTimeout(() => {
        // Get districts options.
        simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_district?order=label`)
        .then(data => setDistrictOptions(data));

        // Get comparison group types.
        //setGroupTypesOptions(query.get('shared') ? [{ label: 'Custom Boards', value: 'custom_boards', selected: true }] : groupTypes);
    }, 1000);
}, [user]);

  useEffect(() => {
    if (districtInfo) {
      let ref = districtInfo.value;
      let urlLevyRef = `${process.env.REACT_APP_API_URL}/rpc/levy_ref?_irn='`;
      let urlBondRef = `${process.env.REACT_APP_API_URL}/rpc/bond_ref?_irn='`;
      let urlIncomeTaxRef = `${process.env.REACT_APP_API_URL}/rpc/income_tax_ref?_irn='`;

      setLevyRef();
      fetchById(urlLevyRef, ref + "'")
        .then(data => {
          if (data[0].rows && data[0].columns) {
            setLevyRef(normalizeData(data));
          }
        });

      setBondRef();
      fetchById(urlBondRef, ref + "'")
        .then(data => {
          if (data[0].rows && data[0].columns) {
            setBondRef(normalizeData(data));
          }
        });

      setIncomeTaxRef();
      fetchById(urlIncomeTaxRef, ref + "'")
        .then(data => {
          if (data[0].rows && data[0].columns) {
            setIncomeTaxRef(normalizeData(data));
          }
        });
    }
     if (!districtInfo) {
      setDistrictInfo(districtOptions[0]);
      setDefaultValue(districtOptions[0]);
    } 
  }, [districtInfo, districtOptions]);

  function dateFormat(time) {
    let date = new Date(time);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return month + "/" + day + "/" + year;
  }

  return (
    <LayoutPage
      pageTitle="Election History"
      description="Build custom groups based on filters"
      type="dashboard"
      customClass="levy"
    >
      <div className="header__bottom">
        <div className="layout-wrapper">
          <section className="section section--data-filters">
            <div
              className="data-filters__item filter-district"
              style={{ marginTop: 20 }}
            >
              <Autocomplete
                sx={{ backgroundColor: "white" }}
                id="districts"
                className="form-select"
                 isOptionEqualToValue={(option, value) =>
                  option === value
                }
                options={districtOptions}
                value={defaultValue}
                fullWidth={true}
                onChange={(event, newValue) => {
                  // Matomo code.
                  if (newValue) {
                    window._paq.push([
                      "trackEvent",
                      "Board ElectionHistory",
                      "Clicked: Reference district",
                      `Selected: ${newValue.label}`,
                    ]);
                  }
                  setDistrictInfo(newValue);
                  setDefaultValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Reference district" />
                )}
              />
            </div>
          </section>
        </div>
      </div>
      <section className="levy__cards layout-wrapper">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {levyRef && (
            <Grid item xs={12} className="table-data">
              <h2>Levy</h2>
              <DataGridTable
                columns={levyRef.columns.map((item) => ({
                  ...item,
                  minWidth: 150,
                }))}
                rows={levyRef.rows.map((item) => ({
                  ...item, election_date: dateFormat(item.election_date)
                }))}
                sortField={"election_date"}
                sortOrder={"desc"}
              />
            </Grid>
          )}
          {bondRef && (
            <Grid item xs={12} className="table-data">
              <h2>Bond</h2>
              <DataGridTable
                columns={bondRef.columns.map((item) => ({
                  ...item,
                  minWidth: 150,
                }))}
                rows={bondRef.rows.map((item) => ({
                  ...item, election_date: dateFormat(item.election_date)
                }))}
                sortField={"election_date"}
                sortOrder={"desc"}
              />
            </Grid>
          )}
          {incomeTaxRef && (
            <Grid item xs={12} className="table-data">
              <h2>Income Tax</h2>
              <DataGridTable
                columns={incomeTaxRef.columns.map((item) => ({
                  ...item,
                  minWidth: 150,
                }))}
                rows={incomeTaxRef.rows.map((item) => ({
                  ...item, election_date: dateFormat(item.election_date)
                }))}
                sortField={"election_date"}
                sortOrder={"desc"}
              />
            </Grid>
          )}
        </Grid>
      </section>
      <ReportButtons
        boardType={"Election History"}
        collectedData={{
          districtInfo: districtInfo,
        }}
      />
    </LayoutPage>
  );
}

export default ElectionHistory;
