import React from "react";
import BrandBlock from "../BrandBlock/BrandBlock";
import PropTypes from "prop-types";
import styles from "./header.module.scss";
import { MyProfile } from "../MyProfile/MyProfile";

const Header = ({ pageTitle }) => (
 <header className={'header'}>
   <div className={styles.headerWrapper}>
     <BrandBlock className={styles.brandIcon}/>
     <h1 className={styles.pageTitle}>{pageTitle}</h1>
     <div className={styles.myProfile}>
       <MyProfile />
     </div>
   </div>
 </header>
);

Header.propTypes = {
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Header;
