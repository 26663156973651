import React, { useState } from "react";
import BugReport from "./BugReport";
import Button from "@mui/material/Button";
import HelpDeskReport from "./HelpDeskResport";
import styles from './bugreport.module.scss';
import { useAuth0 } from "@auth0/auth0-react";

export const ReportButtons = ({ boardType, collectedData }) => {
  const [bugReport, setBugReport] = useState(false);
  const [helpDeskReport, setHelpDeskReport] = useState(false);

  const { user } = useAuth0();
  let userRole = user[`${process.env.REACT_APP_AUTH0_KEY}/role`];

  return (
    <div className={styles.reportContainer}>
      <div className={styles.reportButtonsWrapper}>
        <Button variant="contained" className={`${styles.reportButtonsItem} ${styles.bugReport}`} onClick={() => {
          setBugReport(true);
          setHelpDeskReport(false);
        }}>Bug Report</Button>

        {
          userRole === 'api_group_admin' &&
          <Button variant="contained" className={`${styles.reportButtonsItem} ${styles.helpDeskButton}`} onClick={() => {
            setHelpDeskReport(true);
            setBugReport(false);
          }}>Help Desk</Button>

        }
      </div>
      {
        bugReport && <BugReport type={boardType} reportData={collectedData} onClose={() => {
          // Both hooks need to be updated because ybug on close function.
          setBugReport(false);
          setHelpDeskReport(false);
        }} />
      }
      {
        helpDeskReport && <HelpDeskReport type={boardType} onClose={() => {
          // Both hooks need to be updated because ybug on close function.
          setBugReport(false);
          setHelpDeskReport(false);
        }} />
      }
    </div>
  )
}
