import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Standard Dialog box.
 *
 * @param {boolean} button: Set if element to trigger the dialog box will be a button.
 * @param {string} ctaText: Call to action text, click on it will trigger the dialog box.
 * @param {string} title: The title to be displayed on the dialog box.
 * @param {string} content: Content text
 * @param {string} helpText: Text added into a title tag to be displayed on hover.
 * @returns {JSX.Element}
 */
export default function DialogBox({ button = true, ctaText, title, content, helpText }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {
        button
          ? <Button variant="outline" onClick={handleClickOpen}>
            {
              ctaText
            }
          </Button>
          : <div title={helpText || ctaText} onClick={handleClickOpen}>
            {
              ctaText
            }
          </div>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <IconButton
          onClick={() => setOpen(false)}
          aria-label="close dialog"
          size="small" color={'error'}
          style={{ width: 24, height: 24, position: 'absolute', right: 10, top: 10 }}>
          <CloseIcon fontSize="small" />
        </IconButton>
        {
          title && (
            <DialogTitle id="dialog-title">
              {title}
            </DialogTitle>
          )
        }
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

DialogBox.propTypes = {
  ctaText: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
  button: PropTypes.bool,
};
