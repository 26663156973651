import React, {useState, useEffect, useRef, useContext} from 'react';
import { styled, ThemeProvider } from "@mui/material/styles";
import ComparisonCard from "../../components/ComparisonCard/ComparisonCard";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import DistrictData from "../../components/DistrictData/DistrictData";
import PieChart from "../../components/PieChart/PieChart";
import ColumnChart from "../../components/ColumnChart/ColumnChart";
import RadarChart from "../../components/RadarChart/RadarChart";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
    getDistrictInfo,
    fetchById,
    simpleFetch,
    currencyFormat,
    localHour,
    buildOnLoad
} from "../../utils/helpsers";
import MapView from '../../components/MapChart/MapView';
import LayoutPage from "../../layout/LayoutPage";
import SaveGroupForm from "../../components/SaveGroup/SaveGroup";
import TornadoChart from '../../components/TornadoChart/TornadoChart';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import BoardBuilder from "../../components/BoardBuilder/BoardBuilder";
import useQuery from "../../utils/userLocation";

import TextField from '@mui/material/TextField';

import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Select from '../../components/Select/Select';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import axios from 'axios';
import customTheme from '../../utils/theme';

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import {TokenContext} from "../../utils/Context";

import AsyncWrapper from "../../components/AsyncWrapper/AsyncWrapper";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

HighchartsMore(Highcharts);

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.color.secondary,
  fontWeight: 'bold',
  marginTop: 10,
  '&:hover': {
    backgroundColor: theme.color.secondary,
  },
}));

const MainButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "5px",
  border: "1px solid #773DBD",
  '&:hover': {
    backgroundColor: "#773DBD",
  }
}));

const SecondaryButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "5px",
  marginLeft: "5px"
}));

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0)",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: "11px",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    top:"131px !important",
    left:"91px",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: "#221349",
    color: 'white',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },

    "fieldset": {
      borderColor: "white",
    },

    " input, label, svg": {
      color: "white",
    }
  },
}));

const BaseIcon = (muiIcon) => {
  return styled(muiIcon)(({ theme }) => ({
    cursor: "pointer",
    fontSize: "24px"
  }));
};

const SaveBoardIcon = BaseIcon(SaveOutlinedIcon);
const ShareBoardIcon = BaseIcon(ShareOutlinedIcon);
const FilterIcon = BaseIcon(FilterAltOutlinedIcon);


function DistrictInsight() {
  // User session metadata and data
  const { user } = useAuth0();
  const metadata = user[`${process.env.REACT_APP_AUTH0_KEY}/district`];
  const enabledShare = metadata.enable_share;

  // User session token
  const {userToken} = useContext(TokenContext);

  const history = useHistory();
  const query = useQuery();

  // Data to feed select fields.
  const [options, setOptions] = useState({});

  // Object value/label from select component
  const [districtInfo, setDistrictInfo] = useState();
  const [groupInfo, setGroupInfo] = useState();
  const [groupType, setGroupType] = useState();
  const [groupCount, setGroupCount] = useState();
  const [year, setYear] = useState({});

  // The group to which the district belongs example: "group_name": "2) 1,000 to 2,499 ADM"
  const [districtGroup, setDistrictGroup] = useState();

  // Data to feed Cards.
  const [districtData, setDistrictData] = useState();
  const [comparisonCardsData, setComparisonCardsData] = useState();

  // Data to feed charts.
  const [demographicsData, setDemographicsData] = useState();
  const [demographicsGroupData, setDemographicsGroupData] = useState();

  const [perPupilSpendingData, setPerPupilSpendingData] = useState();
  const [perPupilSpendingGroupData, setPerPupilSpendingGroupData] = useState();

  const [totalSpending, setTotalSpending] = useState();
  const [totalSpendingGroup, setTotalSpendingGroup] = useState();

  const [enrollmentBrackets, setEnrollmentBrackets] = useState();
  const [academicPerformance, setAcademicPerformance] = useState();

  const [boardData, setBoardData] = useState();
  const [existingBoard, setExistingBoard] = useState();

  //Data for Map
  const [mapViewData, setMapViewData] = useState();
  const [groupIrns, setGroupIrns] = useState();

  const [showFilters, setShowFilters] = useState(true);

  const [btnFilter, setBtnFilter] = useState(null);
  const [btnSaved, setBtnSaved] = useState(null);
  const [btnShared, setBtnShared] = useState(null);

  const openFilters = Boolean(btnFilter);
  const openSaved = Boolean(btnSaved);
  const openShared = Boolean(btnShared);
  

  const [activeVersion, setActiveVersion] = useState(null);
  const [lastVersion, setLastVersion] = useState(null);
  const [sortedVersions, setSortedVersions] = useState([]);
  const [boardsName, setBoardsName] = useState(null);
  const [boardsRepeated, setBoardsRepeated] = useState(false);

  const [postResponse, setPostResponse ] = useState(null);
  const [repeatedError, setRepeatedError] = useState(false);
  const [displayResponseMessage, setDisplayResponseMessage] = useState(false);

  const Text = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root, MuiOutlinedInput-input': {
      color: '#black',
    },

    '& .MuiInputLabel-root': {
      color: '#black',
      top: '0px',
    },

    '& label.Mui-focused': {
      color: '#black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#207ad3',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#black',
      },
      '&:hover fieldset': {
        borderColor: '#black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#black',
      },
    },
  }));

  const clickFilters = (event) => {
    setBtnFilter(event.currentTarget);
  };

  const closeFilters = () => {
    setBtnFilter(null);
  };

  const clickSaved = (event) => {
    setBtnSaved(event.currentTarget);
  };

  const closeSaved = () => {
    setBtnSaved(null);
  };

  const clickShared = (event) => {
    setBtnShared(event.currentTarget);
  };

  const closeShared = () => {
    setBtnShared(null);
  };

  const handleFilter = () => {
    setBtnFilter(null)
  }

  const [activeUser, setActiveUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [usersFormat, setUsersFormat] = useState([]);
  const [shareWith, setShareWith] = useState([]);

  const [action, setAction] = useState();

  const shareWithUsers = () => {
    if (existingBoard) {
      if (shareWith.length === 0) {
        setDisplayResponseMessage(true);
        setPostResponse(true);
        closeShared();
      } else {
        let uuid = existingBoard.uuid;
        let bodyJson = existingBoard;

        bodyJson["available_users_share"] = shareWith;

        bodyJson = JSON.stringify(bodyJson);

        fetch(
          `${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${uuid}`,
          {
            method: "PUT",
            headers: {
              Authorization: "Bearer " + userToken,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
            body: bodyJson,
          }
        ).then((response) => {
          setDisplayResponseMessage(true);
          setPostResponse(response);
          closeShared();
        });
      }
    }
  };

  useEffect(() => {
   if(userToken) return axios.get(
               `${process.env.REACT_APP_API_URL}/custom_boards?created_by=eq.${user.email}`,
               {
                 headers: {
                  'ngrok-skip-browser-warning': true  
                 }
               }
           ).then((res) => {
               setBoardsName(res.data);
           });
  }, [user.email, userToken]);

  const changeName = () => {
    if (existingBoard) {
      let uuid = existingBoard.uuid;
      let bodyJson = existingBoard;

      bodyJson.label = boardName.current;
      bodyJson = JSON.stringify(bodyJson);
      let boardsLength = Object.keys(boardsName).length;

      for (const [key, value] of Object.entries(boardsName)) {
        if (value.label === boardName.current) {
          setRepeatedError(true);
          setBoardsRepeated(true);
          break;
        }
        if (+key + 1 === boardsLength) {
          fetch(
            `${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${uuid}`,
            {
              method: "PUT",
              headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + userToken,
                "Content-Type": "application/json",
              },
              body: bodyJson,
            }
          ).then(() => {
            setAction("");
          });
        }
      }
    }
  };

  const changeVersion = () => {
    if (existingBoard) {
      let uuid = existingBoard.uuid;
      let bodyJson = existingBoard;

      if (action === 'edit-version' && existingBoard.data[activeVersion.value]['done_by'] === user.email) {
        bodyJson.data[boardVersion.current] = bodyJson.data[activeVersion.value];
        if (boardVersion.current !== activeVersion.value) {
          delete bodyJson.data[activeVersion.value];
        }
      }

      bodyJson = JSON.stringify(bodyJson);

      fetch(`${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${uuid}`, {
        method: 'PUT',
        headers: {
          'ngrok-skip-browser-warning': true,
          'Authorization': 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        },
        body: bodyJson
      }).then(() => {
        window.location.reload();
      })
    }
  }

  useEffect(() => {
    if (usersFormat.length === 0 && userToken) {
      async function getGroups() {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/groups`,
          {
            headers: {
              'ngrok-skip-browser-warning': true
            }
          }
        );
        return response.data;
      }

      getGroups().then( groups => {
        setUserGroups(groups);
        axios.get(`${process.env.REACT_APP_API_URL}/users`, {
          headers: {
            'ngrok-skip-browser-warning': true
          }
        })
            .then(res => {
              setUsers(res.data);

              let currentUser = res.data.find((element, index) => {
                let valid = false;

                if (element.email === user.email) {
                  valid = true;
                }

                return valid;
              });

              setActiveUser(currentUser);
            });

        let filteredUsers = users.filter((userF) => userF.group_id === activeUser.group_id ? userF : false);
        let usersFormatted = filteredUsers.map( (userF) => {
          let userGroup = userGroups.find(userGroup => userGroup.id === userF.group_id);
          let filteredResult = null;
          if (userF && userGroup) {
            filteredResult = { 'value': userF.email, 'label': userF.name + ' @ ' + userGroup.name };
          }
          return filteredResult;
        });
        setUsersFormat(usersFormatted);
      });
    }
  }, [activeUser.group_id, user.email, userGroups, users, usersFormat.length, userToken]);

  const handleChange = (selection) => {
    if (selection !== null) {
      if (existingBoard) {
        if (existingBoard.data[selection.value].comparisonCards) {
          setActiveVersion(selection);
          boardVersion.current = selection.value;

          // Cleaning group info hook.
          let selectedVersion = existingBoard.data[selection.value];

          setExistingBoardVars(
            selectedVersion.referenceDistrict,
            selectedVersion.year,
            selectedVersion.groupType,
            undefined,
            selectedVersion.irns,
            selectedVersion.groupInfo.count
          );

          setTimeout(() => setExistingBoardVars(
            selectedVersion.referenceDistrict,
            selectedVersion.year,
            selectedVersion.groupType,
            selectedVersion.groupInfo,
            selectedVersion.irns,
            selectedVersion.groupInfo.count
          ), 100);
        }
      }
    }
  };
  const setExistingBoardVars = (districtInfoVar, yearVar, groupTypeVar, groupInfoVar, groupIrnsVar, groupCountVar) => {
    setDistrictInfo(districtInfoVar);
    setYear(yearVar);
    setGroupType(groupTypeVar);
    setGroupInfo(groupInfoVar);
    setGroupIrns(groupIrnsVar)
    setGroupCount(groupCountVar);
  }

  useEffect(() => {
    if (!lastVersion && existingBoard) {
      let unsorted = Object.keys(existingBoard.data);
      unsorted = unsorted.map(version => {
        let label = version === existingBoard.data[version]['created_at'] ? `${localHour(version)}` : `${version} - ${localHour(existingBoard.data[version]['created_at'])}`;
        return { 'label': label, 'value': version, "datetime": existingBoard.data[version]['created_at'] }
      }
      );
      let sortedDates = unsorted.sort((a, b) => a.datetime - b.datetime);

      let lastVersion = sortedDates[sortedDates.length - 1];

      setSortedVersions(sortedDates);
      setActiveVersion(lastVersion);
      if (lastVersion) {
        boardVersion.current = lastVersion.value;
      }
      setLastVersion(lastVersion);
    }
  }, [existingBoard, lastVersion]);


  useEffect(() => {
    if (metadata) {
      const userSessionType = metadata.hasOwnProperty('sessionType') ? metadata.sessionType : 'forbidden';
      switch (userSessionType) {
        case 'district-access':
          window.localStorage.setItem('session-type', 'district-access');
          break;
        case 'full-access':
          window.localStorage.setItem('session-type', 'full-access');
          break;
        case 'multiple-district-access':
          window.localStorage.setItem('session-type', 'multiple-district-access');
          break;
        case 'forbidden':
          history.push('/AccessDenied');
          break;
        default:
          history.push('/AccessDenied');
          break;
      }
    } else {
      history.push('/AccessDenied');
    }
  }, [history, user, metadata]);

  useEffect(() => {
    if (query.get('shared') && !groupType && userToken) {
      setShowFilters(false);

      simpleFetch(`${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.` + query.get('shared'))
        .then((customBoards) => {
          let boardRetrieve = customBoards[0];
          if ((boardRetrieve.available_users_share && boardRetrieve.available_users_share.some(userShare => userShare.value === user.email)) || boardRetrieve.created_by === user.email) {
            let unsorted = Object.keys(customBoards[0].data);
            let sortedDates = unsorted.sort((a, b) => a - b);
            let lastVersion = sortedDates[sortedDates.length - 1];

            let boardRetrieve = "irns" in customBoards[0].data ? customBoards[0].data : customBoards[0].data[lastVersion];

            setExistingBoard(customBoards[0]);
            boardName.current = customBoards[0].label;
            if (customBoards[0]['available_users_share']) {
              setShareWith(customBoards[0]['available_users_share']);
            }

            if (boardRetrieve.referenceDistrict) {
              setDistrictInfo(boardRetrieve.referenceDistrict);
            } else {
              setDistrictInfo({
                "value": 45187,
                "label": "Ada Ex Vill SD, Hardin (045187)"
              });
            }

            boardRetrieve.groupInfo.data = boardRetrieve.comparisonCards;

            setExistingBoardVars(
              boardRetrieve.referenceDistrict,
              boardRetrieve.year,
              'custom_boards',
              boardRetrieve.groupInfo,
              boardRetrieve.irns,
              boardRetrieve.groupInfo.count
            );
          } else {
            history.push('/AccessDenied');
          }
        });
    }
    // Build board on load if no query found.
      if(!query.get('shared') && !groupType && userToken) {
          // Build .
          buildOnLoad({user: user, callback: setBoardData, token: userToken});
      }
  }, [query, groupType, history, user, userToken]);

  useEffect(() => {
    if (boardData) {
      setExistingBoardVars(
        boardData.referenceDistrict,
        boardData.year,
        boardData.groupType,
        boardData.groupInfo,
        boardData.irns,
        boardData.groupCount
      );
    }
  }, [boardData])

  useEffect(() => {
    if (districtInfo !== undefined && Object.keys(year).length > 0) {
      let districtId = districtInfo.value;
      // Cleaning hooks.
      setComparisonCardsData();
      setEnrollmentBrackets();
      setAcademicPerformance();

      // Getting data for district card.
      getDistrictInfo(districtId, year.value, setDistrictData, setDistrictGroup, userToken);
      // Getting Per Pupil Spending.
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_per_pupil_spending?_irns=` + districtId + "&_year=" + year.value + "&order=y.desc")
        .then(data => {
          const totalSpendingPerPupil = data.filter(item => item.name === "District Total");
          data = data.filter(item => item.name !== "District Total");
          setPerPupilSpendingData(data);
          setTotalSpending(totalSpendingPerPupil[0]);
        });

      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_tornado?_years=` + year.value + "&_irns=" + districtId)
        .then(data => {
          setDemographicsData(data[0]);
        });
    }
  }, [districtInfo, year, userToken]);

  useEffect(() => {
    if (districtGroup !== undefined && Object.keys(year).length > 0) {
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_enrollment_bracket?_year=` + year.value)
        .then(data => data.forEach(item => {
          item.data.forEach(itm => {
            if (itm.name === districtGroup) {
              itm.selected = true
              setEnrollmentBrackets(null)
              return setEnrollmentBrackets(data);
            }
          });
          item.data_sum.forEach(itm => {
            if (itm.name === districtGroup) {
              itm.selected = true
            }
          });
        }));
    }
  }, [districtGroup, districtInfo, year]);

  useEffect(() => {
    if (groupInfo && districtInfo) {
      let groupId = groupInfo.label;
      let urlGroupSelected = `${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&label=eq.`;

      if (groupType.value === 'Enrollment+Brackets') {
        groupId = groupInfo.value;
        urlGroupSelected = `${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&value=eq.`;
      }

      // Getting group count.
      if (groupType.value === 'custom_groups') {
        let varKey = (Object.keys(groupInfo.custom_ranges).length);
        let varData = groupInfo.custom_ranges[`var${varKey}`];
        setGroupCount(varData.districtsCount);
        setGroupIrns([...varData.irns]);
      } else {
        // @TODO BackEnd must update the group ID label to remove the 's.
        groupId = groupId === "All Ohio School Districts" ? "All Ohio's School Districts" : groupId;

        fetchById(urlGroupSelected, groupId)
          .then(data => {
            setGroupCount(data[0].count);
            setGroupIrns(data[0].irns);
          });
      }

    }
  }, [groupInfo, districtInfo, groupType]);


  useEffect(() => {
    if (groupIrns && districtInfo && Object.keys(year).length > 0) {
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_tornado?_irns=` + groupIrns + '&_years=' + year.value).then(data => {
        setDemographicsGroupData(data[0]);
      });

      // Getting Per Pupil Spending.
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_per_pupil_spending?_irns=` + groupIrns + '&_year=' + year.value + "&order=y.desc").then(data => {
        const totalSpendingPerPupil = data.filter(item => item.name === "District Total");
        data = data.filter(item => item.name !== "District Total");
        setPerPupilSpendingGroupData(data);
        setTotalSpendingGroup(totalSpendingPerPupil[0]);
      });

      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_radars?_irn=${districtInfo.value}&_irns=${groupIrns}&_year=${year.value}`).then(data => {
        setAcademicPerformance(data);
      });

      if (Object.keys(year).length > 0) {
        simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_domain_card?_irn=${districtInfo.value}&_irns=${groupIrns}&_year=${year.value}`)
          .then(data => {
            setComparisonCardsData(data);
          });
      }
    }
  }, [groupIrns, districtInfo, year]);

  useEffect(() => {
    if (comparisonCardsData) {
      comparisonCardsData.forEach(card => {
        simpleFetch(`${process.env.REACT_APP_API_URL}/historical_variable?domain=eq.${card.domain}&select=label:human_name,value:dwh_variable&order=var_order&visible=eq.true`)
          .then(data =>
            setOptions(prevState => ({
              ...prevState,
              [card.domain]: data
            }))
          );
      });
    }
  }, [comparisonCardsData]);

  const comparisonCardsInfo = useRef({
    cardsData: {},
  });

  const boardName = useRef('');
  const boardVersion = useRef('');

  return (
    <LayoutPage
      pageTitle={
        districtInfo ? (
          <>
            District Insights:&nbsp;{" "}
            <span className="district-name clr-secondary">
              {districtInfo.label}
            </span>
          </>
        ) : (
          "District Insights"
        )
      }
      description="Build custom groups based on filters"
      type="dashboard"
      customClass="district-insights"
    >
      <div className="header__bottom">
        <div className="layout-wrapper">
          <section
            className={`section section--data-filters ${
              showFilters
                ? "district-filters--active"
                : "district-filters--hidden"
            }`}
          >
            <Grid
              container
              className={`align-container ${
                showFilters ? "basicFilters" : "sharedFilters"
              }`}
              spacing={0}
              textAlign="center"
              style={{ marginBottom: 20 }}
            >
              <Grid item xs={5} sm={2} display="flex">
                <TooltipStyled title="Filters">
                  <MainButton
                    id="button-filters"
                    aria-controls={openFilters ? "menu-filters" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilters ? "true" : undefined}
                    variant="contained"
                    onClick={clickFilters}
                    style={openFilters ? { backgroundColor: "#221349", borderColor: "#221349" } : {backgroundColor: "#773DBD"}}
                  >
                    <FilterIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"} sx={{marginBottom:0}} variant="caption" display="block" gutterBottom>
                      Filters
                    </Typography>
                  </MainButton>
                </TooltipStyled>
                <StyledMenu
                  id="menu-filters"
                  MenuListProps={{
                    "aria-labelledby": "button-filters",
                  }}
                  anchorEl={btnFilter}
                  open={openFilters}
                  onClose={closeFilters}
                >
                  <MenuItem disableRipple>
                    <BoardBuilder
                      group={true}
                      year={true}
                      boardData={setBoardData}
                      onSubmit={() => handleFilter()}
                      existingBoard={existingBoard}
                    />
                  </MenuItem>
                </StyledMenu>
                {enabledShare && (
                  <>
                    <TooltipStyled title="Save Board">
                      <SecondaryButton
                        id="button-saved"
                        aria-controls={openSaved ? "menu-saved" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSaved ? "true" : undefined}
                        variant="contained"
                        disabled={!districtInfo ? true : false}
                        style={districtInfo ? {border: "1px solid #773DBD"} : { border: "1px solid #808080"}}
                        onClick={clickSaved}
                      >
                        <SaveBoardIcon
                          style={{
                            color:
                              boardData || existingBoard ? "#773DBD" : "#BFBDBD",
                          }}
                        />
                      </SecondaryButton>
                    </TooltipStyled>
                    <StyledMenu
                      id="menu-saved"
                      MenuListProps={{
                        "aria-labelledby": "button-saved",
                      }}
                      anchorEl={btnSaved}
                      open={openSaved}
                      onClose={closeSaved}
                    >
                      <MenuItem disableRipple>
                        <div className={"district-insights__actions"}>
                          {groupCount && Object.keys(year).length > 0 && (
                            <SaveGroupForm
                              type={"insights"}
                              groupData={{
                                referenceDistrict: districtInfo,
                                irns: groupIrns,
                                groupType: groupType,
                                groupInfo: groupInfo,
                                year: { value: year.value, label: year.value },
                                comparisonCards: comparisonCardsInfo.current,
                              }}
                              endpoint={`${process.env.REACT_APP_API_URL}/custom_boards`}
                              layout={true}
                              existingData={existingBoard}
                              onChange={setExistingBoardVars}
                              onSave={setExistingBoard}
                            />
                          )}
                        </div>
                      </MenuItem>
                    </StyledMenu>
                    <TooltipStyled title="Share Board">
                      <SecondaryButton
                        id="button-shared"
                        aria-controls={openShared ? "menu-shared" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openShared ? "true" : undefined}
                        variant="contained"
                        disabled={
                          !existingBoard ||
                          existingBoard.created_by !== user.email
                            ? true
                            : false
                        }
                        style={existingBoard ? {border: "1px solid #773DBD"} : { border: "1px solid #808080"}}s
                        onClick={clickShared}
                      >
                        <ShareBoardIcon
                          style={{ color: existingBoard ? "#773DBD" : "#BFBDBD" }}
                        />
                      </SecondaryButton>
                    </TooltipStyled>
                    <StyledMenu
                      id="menu-shared"
                      MenuListProps={{
                        "aria-labelledby": "button-shared",
                      }}
                      anchorEl={btnShared}
                      open={openShared}
                      onClose={closeShared}
                    >
                      <MenuItem disableRipple>
                        {usersFormat && (
                          <Grid container spacing={0} textAlign="center">
                            <Grid item sm={12} display="flex">
                              <Select
                                options={usersFormat}
                                id={"shared-with"}
                                label=""
                                defaultValue={shareWith}
                                isMultiple={true}
                                onChange={(e) => {
                                  setShareWith(e);
                                }}
                              />
                            </Grid>
                            <Grid item sm={12} display="flex">
                              <ThemeProvider theme={customTheme}>
                                <ActionButton
                                  variant="contained"
                                  color="primary"
                                  onClick={() => shareWithUsers()}
                                >
                                  Share with users
                                </ActionButton>
                              </ThemeProvider>
                            </Grid>
                          </Grid>
                        )}
                      </MenuItem>
                    </StyledMenu>
                  </>
                )}
              </Grid>
              {existingBoard && (
                <>
                  <Grid item xs={9} sm={3} className="group-filters__wrapper">
                    {action === "edit-name" ? (
                      <Text
                        autoComplete="off"
                        className="form-field form-field--group-name"
                        id="BoardName"
                        defaultValue={boardName.current}
                        onChange={(e) => (boardName.current = e.target.value)}
                        fullWidth
                        variant="outlined"
                        label="Board Name"
                        required
                      />
                    ) : (
                      <div className="group-counter__result">
                        {existingBoard.label}
                      </div>
                    )}
                  </Grid>
                  <Grid className="editButtonItem" item xs={2} sm={1}>
                    {action === "edit-name" ? (
                      <ThemeProvider theme={customTheme}>
                        <ActionButton
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => changeName()}
                        >
                          Rename Board
                        </ActionButton>
                        <ActionButton
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => setAction("")}
                        >
                          Cancel
                        </ActionButton>
                      </ThemeProvider>
                    ) : (
                      <IconButton
                        aria-label="done"
                        onClick={() => setAction("edit-name")}
                      >
                        <EditIcon style={{ color: "#221349" }} />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    {action === "edit-version" && activeVersion ? (
                      <Text
                        autoComplete="off"
                        className="form-field form-field--group-name"
                        id="VersionName"
                        defaultValue={boardVersion.current}
                        onChange={(e) =>
                          (boardVersion.current = e.target.value)
                        }
                        fullWidth
                        variant="outlined"
                        label="Version Name"
                        required
                      />
                    ) : (
                      activeVersion && (
                        <Select
                          labelId="data-version"
                          id="data-version"
                          options={sortedVersions}
                          onChange={handleChange}
                          value={activeVersion}
                          label="Version"
                        />
                      )
                    )}
                  </Grid>
                  <Grid className="editButtonItem" item xs={2} sm={1}>
                    {action === "edit-version" ? (
                      <ThemeProvider theme={customTheme}>
                        <ActionButton
                          className="editChangeVersionName"
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => changeVersion()}
                        >
                          Rename Version
                        </ActionButton>
                        <ActionButton
                          className="editChangeVersionName"
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => setAction("")}
                        >
                          Cancel
                        </ActionButton>
                      </ThemeProvider>
                    ) : (
                      activeVersion &&
                      existingBoard.data[activeVersion.value] &&
                      existingBoard.data[activeVersion.value]["done_by"] ===
                        user.email && (
                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setAction("edit-version");
                            boardVersion.current = activeVersion.value;
                          }}
                        >
                          <EditIcon style={{ color: "#221349" }} />
                        </IconButton>
                      )
                    )}
                  </Grid>
                </>
              )}
            </Grid>

            {groupInfo && groupCount && (
              <div className="data-filters__item group-counter__wrapper">
                <div className="group-counter">
                  <div className="group-counter__versus">{`${districtInfo.label} - vs - ${groupInfo.label}`}</div>
                  <div className="group-counter__result">{`Year: ${year.label} | ${groupCount} districts in the comparison group.`}</div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
      <div className="main-content__top layout-wrapper">
        {
          (districtInfo && year.value) &&
            <section className="section section--district-data">
              <AsyncWrapper
                  label={districtInfo.label}
                  endPoint={`${process.env.REACT_APP_API_URL}/rpc/hvd_resume?_irn=${districtInfo.value}&_year=${year.value}`}
                  dataKey={0}
                  dataType={'object'}
                  callback={setDistrictData}>
                {
                  districtData &&
                  <DistrictData data={districtData} />
                }
              </AsyncWrapper>
            </section>
        }
        {
          (districtInfo && year.value) &&
            <section className="section section--district-map">
              <AsyncWrapper
                  label={'MAP'}
                  endPoint={`${process.env.REACT_APP_API_URL}/hvd_polygons?irn=in.(${groupIrns},${districtInfo.value})&file_year=eq.${year.value}`}
                  callback={setMapViewData}>
                {
                
                  mapViewData &&
                  <MapView data={mapViewData} districtIrn={districtInfo.value} />
                }
              </AsyncWrapper>
            </section>
        }
        {demographicsData &&
          districtInfo &&
          groupInfo &&
          Object.keys(year).length > 0 && (
            <section className="section section--student-demographics">
              <TornadoChart
                data={demographicsData}
                title={`${districtInfo.label}: Demographics`}
                subtitle="Student vs Teachers % of Total"
              />
            </section>
          )}
        {demographicsGroupData && groupInfo && Object.keys(year).length > 0 && (
          <section className="section section--pupil-spending">
            <TornadoChart
              data={demographicsGroupData}
              title={`${groupInfo.label}: Demographics`}
              subtitle="Student vs Teachers % of Total"
            />
          </section>
        )}
        {perPupilSpendingData &&
          districtInfo &&
          groupInfo &&
          Object.keys(year).length > 0 && (
            <section className="section section--student-demographics">
              <PieChart
                data={perPupilSpendingData}
                title={`${districtInfo.label}: Per Pupil Spending`}
                subtitle={" Expenditure per Pupil $USD"}
                id={"district-spending"}
                toggle={true}
              />
              {totalSpending && (
                <div className="extra-data">
                  {" "}
                  Total Spending Per Pupil:{" "}
                  <strong>{currencyFormat(totalSpending.y)}</strong>
                </div>
              )}
            </section>
          )}
        {perPupilSpendingGroupData &&
          groupInfo &&
          groupInfo &&
          Object.keys(year).length > 0 && (
            <section className="section section--pupil-spending">
              <PieChart
                data={perPupilSpendingGroupData}
                title={`${groupInfo.label}: Per Pupil Spending`}
                subtitle={" Expenditure per Pupil $USD"}
                id={"group-demographics"}
                toggle={true}
              />
              {totalSpendingGroup && (
                <div className="extra-data">
                  {" "}
                  Total Spending Per Pupil:{" "}
                  <strong>{currencyFormat(totalSpendingGroup.y)}</strong>
                </div>
              )}
            </section>
          )}
        {enrollmentBrackets && groupInfo && Object.keys(year).length > 0 && (
          <section className="section section--enrollment-brackets">
            <ColumnChart
              data={enrollmentBrackets}
              title={"Enrollment Brackets"}
            />
          </section>
        )}
        {academicPerformance && districtInfo && groupInfo && (
          <section className="section section--academic-performance">
            <RadarChart
              data={academicPerformance}
              title={"Academic Performance"}
              referenceDistrict={districtInfo.label}
              groupName={groupInfo.label}
            />
          </section>
        )}
      </div>
      <div className="main-content__bottom layout-wrapper">
        {comparisonCardsData &&
          districtInfo &&
          groupInfo &&
          Object.keys(year).length > 0 && (
            <section className="section section--data-comparison">
              {comparisonCardsData.length === Object.keys(options).length &&
                comparisonCardsData.map((item, key) => {
                  return (
                    <ComparisonCard
                      key={key}
                      defaultDomainVar={
                        groupType === "custom_boards"
                          ? groupInfo.data.cardsData[`card${key}`]
                          : options[item.domain][0]
                      }
                      year={year.value}
                      title={item.domain}
                      irns={groupIrns}
                      referenceDistrict={districtInfo}
                      groupName={groupInfo.label}
                      groupType={groupType}
                      domainOptions={options[item.domain]}
                      storedCardInfo={(val) =>
                        (comparisonCardsInfo.current.cardsData = {
                          ...comparisonCardsInfo.current.cardsData,
                          [`card${key}`]: val,
                        })
                      }
                    />
                  );
                })}
            </section>
          )}
      </div>
      <ReportButtons
        boardType={"Board Insight"}
        collectedData={{
          irns: groupIrns,
          groupType: groupType,
          groupInfo: groupInfo,
          year: { value: year.value, label: year.value },
          comparisonCards: comparisonCardsInfo.current,
        }}
      />
      {postResponse && (
        <Snackbar
          className="custom-groups__save-message"
          open={displayResponseMessage}
          autoHideDuration={6000}
          onClose={() => setDisplayResponseMessage(false)}
          message={
            postResponse.ok === true || postResponse.status === 201 ? (
              <p className="success">Shared with users successfully!</p>
            ) : (
              <p className="error">
                Something went wrong. Error{" "}
                <strong>{postResponse.status}</strong>
              </p>
            )
          }
          action={
            <div onClose={() => setDisplayResponseMessage(false)}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setDisplayResponseMessage(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      )}
      {boardsRepeated && (
        <Snackbar
          className="custom-groups__save-message"
          open={repeatedError}
          autoHideDuration={6000}
          onClose={() => setRepeatedError(false)}
          message="You already used this name for a board"
          action={
            <div onClose={() => setRepeatedError(false)}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setRepeatedError(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      )}
    </LayoutPage>
  );
}

export default DistrictInsight;
