import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from "@mui/material/Button";

const LogoutButton = () => {
    const { logout } = useAuth0();
    return (
        <Button
            color="secondary"
            variant="contained"
            className="log-btn"
            onClick={() => {
                    localStorage.removeItem('fixedSelection');
                    localStorage.removeItem('token');
                    localStorage.removeItem('exp');
                    localStorage.removeItem('session-type');
                    localStorage.removeItem('user_email');

                    logout({
                        returnTo: window.location.origin,
                    })
                }
            }
        >
            Log Out
        </Button>
    );
};

export default LogoutButton;
