import { useEffect } from "react";

// Feedback is the container that bypass the email data as the Reporter.
export default function BugReport({ type, reportData, onClose }) {
  useEffect(() => {
    (function (s) {
      window.ybug_settings = {
        id: "2k18whsf5y0css9kwpb1",
        hide_launcher: true,
        type: false,
        user: {
          username: window.localStorage.getItem('user_email'),
          sessionType: window.localStorage.getItem('session-type'),
          board: type,
          reportData
        },
        feedback: {
          email: window.localStorage.getItem('user_email'),
        },
        translate: {
          "feedbackTypeForm.Heading": "Bug Report",
          "feedbackForm.TextPlaceholder": "Write a comment or describe a problem, for any other concern please contact your group admin.",
          "thankYouModal.Text": "Thanks form making this product better.",
        },
        onclose: () => onClose(),
      }
      const loadYbug = new Promise(resolve => {
        let ybug = document.createElement('script');
        ybug.type = 'text/javascript';
        ybug.async = false;
        ybug.src = 'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js';
        ybug.addEventListener('load', resolve, {
          once: true
        });
        s = document.getElementsByTagName('script')[0];

        document.getElementById('bugReportContainer').append(ybug, s)
      });

      loadYbug.then(() => window.Ybug.open());
    })();

  }, [type, reportData, onClose])

  // Div required to append the Ybug window.
  return <div id="bugReportContainer"></div>;
}
