import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import LayoutPage from "../../layout/LayoutPage";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();

  const insightsPage = () => {
    history.push("/insights");
  };
  return (
    <LayoutPage
      pageTitle="Page Not Found"
      description="Build custom groups based on filters"
      type="dashboard"
    >
      <Container maxWidth="sm">
        <div className="error-container">
          <h1 className="error-title">404 Error</h1>
          <h2>We can't find the page that you're looking for</h2>
          <Button
            style={{
              background: "#221349",
              color: "white",
              fontSize: "1rem",
              minWidth: "84px",
              borderRadius: "15px",
              textTransform: "none",
            }}
            onClick={insightsPage}
          >
            Home
          </Button>
        </div>
      </Container>
    </LayoutPage>
  );
};

export default PageNotFound;
