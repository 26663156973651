import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from "./apareference.module.scss";

const ApaReferenceAccordion = ({ apaData }) => {
  return <div className={styles.chartFooter__bibliographicReferences}>
    <div className={styles.apaReference}>
      {
        apaData.source &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${apaData.source.r_text}-content`}
            id={`panel-${apaData.source.r_text}-header`}
            className={styles.accordionSummary}
          >
            <strong>See references</strong>
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <p>
              {apaData.source.apa_ref}
            </p>
          </AccordionDetails>
        </Accordion>
      }
      {
        apaData.source_compare &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${apaData.source.r_text}-content`}
            id={`panel-${apaData.source.r_text}-header`}
            className={styles.accordionSummary}
          >
            <strong>{apaData.source_compare.r_text}</strong>
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            {apaData.source_compare.apa_ref}
          </AccordionDetails>
        </Accordion>
      }
    </div>
  </div>
}

export default ApaReferenceAccordion;
