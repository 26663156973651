import React, {useState, useEffect, useContext} from 'react';
import { simpleFetch } from "../../utils/helpsers";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {TokenContext} from "../../utils/Context";

const InlineHelp = () => {
  const [variablesGroup, setVariablesGroup] = useState(null);
  const [variableDefinition, setVariableDefinition] = useState(null);

  // User session token
  const {userToken} = useContext(TokenContext);

  useEffect(() => {
      if (userToken) {
          simpleFetch(`${process.env.REACT_APP_API_URL}/historical_variable?order=human_name.asc`)
              .then((data) =>
                setVariablesGroup(
                  data.map((elem) => ({
                      value: elem.var_id,
                      label: elem.human_name,
                      description: elem.description,
                  }))
              )
          );
      }
  }, [userToken]);

  const getDefinition = (varID) => variablesGroup.filter(elem => {
    return elem.value === varID && setVariableDefinition(
      elem.description ? elem.description : "Not definition available"
    );
  });

  return <div className="inline-help">
      <Autocomplete
          id="inline-help-select"
          className={"inline-help__select"}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={variablesGroup}
          fullWidth={true}
          onChange={(event, newValue) => {
              if(newValue) return getDefinition(newValue.value)
              setVariableDefinition(null)
          }}
          sx={{ color: '#ff000'}}
          renderInput={(params) =>
              <TextField {...params} label={'Lookup Definitions:'} sx={{ input: { color: '#fff' } }}/>
          }
      />
    {
      variableDefinition &&
      <div className="inline-help__definition">
        {variableDefinition}
      </div>
    }
  </div>
}

export default InlineHelp;
