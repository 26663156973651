import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";
import LayoutPage from "../../layout/LayoutPage";

function LaborDashboard() {
  // User session metadata and data
  const { user } = useAuth0();
  const roleUser = user["https://data.insightboard.org/role"];
  const metadata = user[`${process.env.REACT_APP_AUTH0_KEY}/district`];

  const history = useHistory();

  useEffect(() => {
    if (metadata) {
      const userSessionType = metadata.hasOwnProperty("sessionType")
        ? metadata.sessionType
        : "forbidden";
      switch (userSessionType) {
        case "district-access":
          window.localStorage.setItem("session-type", "district-access");
          break;
        case "full-access":
          window.localStorage.setItem("session-type", "full-access");
          break;
        case "multiple-district-access":
          window.localStorage.setItem(
            "session-type",
            "multiple-district-access"
          );
          break;
        case "forbidden":
          history.push("/AccessDenied");
          break;
        default:
          history.push("/AccessDenied");
          break;
      }
    } else {
      history.push("/AccessDenied");
    }
  }, [history, user, metadata]);

  useEffect(() => {
    if (roleUser !== "api_super_admin") {
      history.push("/AccessDenied");
    }
  }, [history, user, roleUser]);

  return (
    <LayoutPage
      pageTitle="Labor Dashboard"
      description="Labor Dashboard"
      type="dashboard"
    >
      <Container maxWidth="sm">
        <div style={{ textAlign: "center" }}>
          <h1>Work in construction 🚧</h1>
        </div>
      </Container>
    </LayoutPage>
  );
}

export default LaborDashboard;
