import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from "@mui/material/Button";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Button
            color="secondary"
            variant="contained"
            className="log-btn"
            onClick={() => loginWithRedirect()}
        >
            Log In
        </Button>
    );
};
// <Button color="secondary" variant="contained" className="log-btn" class old button
export default LoginButton;