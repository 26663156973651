import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Alert } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import LayoutPage from "../../layout/LayoutPage";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import LicensesByUser from "./LicensesByUser";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [authToken, setAuthToken] = useState([]);
  const [authData, setAuthData] = useState([]);

  const { user } = useAuth0();
  const userRole = user[`${process.env.REACT_APP_AUTH0_KEY}/role`]

  const [usersByGroup, setUsersByGroup] = useState();
  const [activeGroup, setActiveGroup] = useState();
  const [open, setOpen] = useState(false);

  let columns = [
      { title: 'Name', field: 'name' },
      { title: 'Email', field: 'email', validate: rowData => allowedDomain(rowData) ? true : 'Email domain doesn\'t belong to the allowed domains.' },
      {
          title: 'Role', field: 'role', lookup: {
              api_group_admin: 'Account Admin',
              api_user: 'User',
          }, initialEditValue: 'api_user', editable: 'never'
      },
      {
          title: 'Account', field: 'group_id',
          initialEditValue: activeGroup,
          render: props => {
              let currentGroup = groups.find(group => group.id === props.group_id);
              return (<p>{currentGroup ? currentGroup.name : null}</p>);
          },
          lookup: groups.reduce((acc, group) => {
            acc[group.id] = group.name;
            return acc;
          }, {}),
      },
      { title: 'License Type', field: 'license_type', sorting: true, defaultSort: 'desc', editable: 'never' },
      { title: 'License Expiration', field: 'expired_at', editable: 'never', type: 'date',
      render: rowData => {
        if (rowData.expired_at){
          let date = new Date(rowData.expired_at);
          let year = date.getFullYear();
          let month = (1 + date.getMonth()).toString().padStart(2, "0");
          let day = date.getDate().toString().padStart(2, "0");
          return month + "/" + day + "/" + year;
        }
        return ""
      } 
    },
      {
          title: 'License', field: 'license_id', hidden: true, searchable: true,
      },
      {
          title: 'Last Login',
          render: rowData => {
              const found = authData.find(authUser => authUser.email === rowData.email);
              if (found) {
                  let date = new Date(found.last_login);
                  let year = date.getFullYear();
                  let month = (1 + date.getMonth()).toString().padStart(2, "0");
                  let day = date.getDate().toString().padStart(2, "0");
                  let lastLogin = month + "/" + day + "/" + year;
          
                return (<>{found ? found.last_login ? lastLogin : 'Never' : 'Never'}</>);
              }
          }
      },

      {
          title: 'Login Count',
          render: rowData => {
              const found = authData.find(authUser => authUser.email === rowData.email);
              return (<>{found ? found.logins_count ? found.logins_count : 0 : 0}</>);
          }
      },
      { title: 'Created At', field: 'created_at', sorting: true, defaultSort: 'desc', editable: 'never', type: 'datetime', 
      render: rowData => {
        let date = new Date(rowData.created_at);
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return month + "/" + day + "/" + year;

      } 
    }
  ];

  useEffect(() => {
    if (authToken.length >= 1) {
      axios.get('https://' + process.env.REACT_APP_AUTH0_DOMAIN + `/api/v2/users?fields=email,phone_verified,created_at,updated_at,last_login,logins_count&search_engine=v3`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          setAuthData(res['data']);
        }
        );
    }
  }, [authToken]);

  const allowedDomain = (rowData) => {
    let groupRow = groups.find((element, index) => {
      let valid = false;

      if (element.id === parseInt(rowData.group_id)) {
        valid = true;
      }
      return valid;
    });

    let userHasAccess = false;

    if (groupRow) {
      const whitelist = groupRow.allowed_domains ? groupRow.allowed_domains.replace(' ', '').split(',') : []; //authorized domains
      if (whitelist.length >= 1) {
        userHasAccess = whitelist.some(
          function (domain) {
            let isValid = false;
            if (rowData.email) {
              const emailSplit = rowData.email.split('@');
              isValid = emailSplit[emailSplit.length - 1].toLowerCase() === domain;
            }
            return isValid;
          });
      } else {
        userHasAccess = true;
      }
    }
    return userHasAccess;
  }

  const getAuthToken = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/rpc/auth0-token`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
      })
      .then(res => {
        setAuthToken(res.data[0]);
      });
  }

  const getUsers = useCallback((activeGroup) => {
    axios.get(`${process.env.REACT_APP_API_URL}/users`, {
      headers: {
        'ngrok-skip-browser-warning': true  
      }
    })
      .then(res => {
        let users_data = res.data;

        var userValidate = users_data.find((singular_user, index) => {
          let valid;

          if (singular_user.email === user.email) {
            valid = true;
          }

          return valid;
        });

        let group_user = userValidate.group_id;
        let role_user = userValidate.role;

        var userTable = users_data.filter((singular_user, index) => {
          let valid_user;

          switch (role_user) {
            case 'api_super_admin':
              valid_user = true;
              break;
            case 'api_group_admin':
              if ((singular_user.group_id === group_user && singular_user.role === 'api_user') || (singular_user.group_id === group_user && singular_user.role === 'api_group_admin')) {
                valid_user = true;
              }
              break;
            default:
              valid_user = false;
              break;
          }

          return valid_user;
        });

        let userWithLicenseType = userTable.map(function (obj) {
          if (obj.license_id) {
            return axios.get(`${process.env.REACT_APP_API_URL}/licenses?uuid=eq.${obj.license_id}`, {
              headers: {
                  'ngrok-skip-browser-warning': true 
              }
            })
              .then(function (results) {
                return obj = { ...obj, 'license_type': results.data[0].type, 'expired_at': results.data[0].expired_at }
              })
          } else {
            return obj = { ...obj, 'license_type': 'No License' }
          }
        })
        Promise.all(userWithLicenseType).then(function (results) {
          setUsers(results)
        })
      });
  }, [user]);

  useEffect(() => {
    if (Object.keys(users).length === 0) {
      getAuthToken();
      getUsers();
      if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_super_admin') {
        axios.get(`${process.env.REACT_APP_API_URL}/groups`, {
          headers: {
            'ngrok-skip-browser-warning': true 
          }
        })
          .then(res => {
            const groups = res.data;
            setGroups(groups);
          })
      } else {
        axios.get(`${process.env.REACT_APP_API_URL}/users?email=eq.${user.email}`, {
          headers: {
            'ngrok-skip-browser-warning': true
          }
        })
          .then(res => {
            let userGroupId = res.data[0].group_id;
            axios.get(`${process.env.REACT_APP_API_URL}/groups?id=eq.${userGroupId}`, {
              headers:{
                'ngrok-skip-browser-warning': true  
              }
            })
              .then(res => {
                const groups = res.data;
                setGroups(groups);
              })
          })
      }
    }
  }, [user, users, getUsers]);

  useEffect(() => {
    if (groups.length > 0) {
      let usersGroups = users.filter(user => user.group_id === groups[0].id);
      setActiveGroup(groups[0].id);
      setUsersByGroup(usersGroups);
    }
    let buttons = document.querySelectorAll('.groups-buttons__item');
    buttons.forEach((button, key) => {
      if (key === 0) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    });
  }, [groups, users])

  return (
    <LayoutPage
      pageTitle='Search Users and Licenses'
      description='Create, update, and delete users.'
      customClass='page-crud'
    >
      <div className="layout-wrapper">
        <div className="groups-buttons">
          {
            userRole === 'api_group_admin' &&
            groups.map((group, key) =>
              <Button
                key={key}
                className={`groups-buttons__item ${key === 0 ? 'active' : ''}`}
                onClick={(e) => {
                  let buttons = document.querySelectorAll('.groups-buttons__item');
                  buttons.forEach(button => button.classList.remove('active'));
                  e.currentTarget.className += " active";
                  setUsersByGroup(users.filter(user => user.group_id === group.id));
                  setActiveGroup(group.id);
                }}
              >
                {group.name}
              </Button>
            )
          }
        </div>
        <MaterialTable
          title="Users"
          columns={columns}
          data={userRole === 'api_super_admin' ? users : (usersByGroup ? usersByGroup : users)}
          components={{
            Toolbar: props => (
              <MTableToolbar {...props} classes={{ root: "board-toolbar" }} />
            ),
          }}
          options={{
            headerStyle: { borderBottomColor: 'rgb(102, 16, 242)', borderBottomWidth: '3px' },
            actionsColumnIndex: 0,
            toolbarButtonAlignment: "left",
            showTitle: false,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          detailPanel={[
            {
              tooltip: 'License Information',
              icon: KeyOutlinedIcon,
              render: props => {
                return (
                  <div
                    style={{
                      backgroundColor: '#e7e7e7',
                      padding: '10px',
                    }}
                  >
                    <LicensesByUser currentUserData={props.rowData} refreshUsers={getUsers} />
                  </div>
                )
              },
            },
          ]}
        />

        <div>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              New password requested successfully
            </Alert>
          </Collapse>
        </div>
      </div>
      <ReportButtons boardType={'Users'} />
    </LayoutPage>
  );
}

export default Users;
