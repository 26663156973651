import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import districtImg from '../../licking_heights.png';
import districtDefaultImg from '../../IB_Simbol_DB_RGB.png';
import PlaceIcon from '@mui/icons-material/Place';
import ReactCardFlip from "react-card-flip";
import Button from "@mui/material/Button";
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

import './district-card.scss';

const DistrictData = ({ data }) => {
  // Split District name and county.
  let districtFullName = (data.district_name).split(',');
  let districtName = districtFullName[0];
  let districtCounty = districtFullName[1];

  const formatAddress = (address) => {
    const acronyms = ["sw", "rd", "way", "ave", "st", "blvd", "dr", "avenue", "pike", "w", "n", "e", "o"];
    let city = "";
    let addressArray = address.split(',');
    let partDirection1 = addressArray[0].split(' ').reverse();
    let index = partDirection1.findIndex((value) => acronyms.includes(value.toLowerCase()));
    if(index === -1) {
      index = partDirection1.findIndex((value) => !isNaN(value))
    }
    city = partDirection1.slice(0, index).reverse().join(' ');
    let direction1 = partDirection1.slice(index).reverse().join(' ');
    let direction2 = `${city}, ${addressArray[1]}`;

    return [direction1, direction2];
  }

  // Split Address
  let fullAddress = formatAddress(data.physical_address.value);

  const [flipCard, setFlipCard] = useState(false);
  let static_data = Object.entries(data.static_data).sort((a, b) => parseFloat(a[1].var_order) - parseFloat(b[1].var_order));

  let table_data = Object.entries(data.table_data).sort((a, b) => parseFloat(a[1].var_order) - parseFloat(b[1].var_order));

  /**
   *
   * @param arr
   *   The array object to be validated.
   * @returns [{}]
   */
  const validated_data = arr => {
    let keysToValidate = ['value', 'state']
    let filtered_array = [];

    arr.forEach(el => {
      keysToValidate.forEach(key => {
        if (el[1][key] === null || el[1][key] === 0 || el[1][key] === '') {
          el[1][key] = "(Data unavailable)";
        }
      })
      filtered_array.push(el)
    });

    return filtered_array;
  };

  let staticRender = (entry, key) => {
    if (!['federal_revenue_per_pupil', 'state_revenue_per_pupil', 'local_revenue_per_pupil', 'other_non_tax_revenue_per_pupil'].includes(entry[0])) {
      return (
        <div key={key}>
          <div className="district-card__data">
            <div className="label">{entry[1].label}</div>
            <div className="value">{entry[1].value}</div>
          </div>
          {
            data && entry[0] === 'revenue_per_pupil' &&
            <>
              <div className="district-card__revenue">
                <span className="label">Federal: </span> <span className="district__value">{data.static_data.federal_revenue_per_pupil.value}</span>
              </div> +
              <div className="district-card__revenue">
                <span className="label">State: </span> <span className="district__value">{data.static_data.state_revenue_per_pupil.value}</span>
              </div> +
              <div className="district-card__revenue">
                <span className="label">Local: </span> <span className="district__value">{data.static_data.local_revenue_per_pupil.value}</span>
              </div> +
              <div className="district-card__revenue">
                <span className="label">Other: </span> <span className="district__value">{data.static_data.other_non_tax_revenue_per_pupil.value}</span>
              </div>
            </>
          }
        </div>
      )
    }
  }

  return (
    <>
      <ReactCardFlip isFlipped={flipCard} flipDirection="horizontal">
        <div className="district-card">
          <div className="district-card__header">
            <div className="district-definition">
              <h2>{districtName}</h2>
              <div className="district-card__address">
                <div className="address-icon">
                  <PlaceIcon />
                </div>
                <div className="address-info">
                  <div>{fullAddress[0]}</div>
                  <div>{fullAddress[1]}</div>
                </div>
              </div>
              <div className="district-card__irn">
                <span className="label">IRN: </span><span className="district__value">{data.irn}</span>
              </div>
              <div className="district-card__info">
                <span className="label">Career-Technical School: </span> {data.career_technical_school.value}
              </div>
              <div className="district-card__info">
                <span className="label">County: </span><span className="district__value">{districtCounty}</span>
              </div>
            </div>
            {
              data.district_name === 'Licking Heights Local SD, Licking'
                ? <div className="district-card__image district-image">
                  <img src={districtImg} alt="Licking Heights Hornets" />
                </div>
                : <div className="district-card__image default-image">
                  <img src={districtDefaultImg} alt="insight board" />
                </div>
            }
          </div>

          <div className="district-card__content">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {
                  validated_data(static_data).map((entry, key) => {
                    return staticRender(entry, key);
                  })
                }
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="district-card">
          <div className="district-card__content">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"><span>Category</span></TableCell>
                    <TableCell align="center"><span>District</span></TableCell>
                    <TableCell align="center"><span>State Average</span></TableCell>
                    <TableCell align="center"><span>State Rank</span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    validated_data(table_data).map(entry => {
                      return (
                        <TableRow
                          key={entry[1].label}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {entry[1].label}
                          </TableCell>
                          <TableCell align="right">{entry[1].value}</TableCell>
                          <TableCell align="right">{entry[1].state}</TableCell>
                          <TableCell align="right">{entry[1].rank_in_state}</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </ReactCardFlip>
      <div className="district-card__actions">
        <Button variant="contained" className="flip-btn" onClick={() => setFlipCard(!flipCard)}>
          <FlipCameraAndroidIcon />
        </Button>
      </div>
    </>
  );
};

export default DistrictData;
