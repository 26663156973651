import React, {useContext, useEffect, useRef, useState} from 'react';
import { styled, ThemeProvider } from "@mui/material/styles";
import LayoutPage from "../../layout/LayoutPage";
import { simpleFetch, valueFormatted } from "../../utils/helpsers";
import ParallelChart from "../../components/ParallelChart/ParallelChart";
import Button from "@mui/material/Button";
import RangeSliderCustomGroup from "../../components/RangeSliderCustomGroup/RangeSliderCustomGroup"
import Grid from '@mui/material/Grid';
import DataGridTable from "../../components/Table/DataGridTable";
import MapView from "../../components/MapChart/MapView";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import BoardBuilder from "../../components/BoardBuilder/BoardBuilder";
import Autocomplete from "@mui/material/Autocomplete";
import Select from '../../components/Select/Select';
import useQuery from "../../utils/userLocation";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import customTheme from '../../utils/theme';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import './CustomGroups.scss'

import {TokenContext} from "../../utils/Context";

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.color.secondary,
  fontWeight: 'bold',
  marginTop: 10,
  '&:hover': {
    backgroundColor: theme.color.secondary,
  },
}));

const MainButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "5px",
  border: "1px solid #773DBD",
  '&:hover': {
    backgroundColor: "#773DBD",
  }
}));

const SecondaryButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "5px",
  marginLeft: "5px"
}));

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0)",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: "11px",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    top:"131px !important",
    left:"91px",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: "#221349",
    color: 'white',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },

    "fieldset": {
      borderColor: "white",
    },

    " input, label, svg": {
      color: "white",
    }
  },
}));

const BaseIcon = (muiIcon) => {
  return styled(muiIcon)(({ theme }) => ({
    cursor: "pointer",
    fontSize: "24px"
  }));
};

const SaveBoardIcon = BaseIcon(SaveOutlinedIcon);
const ShareBoardIcon = BaseIcon(ShareOutlinedIcon);
const FilterIcon = BaseIcon(FilterAltOutlinedIcon);

const Text = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root, MuiOutlinedInput-input': {
    backgroundColor: '#221349',
    color: '#fff',
  },

  '& .MuiInputLabel-root': {
    color: '#fff',

    top: '0px',
  },

  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

function CustomGroups() {
  // User session token
  const {userToken} = useContext(TokenContext);

  const [referenceDistrict, setReferenceDistrict] = useState();
  const [domainVariables, setDomainVariable] = useState();
  // Data variables set on every filter.
  const [filters, setFilters] = useState({ var1: {} });
  // Custom ranges.
  const customGroupData = useRef({
    name: null,
    description: null,
    vars: {
      var1: { varName: null, range: null },
    }
  });

  const colors = ['#D8A8D8', '#D36BC6', '#A03A67', '#773DBD', '#723973', '#1F1646'];

  // User session metadata and data
  const { user } = useAuth0();
  const metadata = user[`${process.env.REACT_APP_AUTH0_KEY}/district`];

  const history = useHistory();
  const query = useQuery();

  // Districts counter
  const [districtsCount, setDistrictsCount] = useState();

  //Data for Map
  const[mapData, setMapData] = useState();
  const[mapAllFilters, setMapAllFilters] = useState();
  const[mapLastFilter, setMapLastFilter] = useState();

  // Parallel chart data
  const [parallelData, setParallelData] = useState();
  const [rangeData, setRangeData] = useState();
  // Table Data
  const [gridTableData, setGridTableData] = useState();

  const handleFilter = () => {
    setBtnFilter(null);
  };

  // Message response on save group.
  const [postResponse, setPostResponse] = useState(null);

  const [displayResponseMessage, setDisplayResponseMessage] = React.useState(false);

  // Switch highlight filtered districts on map.
  const [highlightLastFilter, setHighlightLastFilter] = useState(false);

  const [boardData, setBoardData] = useState();
  const [existingGroup, setExistingGroup] = useState();

  const [year, setYear] = useState();
  const [disabledApply, setDisabledApply] = useState(true);

  const [activeUser, setActiveUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [usersFormat, setUsersFormat] = useState([]);
  const [shareWith, setShareWith] = useState([]);

  const [btnFilter, setBtnFilter] = useState(null);
  const [btnSaved, setBtnSaved] = useState(null);
  const [btnShared, setBtnShared] = useState(null);

  const openFilters = Boolean(btnFilter);
  const openSaved = Boolean(btnSaved);
  const openShared = Boolean(btnShared);

  const [action, setAction] = useState();

  const groupName = useRef('');
  const groupDescription = useRef('');

  const [loadingData, setLoadingData] = useState(false);

  const clickFilters = (event) => {
    setBtnFilter(event.currentTarget);
  };

  const closeFilters = () => {
    setBtnFilter(null);
  };

  const clickSaved = (event) => {
    setBtnSaved(event.currentTarget);
  };

  const closeSaved = () => {
    setBtnSaved(null);
  };

  const clickShared = (event) => {
    setBtnShared(event.currentTarget);
  };

  const closeShared = () => {
    setBtnShared(null);
  };

  const handleSubmit = () => {
    let irns =
      customGroupData.current.vars[
        `var${Object.keys(customGroupData.current.vars).length
        }`
      ].irns;

    axios.post(
      "https://insightboard.org/api/custom_groups",
      JSON.stringify({
        label: groupName.current,
        description: groupDescription.current,
        irns: irns,
        custom_ranges: customGroupData.current.vars,
        reference_irn: referenceDistrict,
        year: year,
        available_users_share: shareWith,
        created_by: user.email,
      }),
      {
        headers: {
          'Prefer': 'return=representation',
          "Content-Type": "application/json",
        }
      }
    ).then((response) => {
      setTimeout(() =>
        history.push(`?shared=${response.data[0].uuid}`), 1000);
    });
    setBtnSaved(null);
  };

  const findVar = (variable, variableName) => {
    if (domainVariables) {
      let matchedVar = domainVariables.find((domainVar) => {
        let valid = false;

        if (domainVar.value_var === variableName) {
          valid = true;
        }

        return valid;
      });

      if (variable) {
        matchedVar['min_range'] = variable['originalRange'][0];
        matchedVar['max_range'] = variable['originalRange'][1];
      }

      return matchedVar;
    }
  };

  /**
   * Updates the hooks related with the filter data.
   * @param {object} variableData: All data object fetched.
   * @param {string} varKey: the string assigned to be the object key.
   */
  const updateFilter = ({ variableData, varKey }) => {
    setFilters(prevState => ({
      ...prevState,
      [varKey]: null
    }));

    simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_range_per_year?_var=${variableData.value_var}&_irn=${referenceDistrict.value}&_year=${year.value}`)
      .then((data) => {
        variableData['min_range'] = data[0].min_var;
        variableData['max_range'] = data[0].max_var
        let varData = { ...variableData, customRange: [variableData.min_range, variableData.max_range] }
        customGroupData.current.vars = {
          ...customGroupData.current.vars, [varKey]: {
            varName: varData.value_var,
            range: varData.customRange,
            originalRange: varData.customRange
          }
        }
        // Minimum time required to update the slider.
        setTimeout(() => {
          setFilters(prevState => ({
            ...prevState,
            [varKey]: varData
          }));
        }, 100);
      })
  }

  /**
   * Verify last filter data to enable add button.
   * @returns {boolean}
   */
  const addFilterButton = () => {
    let lastFilter = filters[(Object.keys(filters)).slice(-1).pop()];
    return !(lastFilter && lastFilter.hasOwnProperty('label'))
  };


  useEffect(()=>{
    highlightLastFilter ? setMapData(mapLastFilter) : setMapData(mapAllFilters);
  }, [highlightLastFilter])
  /**
   * Request all data required for data display components.
   */
  const getFiltersInformation = () => {
    let var1 = customGroupData.current.vars.var1;
    let var2 = customGroupData.current.vars.var2;
    let var3 = customGroupData.current.vars.var3;
    let var4 = customGroupData.current.vars.var4;
    let var5 = customGroupData.current.vars.var5;
    let var6 = customGroupData.current.vars.var6;

    // Get Map Data.
    setLoadingData(true);
    setHighlightLastFilter(false);
    simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_map_filter?_reference=${referenceDistrict.value}&_var1=${var1 ? var1.varName : 'NA'}&_min_var1=${var1 ? var1.range[0] : 'NA'}&_max_var1=${var1 ? var1.range[1] : 'NA'}&_var2=${var2 ? var2.varName : 'NA'}&_min_var2=${var2 ? var2.range[0] : 'NA'}&_max_var2=${var2 ? var2.range[1] : 'NA'}&_var3=${var3 ? var3.varName : 'NA'}&_min_var3=${var3 ? var3.range[0] : 'NA'}&_max_var3=${var3 ? var3.range[1] : 'NA'}&_var4=${var4 ? var4.varName : 'NA'}&_min_var4=${var4 ? var4.range[0] : 'NA'}&_max_var4=${var4 ? var4.range[1] : 'NA'}&_var5=${var5 ? var5.varName : 'NA'}&_min_var5=${var5 ? var5.range[0] : 'NA'}&_max_var5=${var5 ? var5.range[1] : 'NA'}&_var6=${var6 ? var6.varName : 'NA'}&_min_var6=${var6 ? var6.range[0] : 'NA'}&_max_var6=${var6 ? var6.range[1] : 'NA'}&_year=${year.value}`)
        .then(data => {
          let filtersLength = Object.keys(filters).length;
          let mapPolygons = data.map(district => {
            return {...district, ['color']: colors[`${district.filter - 1}`]}
          })

          // Store district by filters result.
          for (let i = 1; i <= filtersLength; i++) {
            let group = mapPolygons.filter(district => district.filter === i || district.filter === 0);
            customGroupData.current.vars[`var${i}`].districtsCount = i === 1 ? data.length : group.length;
            customGroupData.current.vars[`var${i}`].irns = i === 1 ? data.map( district => district.irn) : group.map( district => district.irn);

            if ( i > 1 || i < filtersLength) {
              for( let d = i; d < filtersLength; d++) {
                let nextFilterGroup = mapPolygons.filter(district => district.filter === d + 1);
                group = [...group, ...nextFilterGroup];
              }
            }

            // Assign las filter in a hook to set use info on "show last filter" option.
            if (i === filtersLength) {
              setMapLastFilter(group);
              setDistrictsCount(group.length)
            }

            // Update filter count (except first and last).
            customGroupData.current.vars[`var${i}`].districtsCount =  group.length;
            setFilters(prevState => ({
              ...prevState,
              [`var${i}`]: {...filters[`var${i}`],
                districtsCount: i === 1 ? data.length : group.length}
            }))
          }
          
          setMapData(mapPolygons);
          setMapAllFilters(mapPolygons);
          setLoadingData(false);
        }).then(()=>{
      let lastFilter = Object.keys(customGroupData.current.vars).pop();
      // District IRN's required for Grid Table endpoint.
      let irns = customGroupData.current.vars[lastFilter].irns;
      // Name of each variable stored on custom ranges object.
      let vars = Object.keys(customGroupData.current.vars).map(key => customGroupData.current.vars[key]['varName']);
      // Fetching grid table data.
      simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_tabloide?select=irn,district_name,${vars}&irn=in.(${irns})&file_year=eq.${year.value}`)
          .then(data => {
            setGridTableData({
              // Process data to build rows object adding id key to data object.
              rows: data.map((obj, key) => ({ ...obj, id: key + 1 })),
              // Process filters data to build columns objects with the required structure.
              columns: [{
                field: 'district_name',
                headerName: 'District',
                minWidth: 150,
                flex: 1,
              }, ...Object.keys(filters).map(key => {
                let fieldBase = filters[key];

                if (filters[key].hasOwnProperty('varName')) {
                  fieldBase = findVar(filters[key], filters[key]['varName']);
                }
                return {
                  field: fieldBase['value_var'],
                  headerName: fieldBase['label'],
                  minWidth: 150,
                  flex: 1,
                  valueFormatter: (params) => {
                    if (typeof params.value == "number") {
                      return valueFormatted(params.value, "")
                    }
                  },
                }
              })]
            })
          });
    })

    setParallelData(null);
    // Hook to fill yAxis for parallel chart.
    setRangeData(Object.keys(filters).map(variable => {
      return {
        "min": filters[variable].min_range,
        "max": filters[variable].max_range,
        "higher_number": filters[variable].higher_number,
      }
    }));
    // Get Parallel coordinates data.
    simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_parallel_data?_reference=${referenceDistrict.value}&_var1=${var1 ? var1.varName : 'NA'}&_min_var1=${var1 ? var1.range[0] : 'NA'}&_max_var1=${var1 ? var1.range[1] : 'NA'}&_var2=${var2 ? var2.varName : 'NA'}&_min_var2=${var2 ? var2.range[0] : 'NA'}&_max_var2=${var2 ? var2.range[1] : 'NA'}&_var3=${var3 ? var3.varName : 'NA'}&_min_var3=${var3 ? var3.range[0] : 'NA'}&_max_var3=${var3 ? var3.range[1] : 'NA'}&_var4=${var4 ? var4.varName : 'NA'}&_min_var4=${var4 ? var4.range[0] : 'NA'}&_max_var4=${var4 ? var4.range[1] : 'NA'}&_var5=${var5 ? var5.varName : 'NA'}&_min_var5=${var5 ? var5.range[0] : 'NA'}&_max_var5=${var5 ? var5.range[1] : 'NA'}&_var6=${var6 ? var6.varName : 'NA'}&_min_var6=${var6 ? var6.range[0] : 'NA'}&_max_var6=${var6 ? var6.range[1] : 'NA'}&_year=${year.value}`)
      .then(data => {
        setParallelData(data[0]);
      })
  }

  const shareWithUsers = () => {
    if (existingGroup[0]) {
      if (shareWith.length === 0) {
        setDisplayResponseMessage(true);
        setPostResponse(true);
        closeShared();
      } else {
        let uuid = existingGroup[0].uuid;
        let bodyJson = existingGroup[0];

        bodyJson["available_users_share"] = shareWith;

        bodyJson = JSON.stringify(bodyJson);

        fetch(`https://insightboard.org/api/custom_groups?uuid=eq.${uuid}`, {
          method: "PUT",
          headers: {
            "ngrok-skip-browser-warning": true,
            Authorization: "Bearer " + userToken,
            "Content-Type": "application/json",
          },
          body: bodyJson,
        }).then((response) => {
          setDisplayResponseMessage(true);
          setPostResponse(response);
          closeShared();
        });
      }
    }
  };

  // Request init data
  useEffect(() => {
    if (metadata) {
      const userSessionType = metadata.hasOwnProperty('sessionType') ? metadata.sessionType : 'forbidden';

      switch (userSessionType) {
        case 'district-access':
          window.localStorage.setItem('session-type', 'district-access');
          break;
        case 'full-access':
          window.localStorage.setItem('session-type', 'full-access');
          break;
        case 'multiple-district-access':
          window.localStorage.setItem('session-type', 'multiple-district-access');
          break;
        case 'forbidden':
          history.push('/AccessDenied');
          break;
        default:
          history.push('/AccessDenied');
          break;
      }
    } else {
      history.push('/AccessDenied');
    }
  }, [history, user, metadata]);

  useEffect(() => {
    if (query.get('shared')) {
      simpleFetch(`${process.env.REACT_APP_API_URL}/custom_groups?uuid=eq.` + query.get('shared'))
        .then(customGroups => {
          setExistingGroup(customGroups);
          let groupRetrieve = customGroups[0];

          if (groupRetrieve.available_users_share.some(userShare => userShare.value === user.email) || groupRetrieve.created_by === user.email) {
            if (groupRetrieve.reference_irn) {
              setReferenceDistrict(groupRetrieve.reference_irn);
              setYear(groupRetrieve.year);

              customGroupData.current = {
                name: groupRetrieve.label,
                description: groupRetrieve.description,
                vars: groupRetrieve.custom_ranges
              };
              setDisabledApply(false);
              setFilters(groupRetrieve.custom_ranges);
              setShareWith(groupRetrieve.available_users_share);
            }
          } else {
            history.push('/AccessDenied');
          }
        });
    }
  }, [query, user.email, history]);

  const[loadGroup, setLoadGroup] = useState(true);

  useEffect(()=> {
    if(mapData && (year && year.value) && loadGroup && query.get('shared') && customGroupData.current.vars.var1.range) {
      setLoadGroup(false);
      getFiltersInformation();
    }
  },[mapData, year, loadGroup, query, customGroupData,getFiltersInformation])

  useEffect(() => {
    if (usersFormat.length === 0) {
      async function getGroups() {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/groups`
        );
        return response.data;
      }

      getGroups().then( groups => {
        setUserGroups(groups);
        axios.get(`${process.env.REACT_APP_API_URL}/users`, {
          headers: {
            'ngrok-skip-browser-warning': true
          }
        })
            .then(res => {
              setUsers(res.data);

              let currentUser = res.data.find((element, index) => {
                let valid = false;

                if (element.email === user.email) {
                  valid = true;
                }

                return valid;
              });

              setActiveUser(currentUser);
            });

        let filteredUsers = users.filter((userF) => userF.group_id === activeUser.group_id ? userF : false);
        let usersFormatted = filteredUsers.map( (userF) => {
          let userGroup = userGroups.find(userGroup => userGroup.id === userF.group_id);
          let filteredResult = null;
          if (userF && userGroup) {
            filteredResult = { 'value': userF.email, 'label': userF.name + ' @ ' + userGroup.name };
          }
          return filteredResult;
        });
        setUsersFormat(usersFormatted);
      });
    }
  }, [activeUser.group_id, user.email, userGroups, users, usersFormat.length]);

  useEffect(() => {
    // Hiding highlight switch if there is not second filter.
    if (year) {
      // Domain variables
      simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_source_variable?file_year=eq.${year.value}&visible=eq.true&order=label.asc`)
        .then(data => setDomainVariable(data))
      // Get map data
      simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&label=eq.All%20Ohio%27s%20School%20Districts`)
        .then(data => {
          let irns = data[0].irns;
          return simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_polygons?irn=in.(${irns})&file_year=eq.${year.value}`);
        }).then(mapPolygons => {
          setMapData(mapPolygons.map(item => {
            return {
              ...item,
              color: '#1f16460D'
            }
          }));
        });
    }
  }, [year]);

  useEffect(() => {
    if (boardData) {
      setReferenceDistrict(boardData.referenceDistrict);
      setYear(boardData.year);
    }
  }, [boardData]);

  return (
    <LayoutPage
      pageTitle="Custom Group Editor"
      description="Build custom groups based on filters"
      type="dashboard"
      customClass="custom-groups"
    >
      <div className="header__bottom">
        <div className="layout-wrapper">
          <section
            className={`section section--data-filters district-filters--active`}
          >
            <Grid className={`align-container basicFilters`} container spacing={0} textAlign='center' style={{ marginBottom: 20 }}>
              <Grid item xs={5} sm={2} display='flex' >
                <TooltipStyled title="Filters">
                  <MainButton
                    id="button-filters"
                    aria-controls={openFilters ? 'menu-filters' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilters ? 'true' : undefined}
                    variant="contained"
                    onClick={clickFilters}
                    style={openFilters ? { backgroundColor: "#221349", borderColor: "#221349" } : {backgroundColor: "#773DBD"}}
                  >
                    <FilterIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"} sx={{marginBottom:0}} variant="caption" display="block" gutterBottom>
                      Filters
                    </Typography>
                  </MainButton>
                </TooltipStyled>
                <StyledMenu
                  id="menu-filters"
                  MenuListProps={{
                    'aria-labelledby': 'button-filters',
                  }}
                  anchorEl={btnFilter}
                  open={openFilters}
                  onClose={closeFilters}
                >
                  <MenuItem disableRipple>
                    <BoardBuilder
                      year={true}
                      boardData={setBoardData}
                      onSubmit={() => handleFilter()}
                    />
                  </MenuItem>
                </StyledMenu>
                <TooltipStyled title="Save Board">
                  <SecondaryButton
                    id="button-saved"
                    aria-controls={openSaved ? 'menu-saved' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSaved ? 'true' : undefined}
                    variant="contained"
                    disabled={!gridTableData ? existingGroup ? false : true : false}
                    style={gridTableData ? {border: "1px solid #773DBD"} : { border: "1px solid #808080"}}
                    onClick={clickSaved}
                  >
                    <SaveBoardIcon style={{ color: gridTableData ? "#773DBD": existingGroup ? "#773DBD" : '#BFBDBD' }} />
                  </SecondaryButton>
                </TooltipStyled>
                <StyledMenu
                  id="menu-saved"
                  MenuListProps={{
                    'aria-labelledby': 'button-saved',
                  }}
                  anchorEl={btnSaved}
                  open={openSaved}
                  onClose={closeSaved}
                >
                  <MenuItem disableRipple className="save-group-form">
                    <div className={`save-group-form__wrapper layout-wrapper`}>
                      <Grid container spacing={2} className="custom-groups__form-wrapper">
                        {!action &&
                          <Grid item xs={12} sm={12} className="custom-groups__form-item doneButton" display={'flex'} style={{ marginTop: 15 }}>
                            <ThemeProvider theme={customTheme}>
                              <ActionButton onClick={() => !existingGroup ? setAction('new-group') : history.push('/custom-groups')} variant="contained" color="primary" type="submit">
                                {!existingGroup ? 'Save Custom Group' : 'Save a New Custom Group'}
                              </ActionButton>
                            </ThemeProvider>
                          </Grid>
                        }
                        {action === 'new-group' &&
                          <>
                            <Grid item xs={12} sm={12} className="custom-groups__form-item doneButton">
                              <Text
                                style={{ marginTop: 35 }}
                                autoComplete='off'
                                className='form-field form-field--group-name'
                                id="GroupName"
                                fullWidth 
                                variant="outlined" 
                                placeholder="Group Name" 
                                required
                                onChange={e => groupName.current = e.target.value}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} className="custom-groups__form-item doneButton">
                              <Text
                                style={{ marginTop: 35 }}
                                autoComplete='off'
                                className='form-field form-field--group-name'
                                id="GroupDescription"
                                fullWidth 
                                variant="outlined" 
                                placeholder="Group Description" 
                                required
                                onChange={e => groupDescription.current = e.target.value}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} className="custom-groups__form-item doneButton">
                              <ThemeProvider theme={customTheme}>
                                <ActionButton onClick={() => handleSubmit()} variant="contained" color="primary" type="submit">
                                  Save
                                </ActionButton>
                              </ThemeProvider>
                            </Grid>
                          </>
                        }
                      </Grid>
                    </div>
                  </MenuItem>
                </StyledMenu>
                {existingGroup &&
                  <>
                  <TooltipStyled title="Share Board">
                    <SecondaryButton
                      id="button-shared"
                      aria-controls={openShared ? 'menu-shared' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openShared ? 'true' : undefined}
                      variant="contained"
                      disabled={existingGroup && existingGroup[0].created_by !== user.email ? true : false}
                      style={existingGroup ? {border: "1px solid #773DBD"} : { border: "1px solid #808080"}}s
                      onClick={clickShared}
                    >
                      <ShareBoardIcon style={{ color: existingGroup ? "#773DBD" : "#BFBDBD" }} />
                    </SecondaryButton>
                  </TooltipStyled>
                    <StyledMenu
                      id="menu-shared"
                      MenuListProps={{
                        'aria-labelledby': 'button-shared',
                      }}
                      anchorEl={btnShared}
                      open={openShared}
                      onClose={closeShared}
                    >
                      <MenuItem disableRipple>
                        {usersFormat &&
                          <Grid container spacing={0} textAlign='center' >
                            <Grid item sm={12} display='flex' >
                              <Select
                                options={usersFormat}
                                id={"shared-with"}
                                label=""
                                defaultValue={shareWith}
                                isMultiple={true}
                                onChange={(e) => {
                                  setShareWith(e);
                                }
                                } />
                            </Grid>
                            <Grid item sm={12} display='flex' >
                              <ThemeProvider theme={customTheme}>
                                <ActionButton variant="contained" color="primary" onClick={() => shareWithUsers()}>
                                  Share with users
                                </ActionButton>
                              </ThemeProvider>
                            </Grid>
                          </Grid>}
                      </MenuItem>
                    </StyledMenu>
                  </>
                }
              </Grid>
            </Grid>
            <div className="data-filters__item group-counter__wrapper">
              <div className="group-counter">
                {referenceDistrict && year && (
                  <div className="group-counter__versus">{`${referenceDistrict.label} - ${year.label}`}</div>
                )}
                {districtsCount && (
                  <div className="group-counter__result">{`${districtsCount} districts in the comparison group.`}</div>
                )}
                {existingGroup && (
                  <>
                  <div className="group-counter__result">{`${existingGroup[0].label} - ${existingGroup[0].description}`}</div>
                  <div>Saved custom groups are read-only.</div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="layout-wrapper">
        <section className="custom-groups__filters-form">
          <Grid container spacing={2}>
            <Grid className="map-chart" item xs={12} md={6} lg={4}>
              {
                loadingData &&
                    <div className={"custom-groups__loading"}>
                      <div className={"custom-groups__loading-message"}>Loading Map Data...</div>
                      <CircularProgress/>
                    </div>
              }
              {(mapData && year) && (
                <>
                  <MapView
                      data={mapData}
                      districtIrn={
                        referenceDistrict ? referenceDistrict.value : null
                      }
                      height="390px"
                      zoom="6.5"
                  />
                  { filters.var2 && (
                    <FormGroup className="highlight-switch">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={highlightLastFilter}
                            onChange={()=> setHighlightLastFilter(!highlightLastFilter)}
                            disabled={
                              !(
                                filters[`var${Object.keys(filters).length}`] &&
                                filters[`var${Object.keys(filters).length}`]
                                  .districtsCount
                              )
                            }
                          />
                        }
                        label="Show last filter only"
                      />
                    </FormGroup>
                  )}
                </>
              )}
            </Grid>
            <Grid className="custom-groups__filters" item xs={12} md={6} lg={8}>
              {referenceDistrict && year ? (
                <>
                  <Grid
                    container
                    spacing={2}
                    className="custom-groups__filters-group"
                  >
                    {filters &&
                      domainVariables &&
                      Object.keys(filters).map((filter, key) => {
                        const id = key + 1;
                        return (
                          <Grid item xs={12} lg={6} key={key}>
                            <div className={`var-option var-${id}`}>
                              <span
                                className="var-option__color-indicator"
                                style={{ backgroundColor: colors[key] }}
                              ></span>
                              {Object.keys(filters).length === id && id !== 1 && !existingGroup && (
                                <Button
                                  className="remove-filter"
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    // Removing last filter value
                                    setFilters((prevState) => {
                                      let newState = { ...prevState };
                                      delete newState[`var${id}`];
                                      return newState;
                                    });
                                    // Removing las current custom range.
                                    delete customGroupData.current.vars[
                                      `var${id}`
                                    ];
                                    setDisabledApply(false);
                                  }}
                                >
                                  x
                                </Button>
                              )}
                              <span className="filter__counter">
                                {filters[`var${id}`] &&
                                  filters[`var${id}`].districtsCount &&
                                  `${filters[`var${id}`].districtsCount
                                  } districts left`}
                              </span>
                              <Autocomplete
                                id={`var-${id}`}
                                options={domainVariables}
                                disabled={existingGroup ? true : false}
                                defaultValue={
                                  filters[`var${id}`]
                                    ? findVar(
                                      undefined,
                                      filters[`var${id}`]["varName"]
                                    )
                                    : null
                                }
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                fullWidth={true}
                                onChange={(event, newValue) => {
                                  // Matomo code.
                                  if (newValue) {
                                    window._paq.push([
                                      "trackEvent",
                                      "Board CustomGroups",
                                      `Clicked: #${id}`,
                                      `Selected: ${newValue.label}`,
                                    ]);
                                  }
                                  updateFilter({
                                    variableData: newValue,
                                    varKey: `var${id}`,
                                  });
                                  setDisabledApply(false);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label={`#${id}`} />
                                )}
                              />
                              {filters[`var${id}`] &&
                                filters[`var${id}`].customRange && (
                                  <RangeSliderCustomGroup
                                    disabled={existingGroup ? true : false}
                                    district={referenceDistrict}
                                    year={year}
                                    varData={filters[`var${id}`]}
                                    onChange={(val) =>
                                    (customGroupData.current.vars = {
                                      ...customGroupData.current.vars,
                                      [`var${id}`]: {
                                        varName:
                                          filters[`var${id}`].value_var,
                                        range: val,
                                        originalRange:
                                          filters[`var${id}`].customRange,
                                      },
                                    })
                                    }
                                    values={[
                                      filters[`var${id}`].customRange[0],
                                      filters[`var${id}`].customRange[1],
                                    ]}
                                  />
                                )}
                              {filters[`var${id}`] &&
                                filters[`var${id}`].range && (
                                  <RangeSliderCustomGroup
                                    disabled={existingGroup ? true : false}
                                    district={referenceDistrict}
                                    year={year}
                                    varData={findVar(
                                      filters[`var${id}`],
                                      filters[`var${id}`]["varName"]
                                    )}
                                    onChange={(val) =>
                                    (customGroupData.current.vars = {
                                      ...customGroupData.current.vars,
                                      [`var${id}`]: {
                                        varName:
                                          filters[`var${id}`].value_var,
                                        range: val,
                                        originalRange:
                                          filters[`var${id}`].customRange,
                                      },
                                    })
                                    }
                                    values={filters[`var${id}`].range}
                                  />
                                )}
                            </div>
                          </Grid>
                        );
                      })}
                  </Grid>
                  <div className="custom-groups__filter-actions">
                    {!filters.var6 && (
                      <Button
                        color="primary"
                        disabled={addFilterButton()}
                        onClick={() => {
                          window._paq.push([
                            "trackEvent",
                            "Board CustomGroups",
                            "Clicked: Add filters",
                          ]);
                          let activeFilters = Object.keys(filters).length;
                          if (activeFilters < 6) {
                            setFilters((prevState) => ({
                              ...prevState,
                              [`var${activeFilters + 1}`]: {},
                            }));
                          }
                          setDisabledApply(true);
                        }}
                      >
                        + Add filter
                      </Button>
                    )}
                    {(filters.var1 && !existingGroup) &&  (
                      <Button
                        disabled={disabledApply}
                        id={'apply_filters'}
                        className={"submit-filters"}
                        variant="contained"
                        style={{
                          backgroundColor: disabledApply ? "#CCC" : "#773DBD",
                          color: "#fff",
                        }}
                        onClick={() => {
                          window._paq.push([
                            "trackEvent",
                            "Board CustomGroups",
                            "Clicked: Apply filters",
                          ]);
                          window._paq.push(["trackGoal", 8]);
                          getFiltersInformation();
                        }}
                      >
                        Apply filters
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <div className="form-advice">
                  {" "}
                  Please select a Reference district
                </div>
              )}
            </Grid>
          </Grid>
        </section>
        {parallelData && (
          <div className="parallel-chart">
            <ParallelChart
              data={parallelData}
              rangeData={rangeData}
              referenceDistrictId={referenceDistrict.value}
            />
          </div>
        )}
        {gridTableData && (
          <section className="custom-groups__data-table">
            <p>
              <strong>
                Note: The filter at the table has an effect only on the table
                output, not the custom group.
              </strong>
            </p>
            <DataGridTable
              columns={gridTableData.columns}
              rows={gridTableData.rows}
            />
          </section>
        )}
      </div>

      <ReportButtons
        boardType={"Board Insight Groups"}
        collectedData={{
          label: customGroupData.current.name,
          description: customGroupData.current.description,
          custom_ranges: customGroupData.current.vars,
        }}
      />
      {
        postResponse &&
        <Snackbar
          className="custom-groups__save-message"
          open={displayResponseMessage}
          autoHideDuration={6000}
          onClose={() => setDisplayResponseMessage(false)}
          message={
            postResponse.ok === true || postResponse.status === 201
              ? <p className='success'>
                Shared with users successfully!
              </p>
              : <p className='error'>
                Something went wrong. Error <strong>{postResponse.status}</strong>
              </p>
          }
          action={
            <div onClose={() => setDisplayResponseMessage(false)}>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setDisplayResponseMessage(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      }
    </LayoutPage>
  );
}

export default CustomGroups;
