import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import DialogBox from "../DialogBox/DialogBox";

LicenseInfo.setLicenseKey(
  'a9d24100595887f4df6a2e3e3a05582fT1JERVI6MzkzMjMsRVhQSVJZPTE2Nzg0Mjg5NjAwMDAsS0VZVkVSU0lPTj0x',
);

export default function DataGridTable({ columns, rows, sortField, sortOrder }) {
  const [pageSize, setPageSize] = useState(10);
  const [resolution, setResolution] = useState('')

  useEffect(() => {
    setResolution(window.innerWidth < 1023 ? 'mobile' : 'desktop');
  }, [resolution])

  let rowsCount = rows ? Object.keys(rows).length : null;

  const [sortModel, setSortModel] = React.useState([
    {
      field: sortField || columns[0].field,
      sort: sortOrder || 'asc',
    },
  ]);

  const columnsCellValue = columns.map(function (obj) {
    return {
      ...obj,
      renderCell: function (params) {
        let cellVal = params.value;
        if (cellVal && cellVal.length > 40) {
          if (resolution === 'mobile') return (
            <>
              <DialogBox
                button={false}
                ctaText={params.value}
                content={params.value}
                helpText={'Click to display full text'}
                title={`${Object.values(params.row)[1]}: ${params.colDef.headerName}`} />
            </>
          );
          return <div title={params.value}> {params.value}</div>;
        }
      },
    };
  });

  return (
    rowsCount &&
    <div style={{ height: ((rowsCount < pageSize ? rowsCount : pageSize) * 52 + 168), width: '100%', marginTop: 20, marginBottom: 20 }}>
      <DataGridPro
        rows={rows}
        columns={columnsCellValue}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection={false}
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
        }}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </div>
  );
}
