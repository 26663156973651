import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Alert, AlertTitle } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { getToken, simpleFetch } from '../../utils/helpsers';
import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import Select from '../../components/Select/Select';
import Collapse from "@mui/material/Collapse";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const randomTempPassword = (length) => {
  return Array(length).fill().map(_ => String.fromCharCode(33 + Math.random() * (127 - 33))).join('');
}

const UsersByAccount = ({ groupID, groupName }) => {
  const [groups, setGroups] = useState([]);
  const [licensesByGroup, setLicensesByGroup] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [authToken, setAuthToken] = useState([]);
  const [authData, setAuthData] = useState([]);
  const { user } = useAuth0();
  const userRole = user[`${process.env.REACT_APP_AUTH0_KEY}/role`]

  const [usersByGroup, setUsersByGroup] = useState();
  const [passwordReset, setPasswordReset] = useState([]);

  let columns = [];

  useEffect(() => {
    if (authToken.length >= 1) {
      axios.get('https://' + process.env.REACT_APP_AUTH0_DOMAIN + `/api/v2/users?fields=email,phone_verified,created_at,updated_at,last_login,logins_count&search_engine=v3`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          setAuthData(res['data']);
        }
        );
    }
  }, [authToken]);

  const allowedDomain = (rowData) => {
    let groupRow = groups.find((element, index) => {
      let valid = false;

      if (element.id === parseInt(rowData.group_id)) {
        valid = true;
      }

      return valid;
    });

    let userHasAccess = false;

    if (groupRow) {
      const whitelist = groupRow.allowed_domains ? groupRow.allowed_domains.replace(' ', '').split(',') : []; //authorized domains
      if (whitelist.length >= 1) {
        userHasAccess = whitelist.some(
          function (domain) {
            let isValid = false;
            if (rowData.email) {
              const emailSplit = rowData.email.split('@');
              isValid = emailSplit[emailSplit.length - 1].toLowerCase() === domain;
            }
            return isValid;
          });
      } else {
        userHasAccess = true;
      }
    }
    return userHasAccess;
  }

  const handlePasswordRequest = (email) => {
    let resetPassword = {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: email,
      connection: "Username-Password-Authentication",
    };

    axios.post(
      "https://" +
      process.env.REACT_APP_AUTH0_DOMAIN +
      "/dbconnections/change_password",
      resetPassword,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setPasswordReset((emailList) => [...emailList, email]);
  };

  if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_super_admin') {
    columns = [
      { title: 'Name', field: 'name' },
      { title: 'Email', field: 'email', validate: rowData => allowedDomain(rowData) ? true : 'Email domain doesn\'t belong to the allowed domains.' },
      {
        title: 'Role', field: 'role', lookup: {
          api_super_admin: 'Super Admin',
          api_group_admin: 'Account Admin',
          api_user: 'User',
        }
      },
      {
        title: 'Account', field: 'group_id',
        initialEditValue: groupID,
        render: props => groupName,
        editComponent: props => groupName
      },
      {
        title: 'License', field: 'license_id',
        render: rowData => rowData.license_id ? rowData.license_type : 'No License',
        editComponent: props => {
          let licensesFormat = licensesByGroup.map((license) => ({ 'value': license.value, 'label': license.label }));

          if (props.rowData.license_id) {
            licensesFormat = licensesFormat.filter((license) => license.value !== props.rowData.license_id);
          }

          if (props.value) {
            licensesFormat.push({ value: props.value, label: props.rowData.license_type });
          }

          let selectedLicense = licensesFormat.find(license => license.value === props.value);

          if (selectedLicense) {
            licensesFormat = licensesFormat.filter(license => license.value !== selectedLicense.value);
          }

          return (
            <Select
              options={licensesFormat}
              label={'Licenses'}
              id={'licenses'}
              defaultValue={selectedLicense}
              onChange={e => {
                if (e) return props.onChange(e.value)
              }}
            />
          );
        }
      },
      //{ title: 'Dev Enabled', field: 'dev_enabled' },
      {
        title: 'Reset Password',
        render: rowData => {
          return (
            <>
              <Button variant="outlined" onClick={() => handlePasswordRequest(rowData.email)}>
                Request New Password
              </Button>
              <Collapse in={passwordReset.includes(rowData.email)}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setPasswordReset((emailList) => emailList.filter(email => email !== rowData.email));
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Password requested
                </Alert>
              </Collapse>
            </>
          );
        }
      },
      {
        title: 'Last Login',
        render: rowData => {

          const found = authData.find(authUser => authUser.email === rowData.email);
          if (found) {
            let date = new Date(found.last_login);
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, "0");
            let day = date.getDate().toString().padStart(2, "0");
            let lastLogin = month + "/" + day + "/" + year;
    
          return (<>{found ? found.last_login ? lastLogin : 'Never' : 'Never'}</>);
        }
        }
      },

      {
        title: 'Login Count',
        render: rowData => {
          const found = authData.find(authUser => authUser.email === rowData.email);
          return (<>{found ? found.logins_count ? found.logins_count : 0 : 0}</>);
        }
      },
      { title: 'Is sharing enabled?', field: 'enable_share', type: 'boolean' },
      { title: 'Created At', field: 'created_at', sorting: true, defaultSort: 'desc', editable: 'never', type: 'datetime', 
        render: rowData => {
          let date = new Date(rowData.created_at);
          let year = date.getFullYear();
          let month = (1 + date.getMonth()).toString().padStart(2, "0");
          let day = date.getDate().toString().padStart(2, "0");
          return month + "/" + day + "/" + year;
        } 
      }
    ];
  } else if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_group_admin') {
    columns = [
      { title: 'Name', field: 'name' },
      { title: 'Email', field: 'email', validate: rowData => allowedDomain(rowData) ? true : 'Email domain doesn\'t belong to the allowed domains.' },
      {
        title: 'Role', field: 'role', lookup: {
          api_super_admin: 'Super Admin',
          api_group_admin: 'Account Admin',
          api_user: 'User',
        }, initialEditValue: 'api_user', editable: 'never'
      },
      {
        title: 'Account', field: 'group_id',
        initialEditValue: groupID,
        render: props => groupName,
        editComponent: props => groupName
      },
      {
        title: 'License', field: 'license_id',
        render: rowData => rowData.license_id ? rowData.license_type : 'No License',
        editComponent: props => {
          let licensesFormat = licensesByGroup.map((license) => ({ 'value': license.value, 'label': license.label }));

          if (props.rowData.license_id) {
            licensesFormat = licensesFormat.filter((license) => license.value !== props.rowData.license_id);
          }

          if (props.value) {
            licensesFormat.push({ value: props.value, label: props.rowData.license_type });
          }

          let selectedLicense = licensesFormat.find(license => license.value === props.value);

          if (selectedLicense) {
            licensesFormat = licensesFormat.filter(license => license.value !== selectedLicense.value);
          }

          return (
            <Select
              options={licensesFormat}
              label={'Licenses'}
              id={'licenses'}
              defaultValue={selectedLicense}
              onChange={e => {
                if (e) return props.onChange(e.value)
              }}
            />
          );
        }
      },
      {
        title: 'Reset Password',
        render: rowData => {
          return (
            <>
              <Button variant="outlined" onClick={() => handlePasswordRequest(rowData.email)}>
                Request New Password
              </Button>
              <Collapse in={passwordReset.includes(rowData.email)}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setPasswordReset((emailList) => emailList.filter(email => email !== rowData.email));
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Password requested
                </Alert>
              </Collapse>
            </>
          );
        }
      },
      {
        title: 'Last Login',
        render: rowData => {

          const found = authData.find(authUser => authUser.email === rowData.email);
          return (<>{found ? found.last_login ? found.last_login : 'Never' : 'Never'}</>);
        }
      },

      {
        title: 'Login Count',
        render: rowData => {
          const found = authData.find(authUser => authUser.email === rowData.email);
          return (<>{found ? found.logins_count ? found.logins_count : 0 : 0}</>);
        }
      },
      { title: 'Created At', field: 'created_at', sorting: true, defaultSort: 'desc', editable: 'never', type: 'datetime', detaSetting: { 'locale': 'en-US' } }
    ];
  }

  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;

  const getAuthToken = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/rpc/auth0-token`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(res => {
        setAuthToken(res.data[0]);
      });
  }

  const addUserAuth = (id, newData) => {
    const token = authToken;

    axios.get(`${process.env.REACT_APP_API_URL}/rpc/create_user?_id=` + id, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(res => {
        let userData = res.data[0];

        userData['password'] = randomTempPassword(18);
        userData['connection'] = 'Username-Password-Authentication';
        userData['verify_email'] = false;

        axios.post('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/api/v2/users', userData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(res => {
            delete newData.license_type;
            newData['user_id'] = res.data.user_id;
            newData['id'] = id;
            newData['group_id'] = parseInt(newData['group_id']);
            newData['is_created'] = true;

            axios.put(`${process.env.REACT_APP_API_URL}/users?id=eq.${id}`, newData,{
              headers: {
                'ngrok-skip-browser-warning': true
              }
            })
              .then(response => {
                let resetPassword = {
                  "client_id": process.env.REACT_APP_AUTH0_CLIENT_ID,
                  "email": newData.email,
                  "connection": "Username-Password-Authentication"
                };

                axios.post('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/dbconnections/change_password', resetPassword,
                  {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  })
                  .then(res => {
                    getUsers();
                  });
                setIserror(false);
                setErrorMessages([]);
              })
              .catch(error => {
                if (error.response) {
                  // Request made and server responded
                  setErrorMessages([`Update failed! ${error.response.data.details}`]);
                }

                setIserror(true);
              });
          });
      })
  }

  const getUsers = useCallback(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/users?group_id=eq.${groupID}`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then((res) => {
        let userWithLicenseType = (res.data).map(function (obj) {
          if (obj.license_id) {
            return axios.get(`${process.env.REACT_APP_API_URL}/licenses?uuid=eq.${obj.license_id}`,
              {
                headers: {
                  'ngrok-skip-browser-warning': true  
                }
              })
              .then(function (results) {
                return obj = { ...obj, 'license_type': results.data[0].type }

              })
          } else {
            return obj = { ...obj, 'license_type': null }
          }
        })
        Promise.all(userWithLicenseType).then(function (results) {
          setUsersByGroup(results)
        })
      })
  }, [groupID]);

  const getLicenses = (id) => {
    simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/pending_licenses?_groupid=${id}`)
      .then(response => {
        response.push({ value: 'No License', label: 'No License' });
        setLicensesByGroup(response)
      })

  }

  useEffect(() => {
    getAuthToken();
    getUsers();
    if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_super_admin') {
      axios.get(`${process.env.REACT_APP_API_URL}/groups`, {
         headers: {
          'ngrok-skip-browser-warning': true
        }
      })
        .then(res => {
          const groups = res.data;
          setGroups(groups);
        })
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}/users?email=eq.${user.email}`, {
        headers: {
          'ngrok-skip-browser-warning': true
        }
      })
        .then(res => {
          let userGroupId = res.data[0].group_id;
          axios.get(`${process.env.REACT_APP_API_URL}/groups?id=eq.${userGroupId}`, {
            headers: {
              'ngrok-skip-browser-warning': true
            }
          })
            .then(res => {
              const groups = res.data;
              setGroups(groups);
            })
        })
    }
  }, [user, getUsers]);

  useEffect(() => {
    if (groups.length > 0) {
      axios.get(`${process.env.REACT_APP_API_URL}/users?group_id=eq.${groupID}`, {
        headers: {
        'ngrok-skip-browser-warning': true
        }
      })
        .then((res) => {
          let userWithLicenseType = (res.data).map(function (obj) {
            if (obj.license_id) {
              return axios.get(`${process.env.REACT_APP_API_URL}/licenses?uuid=eq.${obj.license_id}`, {
                headers: {
                  'ngrok-skip-browser-warning': true
                }
              })
                .then(function (results) {
                  return obj = { ...obj, 'license_type': results.data[0].type }

                })
            } else {
              return obj = { ...obj, 'license_type': null }
            }
          })
          Promise.all(userWithLicenseType).then(function (results) {
            setUsersByGroup(results)
          })
        })
      getLicenses(groupID);
    }
    let buttons = document.querySelectorAll('.groups-buttons__item');
    buttons.forEach((button, key) => {
      if (key === 0) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    });
  }, [groups, groupID])


  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve) => {
    delete newData.license_type;
    //validating the data inputs
    let errorList = []
    if (newData.email === "") {
      errorList.push("Oops!!! Please enter a valid email")
    }

    if (newData.license_id === 'No License') {
      newData.license_id = null;
    }

    if (errorList.length < 1) {
      axios.put(`${process.env.REACT_APP_API_URL}/users?id=eq.${newData.id}`, newData, {
        headers: {
        'ngrok-skip-browser-warning': true
        }
      })
        .then(response => {
          newData['connection'] = 'Username-Password-Authentication';

          axios.get(`${process.env.REACT_APP_API_URL}/rpc/create_user?_id=` + newData.id,  {
            headers: {
            'ngrok-skip-browser-warning': true
            }
          })
            .then(res => {
              let userData = res.data[0]['app_metadata'];
              userData = { 'app_metadata': userData }

              axios.patch('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/api/v2/users/' + newData['user_id'], userData,
                {
                  headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                  }
                });
            });

          getLicenses(groupID);
          getUsers();
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Update failed! ${error.response.data.details}`]);
          }

          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  }

  //function for deleting a row
  const handleRowDelete = (oldData, resolve) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/users?id=eq.${oldData.id}`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(response => {
        axios.delete('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/api/v2/users/' + oldData['user_id'],
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          });
        getLicenses(groupID);
        getUsers();
        resolve();
      })
      .catch(error => {
        if (error.response) {
          // Request made and server responded
          setErrorMessages([`Delete failed! ${error.response.data.details}`]);
        }

        setIserror(true);
        resolve();
      })
  }

  //function for adding a new row to the table
  const handleRowAdd = (newData, resolve) => {
    //validating the data inputs
    let errorList = []

    if (newData.email === "") {
      errorList.push("Oops!!! Please enter a valid email")
    }

    if (errorList.length < 1) {
      if (newData.license_id === 'No License') {
        newData.license_id = null;
      }

      if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_group_admin') {
        newData.group_id = groupID;
        newData.role = 'api_user';
      }

      axios.post(`${process.env.REACT_APP_API_URL}/users`, newData,
        {
          headers: {
            'ngrok-skip-browser-warning': true,
            'Prefer': 'return=headers-only'
          }
        })
        .then(response => {
          let raw_id = response.headers['location'];
          let new_id = raw_id.replace('/users?id=eq.', '');

          addUserAuth(new_id, newData);
          getLicenses(groupID);
          getUsers();
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Cannot add data. ${error.response.data.details}`]);
          }

          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  }

  return (
    <>
      <div className="table-wrapper" style={{ padding: 20 }}>
        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
        <MaterialTable
          title="Users"
          columns={columns}
          data={
            ["api_super_admin", "api_group_admin"].includes(userRole) ? usersByGroup : null
          }
          components={{
            Toolbar: props => (
              <MTableToolbar {...props} classes={{ root: "board-toolbar" }} />
            ),
          }}
          icons={{
            Add: props => <div className="Table-button"><AddIcon {...props} /> Add user</div>,
          }}
          options={{
            headerStyle: { borderBottomColor: 'rgb(102, 16, 242)', borderBottomWidth: '3px' },
            actionsColumnIndex: 0,
            toolbarButtonAlignment: "left",
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                handleRowUpdate(newData, oldData, resolve);
              }),
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                handleRowAdd(newData, resolve)
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                handleRowDelete(oldData, resolve)
              }),
          }}
        />
      </div>
    </>
  );
}

export default UsersByAccount;
