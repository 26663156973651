import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import logoSignature from "../../IB_Signature.png";

const TornadoChart = ({ data, title, subtitle = null }) => {
  var categories = data.categories;
  const [showRawDataTable, setShowRawDataTable] = useState(false);

  let logoCredit;
  const tornadoData = {
    chart: {
      type: "bar",
      height: 550,
      style: {
        fontFamily: ['Titillium Web', 'sans-serif']
      },
      margin: [100, 100, 150, 150],
      events: {
        exportData: function(data) {
          (data.dataRows).forEach( (row, parentKey) => {
            row.forEach( (val, key) => {
              if(typeof val === 'number' && val < 0) {
                return data.dataRows[parentKey][key] = val * -1;
              }
            });
          });
        },
        load: function () {
          if (this.options.chart.forExport) {
            this.renderer.image(`${logoSignature}`, 10, 520, 160, 25).add();
          }
        },
        beforePrint: function () {
          logoCredit = this.renderer
            .image(`${logoSignature}`, 10, 520, 160, 25)
            .add();
          this.print();
        },
        afterPrint: function () {
          logoCredit.element.remove();
        },
      },
    },
    title: {
      text: '<b>' + title + '</b>',
      style: { "fontSize": "15px" }
    },
    subtitle: {
      text: subtitle,
      style: {
        fontSize: '14px',
      },
    },
    xAxis: [
      {
        categories: categories,
        reversed: true,
        labels: {
          style: {
            fontWeight: 'bold'
          }
        }
      },
    ],
    yAxis: {
      max: 100,
      min: -100,
      labels: {
        formatter: function () {
          return Math.abs(this.value) + "%";
        },
      },
    },

    plotOptions: {
      series: {
        stacking: "normal",
      },
    },

    exporting: {
      tableCaption: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: PDF in ${title}`,
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: CSV in ${title}`,
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: XLS in ${title}`,
                ]);
                this.downloadXLS();
              },
            },
            {
              separator: true,
            },
            {
              text: "Raw Data Table",
              onclick: function() {
                                                
                if(showRawDataTable)
                {
                  setShowRawDataTable(!showRawDataTable);
                  this.hideData();
                  
                } else {
                  setShowRawDataTable(!showRawDataTable);
                  this.viewData()
                }

                window._paq.push([
                  "trackEvent",
                  "Board District Insight",
                  `Opened the Raw Data Table`,
                ]);
              }          
            }

          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      }
    },

    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.point.category +
          "</b><br/>" +
          "Percentage of Total: " +
          Highcharts.numberFormat(Math.abs(this.point.y), 1) +
          "%"
        );
      },
      backgroundColor: "#FFF",
    },

    series: [
      data.students,
      data.teachers,
    ],

    colors: [
      '#9056D6',
      '#FFCC4E',
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={tornadoData} />;
};

export default TornadoChart;
