import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { localHour } from "../../../utils/helpsers";
import LayoutPage from "../../../layout/LayoutPage";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import WorkIcon from "@mui/icons-material/Work";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FolderIcon from "@mui/icons-material/Folder";
import ShareIcon from "@mui/icons-material/Share";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Typography from "@mui/material/Typography";
import { ReportButtons } from "../../../components/BugReport/ReportButtons";
import AsyncWrapper from "../../../components/AsyncWrapper/AsyncWrapper";

// styles
import styles from './profile.module.scss'

/* import Badge from "@mui/material/Badge";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LoginIcon from "@mui/icons-material/Login";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"; */

//Badge to change profile picture
/* const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    boxShadow: `0 0 0 2px gray`,
    backgroundColor: "lightgray",
    cursor: "pointer",
  },
}));
 */

function Profile() {
  //Use Auth0 to get user info
  const { user } = useAuth0();
  const enabledShare =
    user[`${process.env.REACT_APP_AUTH0_KEY}/district`].enable_share;

  //Use React Hooks to manage state
  const [userInfo, setUserInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState("");

  // const [openPic, setOpenPic] = useState(false);
  // const [picture, setPicture] = useState(null);

  //Upload Photo

  /* const handlePictureOpen = () => {
    setOpenPic(true);
  };

  const handlePicClose = (e) => {
    setOpenPic(false);
    e.stopPropagation();
  };

  const onFileChange = (e) => {
    setPicture(e.target.files[0]);
  };

  const onFileUpload = () => {
    if (picture) {
      const formData = new FormData();
      formData.append("picture", picture, picture.name);
      axios
        .post("/api/users/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
        });
      setOpenPic(false);
      return alert("File Uploaded");
    }
    return alert("Please select a file");
  };
 */

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users?email=eq.${user.email}`, {
        headers: {
          'ngrok-skip-browser-warning': true  
        }})
      .then((res) => {
        let userGroupId = res.data[0].group_id;
        axios
          .get(`${process.env.REACT_APP_API_URL}/groups?id=eq.${userGroupId}`, {
            headers: {
              'ngrok-skip-browser-warning': true  
            }})
          .then((res) => {
            const groups = res.data;
            setGroup(groups[0].name);
          });
      });
  });

  const handlePasswordRequest = () => {
    let resetPassword = {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: user.email,
      connection: "Username-Password-Authentication",
    };

    axios.post(
      "https://" +
        process.env.REACT_APP_AUTH0_DOMAIN +
        "/dbconnections/change_password",
      resetPassword,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setOpen(true);
  };

  const roleUser = user["https://data.insightboard.org/role"];
  const role = roleUser
    .replace(/api_/g, "")
    .replace(/_/g, " ")
    .replace(/group/g, "account");

  return (
    <LayoutPage
      pageTitle="Profile"
      description="Profile Page with user info"
      type="basic"
      customClass={`${styles.userProfile}`}
    >
      <div className={'layout-wrapper'}>
        <Grid container spacing={2} className={'user-info__auth'}>
          <Grid item xs={12} sm={3.5} lg={1.5}>
            <div
              className={styles.profilePhoto}
            >
              {/* <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={<CameraAltIcon />}
                //onClick={handlePictureOpen}
              >

                <Avatar
                  alt={user.nickname}
                  src={user.picture}
                  sx={{ width: 222, height: 222 }}
                />
              </StyledBadge> 
              */}
              <Avatar
                alt={user.nickname}
                src={user.picture}
                sx={{ width: 145, height: 145 }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className={styles.profileName}>
              <div className={styles.userName}>{user.nickname}</div>
              {/* <h3 style={{ marginBottom: "0" }}>{user.email}</h3> */}
              <Typography variant="subtitle1">Email: {user.email}</Typography>
              <Typography variant="subtitle1"> Role: {role} </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "15px" }}>
                Account: {group}
              </Typography>
              <Button
                variant="outlined"
                onClick={handlePasswordRequest}
                className={styles.requestPass}
              >
                Request New Password
              </Button>
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  New password requested successfully
                </Alert>
              </Collapse>
            </div>
          </Grid>

          {
            user.email &&
            <AsyncWrapper
                label={`Loading ${user.nickname} user data...`}
                endPoint={`${process.env.REACT_APP_API_URL}/rpc/hvd_profile_info?_email=${user.email}`}
                dataKey={0}
                dataType={'object'}
                callback={setUserInfo}>

              <Grid container spacing={2} className={'user-info__insight-board'}>
                <Grid item xs={12} sm={6}>
                  <List>
                    {/* <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LoginIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Last login" secondary="Jan 9, 2014" />
              </ListItem> */}
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                          primary="License type"
                          secondary={window.localStorage.getItem("session-type")}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DateRangeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                          primary="Expiration date:"
                          secondary={
                            userInfo.group_admin && localHour(userInfo.expiration_date)
                          }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                          primary="Districts Access: "
                          secondary={userInfo.district_count_access + " districts"}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List>
                    {roleUser === "api_user" && (
                        <>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <SupportAgentIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Request Help"
                                secondary={
                                  userInfo.group_admin ? (
                                      <div>
                                        Contact Your Account Administrator:{" "}
                                        {userInfo.group_admin &&
                                        userInfo.group_admin.map((admin, key) => {
                                          return (
                                              <a href={`mailto:${admin.email}`}>
                                    <span
                                        className={styles.groupAdmin}
                                        key={key}
                                    >
                                      {admin.email}
                                    </span>
                                              </a>
                                          );
                                        })}
                                      </div>
                                  ) : (
                                      <div>
                            <span>
                              Contact InsightBoard:{" "}
                              <a href="mailto:help@insightboard.org">
                                help@insightboard.org
                              </a>
                            </span>
                                      </div>
                                  )
                                }
                            />
                          </ListItem>
                        </>
                    )}
                    {roleUser !== "api_user" && (
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <SupportAgentIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                              primary="Request Help"
                              secondary={
                                <>
                        <span>
                          Contact InsightBoard:{" "}
                          <a href="mailto:help@insightboard.org">
                            help@insightboard.org
                          </a>
                        </span>
                                </>
                              }
                          />
                        </ListItem>
                    )}

                    {enabledShare && (
                        <>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <TurnedInIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                  <>
                                    Saved Boards
                                  </>
                                }
                                secondary={
                                  <>
                                    {userInfo.saved_boards &&
                                    userInfo.saved_boards.map((board, key) => {
                                      return (
                                          <span
                                              className={styles.savedBoards}
                                              key={key}
                                          >
                                    {board.type} | {board.total}
                                  </span>
                                      );
                                    })}
                                    {!userInfo.saved_boards && (
                                        <span>No saved boards</span>
                                    )}
                                  </>
                                }
                            />
                          </ListItem>
                        </>
                    )}
                    <ListItem>
                      {enabledShare && (
                          <>
                            <ListItemAvatar>
                              <Avatar>
                                <ShareIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                  <>
                                    Shared Boards
                                  </>
                                }
                                secondary={
                                  <>
                                    {userInfo.shared_boards &&
                                    userInfo.shared_boards.map((board, key) => {
                                      return (
                                          <span
                                              className={styles.sharedBoards}
                                              key={key}
                                          >
                                    {board.type} | {board.total}
                                  </span>
                                      );
                                    })}
                                    {!userInfo.shared_boards && (
                                        <span>No shared boards</span>
                                    )}
                                  </>
                                }
                            />
                          </>
                      )}
                    </ListItem>
                  </List>
                </Grid>
              </Grid>

            </AsyncWrapper>
          }
        </Grid>
        <ReportButtons boardType={"Profile Page"} collectedData="" />
      </div>
    </LayoutPage>
  );
}

export default Profile;
