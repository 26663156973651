import React from 'react';
import PropTypes from 'prop-types';
import Header from "../components/Header/Header";
import SideBarNav from "../components/Navigation/MainNav";
import Footer from "../components/Footer/Footer";

/**
 * Return layout structure for Crocus pages
 * @param {string} pageTitle: The title of the web page.
 * @param {string} description: Informative description of the web page.
 * @param {string} type: The web page type to be rendered.
 *  - basic: The default page with the base styles/structure Crocus page.
 *  - dashboard: Page with custom styles/structure for data dashboards.
 * @param {string} customClass: Classes for specific style customization.
 * @param {element} children: Elements to build the page ui.
 */
function LayoutPage({ pageTitle, description, type = 'basic', customClass, children }) {
  return (
    <div className={`${type}-page ${customClass}`}>
      <Header pageTitle={pageTitle} />
      <main className={`main-content`}>
        {
          children
        }
      </main>
      <SideBarNav />
      <Footer />
    </div>
  )
}

LayoutPage.propTypes = {
  pageTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
  customClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired
}

export default LayoutPage;
