import axios from "axios";

export const simpleFetch = async (endpoint) => axios.get(endpoint,
  {
    headers: {
      'ngrok-skip-browser-warning': true
    }
  })
  .then(response => response.data).catch(error => alert(error.response.data.details));

export const fetchById = async (endPoint, id) => axios.get(`${endPoint}${id}`,
  {
    headers: {
      'ngrok-skip-browser-warning': true
    }
  }
)
    .then(response => response.data).catch(error => alert(error.response.data.details));

export const clearHooks = (...hooks) => {
  hooks.forEach(hook => hook(null));
}

export const getDistrictInfo = (...args) => {
  fetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_resume?_irn=${args[0]}&_year=${args[1]}`, { headers: { 'ngrok-skip-browser-warning': true, 'Authorization': 'Bearer ' + args[4] } })
    .then(response => response.json())
    .then(data => {
      if (args[0] && args[1] && args[2] && args[3]) {
        args[2] && args[2](data[0]);
        args[3] && args[3](data[0]["group_name"]);
      }
    }).catch(error => alert(error));
};


export const getGroupData = (endpoint, groupId, hook, token) => {
  fetchById(endpoint, groupId, token)
    .then(data => {
      if (groupId === "Enrollment+Brackets") {
        hook(data.map((elem, i) => {
          let splitLabel = elem.label.split(')'),
            newLabel = i === 0 ? elem.label = 'Districts' + splitLabel[1].replace('U', 'u') : elem.label = 'Districts with' + splitLabel[1];
          return {
            label: newLabel,
            value: elem.value
          }
        })
        );
      } else {
        return hook(data);
      }
    });
}

export const currencyFormat = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(number)
}

export const buildTooltip = ({ infoIcon, info }) => {
  infoIcon.onmouseover = () => {
    let label = document.getElementById('label-tooltip');
    if (!label) {
      let top = infoIcon.getBoundingClientRect().top + 28 + window.pageYOffset + 'px';
      let left = infoIcon.getBoundingClientRect().left + -10 + window.pageXOffset + 'px';
      let tooltip = document.createElement('div');
      tooltip.id = 'label-tooltip';
      tooltip.innerHTML = info;
      tooltip.style.cssText = `
                left:${left}; 
                top:${top};`
      document.body.appendChild(tooltip);
    }
  }

  infoIcon.onmouseleave = () => {
    let label = document.getElementById('label-tooltip');
    if (label) {
      label.remove();
    }
  }
}

export const getToken = () => {
  return window.localStorage.getItem('token');
}

export const setToken = (token, exp) => {
  window.localStorage.setItem('exp', exp);
  window.localStorage.setItem('token', token);
}

export const localHour = (datetime) => {
  const now = new Date(datetime);
  const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  const dateLocal = new Date(now.getTime() - offsetMs);
  return dateLocal.toISOString().slice(0, 16).replace("T", " ");
}

export const normalizeDistributionData = ({ varName, varIrn, irns, hook, year }, token) => {
  let distributionData = {};
  simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_footer?_variable=${varName}&_irn=${varIrn}&_irns=${irns}&_year=${year}`, token)
    .then(data => {
      data.forEach(item => {
        distributionData[item.quantity] = item.value && item.value !== 0 ? (item.value).toFixed(2) : item.value
      })
    }).then(() => {
      hook(distributionData)
    });
}

export const groupTypes = [
  { label: 'County', value: 'County' },
  { label: 'Similarity', value: 'Similarity' },
  { label: 'District Picker', value: 'District+Picker' },
  { label: 'Enrollment bracket', value: 'Enrollment+Brackets' },
  { label: 'Custom Groups', value: 'custom_groups' },
  { label: 'All Ohio School Districts', value: 'All' },
];

// Converts a string to TitleCase
export const toTitleCase = (str) => str.replace(/\w\S*/g, txt =>
  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
);

// Formatted item value based on var type.
export const valueFormatted = (item, var_type) => {
  let nf = new Intl.NumberFormat('en-US');
  switch (var_type) {
    case '$':
      return `${currencyFormat(item)}`
    case '%':
      return `${(item).toFixed(2)}${var_type}`
    default:
      return nf.format(item);
  }
}

// Money Formatted
export const moneyFormat = ({val, base, tag}) => {
    let num = val.toString();
    if(val <= ((base * 10) - 1)) return `$${num.slice(0, 1)}.${num.slice(1, 3)}${tag}`;
    if(val <= ((base * 100) - 1)) return `$${num.slice(0, 2)}.${num.slice(2, 3)}${tag}`;
    if(val <= ((base * 1000) - 1)) return `$${num.slice(0, 3)}.${num.slice(3, 4)}${tag}`;
}

// Years data.
export const yearList = [
    { "value": 2021, "label": '2021' },
    { "value": 2020, "label": '2020' },
    { "value": 2019, "label": '2019' },
    { "value": 2018, "label": '2018' },
    { "value": 2017, "label": '2017' },
    { "value": 2016, "label": '2016' },
    { "value": 2015, "label": '2015' },
    { "value": 2014, "label": '2014' },
    { "value": 2013, "label": '2013' },
    { "value": 2012, "label": '2012' },
    { "value": 2011, "label": '2011' },
    { "value": 2010, "label": '2010' },
    { "value": 2009, "label": '2009' },
    { "value": 2008, "label": '2008' },
    { "value": 2007, "label": '2007' },
    { "value": 2006, "label": '2006' }
];

export const buildOnLoad = ({user, callback, token}) => {
    let conditionalDistrict;
    let userEmail = user.email;

    if (localStorage.getItem('fixedSelection')) {
        let fixedSelection = JSON.parse(localStorage.fixedSelection);
        callback({
            referenceDistrict: fixedSelection?.referenceDistrict,
            irns: fixedSelection?.irns,
            groupType: fixedSelection?.groupType,
            groupTypeOptions: null,
            groupCount: fixedSelection?.groupCount,
            groupInfo: fixedSelection?.groupInfo,
            year: fixedSelection.year || {value:'2019', label: '2019'},
        })
    } else {
        simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_allowed_districts?_email=${userEmail}`)
            .then(data => {
                if(user[`${process.env.REACT_APP_AUTH0_KEY}/district`].sessionType === 'district-access') {
                    localStorage.setItem('allowed_districts', JSON.stringify(data[0]));
                    conditionalDistrict = [data[0]];
                } else {
                    localStorage.setItem('allowed_districts', JSON.stringify(data[0].sort(function(a, b) {
                        return a.label.localeCompare(b.label);
                    })));
                    conditionalDistrict = data[0];
                }
            }).then(() => {
            // All Ohio as default district.
            let urlGroupSelected = `${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&label=eq.`;
            let groupId = "All Ohio's School Districts";
            fetchById(urlGroupSelected, groupId, token)
                .then(data => {
                    callback({
                        referenceDistrict: conditionalDistrict[0],
                        irns: data[0].irns,
                        groupType: { label: 'All Ohio School Districts', value: 'All' },
                        groupTypeOptions: null,
                        groupCount: data[0].count,
                        groupInfo: {label: 'All Ohio School Districts', value: 1},
                        year: {value:'2019', label: '2019'},
                    })
                });
        })
    }
}

export const allowedDomain = (rowData) => {
  const regex =
    /^\s*[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,3})+$/gm;
  const domain = rowData.allowed_domains
    ? rowData.allowed_domains.replace(" ", "").split(",")
    : [];
    if (domain.length === 0) {
      return false
    }
  return domain.every((d) => d.match(regex));
};
