import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import LineChart from "../LineChart/LineChart";
import chartIcon from '../../line-chart.png';
import Collapse from '@mui/material/Collapse';
import { simpleFetch, getToken, valueFormatted } from "../../utils/helpsers";
import './comparisonCard.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

/**
 * Renders a Card component with data related with the variable set static o dynamically.
 *
 * @param {object} defaultDomainVar: Default key/value variable data.
 * @param {int} year: The year.
 * @param {string} title: The string to fill the card  title.
 * @param {object} comparisonData: Object with data to be map and displayed on the card.
 * @param {object} referenceDistrict: Data from the reference district selected.
 * @param {object} irns: object array with district group irn.
 * @param {string} groupName: Name of the districts group selected.
 * @param {string} groupType: Name of the group type selected.
 * @param {object} domainOptions: Data of every options to fill select field. Required keys:
 * @param {function} storedCardInfo: Returns the variable data set in the card on load or select change. Used to save data card info inside comparisonCardsInfo.
 *                 - value
 *                 - label
 * @returns {JSX.Element}
 */

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0)",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: "11px",
  },
}));

const ComparisonCard = ({ defaultDomainVar = null, year, title, comparisonData, referenceDistrict, irns, groupName, groupType, domainOptions = null, storedCardInfo }) => {
  const [varData, setVarData] = useState(defaultDomainVar);
  const [cardData, setCardData] = useState(comparisonData);
  const [showTimeline, setShowTimeline] = useState(false);
  const [showRankingList, setShowRankingList] = useState(false);
  // Data to feed line.
  const [lineData, setLineData] = useState();

  useEffect(() => {
    storedCardInfo(varData || domainOptions[0]);
  }, [varData, domainOptions, storedCardInfo])

  useEffect(() => {
    if (varData) {
      if (varData.value && referenceDistrict.value && irns && year) {
        simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_dynamic_card?_var=${varData.value}&_irn=${referenceDistrict.value}&_irns=${irns}&_year=${year}`, getToken())
          .then(data => {
            setCardData(data[0]);
          })
      }
      if (referenceDistrict.value && irns && groupName && varData.value) {
        simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_historic_variable?_irn=${referenceDistrict.value}&_irns=${irns}&_group_name=${groupName}&_variable=${varData.value}`, getToken())
          .then(data => {
            let chartData = data.map(item => {
              if (referenceDistrict.value === item.irn) return { ...item, color: '#F5B335' }
              return { ...item, color: '#773DBD' }
            })
            // Order years and data
            let orderVar = {};
            orderVar[0] = {};
            orderVar[1] = {};

            chartData[0].data.forEach((element, index) => {
              orderVar[0][chartData[0].years[index]] = element;
            });

            chartData[1].data.forEach((element, index) => {
              orderVar[1][chartData[1].years[index]] = element;
            });

            let a = Object.keys(orderVar[0]);
            let b = Object.keys(orderVar[1]);

            let unique_years = Array.from(new Set([...a, ...b].sort())).sort();
            let setA = [];
            let setB = [];

            unique_years.forEach(element => {
              setA.push(orderVar[0][element] ? orderVar[0][element]: null);
              setB.push(orderVar[1][element] ? orderVar[1][element]: null);
            });

            chartData[0].data = setA;
            chartData[0].years = unique_years;
            chartData[1].data = setB;
            chartData[1].years = unique_years;

            setLineData(chartData);
          })
      }
    } else if (domainOptions && !varData) {
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_dynamic_card?_var=${domainOptions[0].value}&_irn=${referenceDistrict.value}&_irns=${irns}&_year=${year}`, getToken())
        .then(data => {
          setCardData(data[0]);
        });
      simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_historic_variable?_irn=${referenceDistrict.value}&_irns=${irns}&_group_name=${groupName}&_variable=${domainOptions[0].value}`, getToken()).then(data => {
        setLineData(data);
      })
      storedCardInfo(domainOptions[0]);
    }
  }, [year, groupName, varData, irns, referenceDistrict.value, domainOptions, storedCardInfo]);

  let average = () => {
    let value = Math.sign(cardData.average_position);
    let flag = '';
    switch (cardData.higher_number) {
      case 'Green':
        if (value === -1) {
          flag = "negative";
        } else {
          flag = "positive";
        }
        break;
      case 'Red':
        if (value === -1) {
          flag = "positive";
        } else {
          flag = "negative";
        }
        break;
      default:
        flag = '';
        break;
    }

    return flag;
  }

  let sentenceRender = () => {
    let sentence = cardData.sentence;

    if (["Percentage Change in Enrollment Over 10 Years", "Percentage Change in Enrollment Over 5 Years"].some(el => cardData.kpi.includes(el))) {
      let sentenceOptions = sentence.split("#");
      if (cardData.value > 0 && cardData.group_average > 0) {
        sentence = sentenceOptions[1];
        sentence = sentence.slice(2).trim();
      } else if (cardData.value < 0 && cardData.group_average < 0) {
        sentence = sentenceOptions[2];
        sentence = sentence.slice(2).trim();
      } else {
        sentence = sentenceOptions[3];
        sentence = sentence.slice(2).trim();
      }
    }

    let district_short_name = referenceDistrict.label.split(',')[0];

    sentence = sentence.replace("(district_short_name)", district_short_name);
    sentence = sentence.replace("(district_name)", district_short_name);
    sentence = sentence.replace("(district)", district_short_name);
    sentence = sentence.replace("(comparison_group_name)", groupName);
    sentence = sentence.replace("(human name)", cardData.kpi);

    if (cardData.average_position) {
      sentence = sentence.replace("---", "<span class=\"average-value " + average() + "\">" + Math.abs(cardData.average_position.toFixed(2)) + "</span>");

      if (cardData.average_position.toFixed(2) > 0) {
        sentence = sentence.replace("higher/lower", "higher");
        sentence = sentence.replace("faster/slower", "faster");
        sentence = sentence.replace("slower/faster", "slower");
        sentence = sentence.replace("grown/declined", "grown");
        sentence = sentence.replace("more/less", "more");
        sentence = sentence.replace("greater/less", "greater");
      } else {
        sentence = sentence.replace("higher/lower", "lower");
        sentence = sentence.replace("faster/slower", "slower");
        sentence = sentence.replace("slower/faster", "faster");
        sentence = sentence.replace("grown/declined", "declined");
        sentence = sentence.replace("more/less", "less");
        sentence = sentence.replace("greater/less", "less");
      }
    }

    sentence = sentence.replace("higher/lower", "");
    sentence = sentence.replace("faster/slower", "");
    sentence = sentence.replace("grown/declined", "");
    sentence = sentence.replace("more/less", "");
    sentence = sentence.replace("is ---", "is equal");

    if (groupType === 'District+Picker') {
      sentence = sentence.replace(" group average.", ".");
    }

    return sentence;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport printOptions={{
          pageStyle: '.MuiDataGrid-toolbarContainer{display:none!important}.MuiDataGrid-root div[data-field="district"],.MuiDataGrid-root div[data-field="value"]{width:300px!important;min-width:300px!important;max-width:300px;}',
        }}/>
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'rown',
      headerName: '#',
      width: 65,
      editable: false,
    },
    {
      field: 'irn',
      headerName: 'IRN',
      width: 65,
      editable: false,
    },
    {
      field: 'district',
      headerName: 'District',
      flex: 1,
      editable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: .5,
      editable: false,
    },
  ];

  return (
    <div className={`comparison-card ${domainOptions ? 'dynamic-card' : 'static-card'}`}>
      <h2 className="comparison-card__title">{title}</h2>
      {
        cardData &&
        <Button onClick={() => {
          window._paq.push([
            "trackEvent",
            "Board InsightBoard",
            `Clicked: ComparisonCard ${title} - order in groups`,
          ]);
          setShowRankingList(!showRankingList)
        }} className={`ranking-group-collapse`}>
          <KeyboardArrowDownIcon className={`comparison-card__toggle-icon ${showRankingList ? 'hide' : 'show'}`} sx={{ color: "black" }} />
          <span>Order in group: </span>
          <span className="value">{cardData.rank_in_group}</span>
        </Button>
      }
      <div className="comparison-card__subtitle">
        {
          cardData && cardData.definition &&
          <TooltipStyled title={cardData.definition} aria-label={cardData.definition}>
            <span className="help-tooltip">ⓘ</span>
          </TooltipStyled>
        }
        {
          domainOptions
            ? <div className="comparison-card__form-select">
              <Autocomplete
                defaultValue={defaultDomainVar || domainOptions[0]}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={domainOptions}
                fullWidth={true}
                onChange={(event, newValue) => {
                  // Matomo code.
                  if (newValue) {
                    window._paq.push([
                      "trackEvent",
                      "Board InsightBoard",
                      `Clicked: ComparisonCard ${title}`,
                      `Selected: ${newValue.label}`,
                    ]);
                  }
                  setVarData(newValue);
                  storedCardInfo(newValue);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="" />
                }
              />
            </div>
            : cardData.kpi
        }
      </div>
      {
        cardData &&
        <div className="comparison-card__data">
          <div className={`comparison-card__average`}>
            {
              cardData.value && cardData.group_average
                ? <div dangerouslySetInnerHTML={{ __html: sentenceRender() }} />
                : <div>Data unavailable for the selected year. This may indicate the district does not have {cardData.kpi}.</div>
            }
          </div>
          <div className="comparison-card__amount">
            <span className="label">{referenceDistrict.label}</span>
            <span className="value">{cardData.value || "Data unavailable"}</span>
          </div>
          <div className="comparison-card__group-amount">
            <span className="label">{groupName}</span>
            <span className="value">{cardData.group_average || "Data unavailable"}</span>
          </div>
          <Collapse in={showRankingList}>
            <div style={{
              height: (
                (Object.keys(cardData.list).length < 50 ? Object.keys(cardData.list).length : 50) * 70 + 168), width: '100%'
            }}
              className={'comparison-card__ranking-table'}>
              <DataGridPro
                rows={(cardData.list).map((item, key) => {
                  return {
                    district: item.district,
                    rown: item.rown,
                    value: valueFormatted((+item.value), cardData.var_type),
                    id: key,
                    irn: item.irn,
                  }
                })}
                rowHeight={70}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                components={{
                  Toolbar: CustomToolbar,
                }}
                disableColumnMenu
                getRowClassName={
                  (params) => params.row.irn === referenceDistrict.value && 'row--highlighted'
                }
              />
            </div>
          </Collapse>
          {
            (cardData.value && cardData.group_average) &&
            <div className="comparison-card__timeline ">
              <Button onClick={() => setShowTimeline(!showTimeline)} >
                {showTimeline ? 'Hide' : 'Show'} History
                <img src={chartIcon} alt="line-chart-icon" className="chart-icon" />
              </Button>
              <Collapse in={showTimeline}>
                <div className="timeline__chart">
                  {lineData &&
                    <LineChart data={lineData} />
                  }
                </div>
              </Collapse>
            </div>
          }
        </div>
      }
    </div>
  )
};

export default ComparisonCard
