import React, { useEffect, useState, useCallback } from 'react';
import LayoutPage from "../../layout/LayoutPage";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Select from '../../components/Select/Select';
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import { useAuth0 } from "@auth0/auth0-react";

const CrudCustomBoards = () => {
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [activeUser, setActiveUser] = useState([]);

  const [activeTab, setActiveTab] = useState('my_group');
  const tabs = [
    { 'name': 'My Groups', 'value': 'my_group' },
    { 'name': 'Shared with me', 'value': 'shared_with' }
  ];

  const [users, setUsers] = useState([]);

  const { user } = useAuth0();

  const [columns, setColumns] = useState([]);

  const defineColumns = useCallback((tab) => {
    if (tab === 'shared_with') {
      return [
        {
          title: 'Name', field: 'label', editable: 'never',
          render: props => {
            return (
              <a
                href={'/custom-groups?shared=' + props.uuid}
                target="_blank"
                rel="noopener noreferrer"
                className="btn form-submit"
              >
                {props.label}
              </a>
            );
          },
        },
        { title: 'Description', field: 'description', editable: 'never' },
        { title: 'Owner', field: 'created_by', editable: 'never' }
      ]
    }
    if (tab === 'my_group') {
      return [
        {
          title: 'Name', field: 'label',
          render: props => {
            return (
              <a
                href={'/custom-groups?shared=' + props.uuid}
                target="_blank"
                rel="noopener noreferrer"
                className="btn form-submit"
              >
                {props.label}
              </a>
            );
          },
        },
        { title: 'Description', field: 'description' },
        {
          title: 'Shared with', field: 'available_users_share',
          render: props => {
            let available_users = [];

            if (props.available_users_share) {
              props.available_users_share.forEach(availableUser => {
                available_users.push(availableUser.label);
              })
            }

            const listUsers = available_users.map((available_user) =>
              <>
                <p>{available_user}</p>
                <Divider variant="fullWidth" />
              </>
            );

            return (
              listUsers
            );
          },
          editComponent: props => {
            let filteredUsers = users.filter((userF) => userF.group_id === activeUser.group_id ? userF : false);
            let usersFormat = filteredUsers.map((userF) => {
              let userGroup = userGroups.find(userGroup => userGroup.id === userF.group_id);
              return ({ 'value': userF.email, 'label': userF.name + ' @ ' + userGroup.name });
            });

            return (
              <Select
                options={usersFormat}
                id={'shared-with'}
                label="Shared with"
                defaultValue={props.value}
                isMultiple={true}
                onChange={e => {
                  props.onChange(e)
                }}
              />
            );
          }
        }
      ]
    }
  }, [activeUser.group_id, userGroups, users]);

  const getGroups = useCallback(() => {
    if (activeTab === 'shared_with') {
      axios.get(`${process.env.REACT_APP_API_URL}/rpc/hvd_shared_group?_email=${user.email}`, {
        headers: {
          'ngrok-skip-browser-warning': true
        }
      })
        .then(res => {
          setGroups(res.data);
        });
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}/custom_groups?created_by=eq.${user.email}`, {
        headers: {
         'ngrok-skip-browser-warning': true   
        }
      })
        .then(res => {
          setGroups(res.data);
        });
    }
    axios.get(`${process.env.REACT_APP_API_URL}/groups`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(res => {
        return setUserGroups(res.data);
      });
  }, [activeTab, user.email]);

  useEffect(() => {
    getGroups();
    axios.get(`${process.env.REACT_APP_API_URL}/users`, {
      headers: {
       'ngrok-skip-browser-warning': true   
      }
    })
      .then(res => {
        setUsers(res.data);

        let currentUser = res.data.find((element, index) => {
          let valid = false;

          if (element.email === user.email) {
            valid = true;
          }

          return valid;
        });

        setActiveUser(currentUser);
      });
  }, [getGroups, user]);

  useEffect(() => {
    setColumns(defineColumns(activeTab));
  }, [activeTab, defineColumns]);

  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve) => {
    //validating the data inputs
    let errorList = [];

    if (errorList.length < 1) {
      axios.put(`${process.env.REACT_APP_API_URL}/custom_groups?uuid=eq.${newData.uuid}`, newData,{
        headers: {
          'ngrok-skip-browser-warning': true
        }
      })
        .then(response => {
          getGroups();
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Update Failed! ${error.response.data.details}`]);
          }

          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  }

  return (
    <LayoutPage
      pageTitle='Custom Groups'
      description='View, and update custom groups.'
      customClass='page-crud'
    >
      <div className="layout-wrapper">
        <div className="groups-buttons">
          {
            tabs.map((tab, key) =>
              <Button
                key={key}
                className={`groups-buttons__item ${key === 0 ? 'active' : ''}`}
                onClick={(e) => {
                  let buttons = document.querySelectorAll('.groups-buttons__item');
                  buttons.forEach(button => button.classList.remove('active'));
                  e.currentTarget.className += " active";
                  setActiveTab(tab.value);
                }}
              >
                {tab.name}
              </Button>
            )
          }
        </div>
        <MaterialTable
          title="Custom Groups"
          columns={columns}
          data={groups}
          options={{
            headerStyle: { borderBottomColor: 'rgb(102, 16, 242)', borderBottomWidth: '3px' },
            actionsColumnIndex: -1,
            toolbarButtonAlignment: "left",
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          components={{
            Toolbar: props => (
              <MTableToolbar {...props} classes={{ root: "board-toolbar" }} />
            ),
          }}
          editable={
            activeTab !== 'shared_with' ? {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  handleRowUpdate(newData, oldData, resolve);
                })
            } : {}}
        />

        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
        <ReportButtons boardType={'Groups'} />
      </div>
    </LayoutPage>
  );
}

export default CrudCustomBoards;
