import React, { useEffect } from "react";
import Slider from '@mui/material/Slider';
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import styles from "./rangeslider.module.scss";
import { simpleFetch, getToken, moneyFormat } from "../../utils/helpsers";

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider({ values, onChange, varData, district, year, disabled=false }) {
  const [value, setValue] = React.useState(values);
  const [districtValue, setDistrictValue] = React.useState();
  const [toggle, setToggle] = React.useState(false);

  //Button styles
  const ColorButton = styled(Button)(({ theme }) => ({
    margin: "10px 0",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
  }));

  const RangeSlider = styled(Slider)(({theme}) => ({
    marginBottom: "10px",
    "&.MuiSlider-root": {
      display: "block",
      color: "#773DBD",
      margin: "0 auto 0",
      width: "calc(100% - 12px)",
      zIndex: "0"
    },
    ".MuiSlider": {
      "&-thumb[data-index='0']": {
        color: "#221349",
        background: "#fff",
        boxShadow: "0 0 5px #000",
        marginLeft: "0",
        width: "15px",
        height: "15px",
        ".MuiSlider-valueLabel": {
          opacity: ".90",
          top: "20px",
          right: "10px",
          "&::before": {
            display: "none"
          },
          ".& > span": {
            height: "auto !important",
            padding: "0 !important",
            width: "auto",
          },
          "span": {
            color: "#ffff",
            borderRadius: "4px",
            fontWeight: "bold",
            transform: "none",
          }
        }
      },
      "&-thumb[data-index='1']": {
        ".MuiSlider-valueLabel": {
          opacity: '0.9',
          top: '20px',
          left: '11px',
          "&::before": {
            display: 'none',
          },
          "& > span": {
            height: 'auto !important',
            padding: '0 !important',
            width: 'auto',
          },
          "span": {
            color: '#ffff',
            "border-radius": '4px',
            "font-weight": 'bold',
            transform: 'none',
          }
        }
      },
      '&-mark': {
        height: '8px',
        marginTop: '-3px',
        background: '#F5B335',
        width: '3px',
        pointerEvents: "auto !important",
      },
      '&-markLabel': {
        display: 'none',
      },

      '&-thumb': {
        color: '$primary-color',
        background: '#fff',
        boxShadow: '0 0 5px #000',
        marginLeft: '0',
        width: '15px',
        height: '15px',
      },

      '&-mark:hover + .MuiSlider-markLabel': {
        display: 'block'
      },

      '&-markLabelActive': {
        background: 'rgba(0, 0, 0, 0.9)',
        color: '#fff',
        zIndex: '2',
        padding: '3px 6px',
      }
    },
    '.Mui-focusVisible': {
      '.MuiSlider-valueLabel': {
        opacity: '1'
      }
    }
  }));

  useEffect(() => {
    if (district && varData) {
      simpleFetch(`${process.env.REACT_APP_API_URL}/hvd_tabloide?select=${varData.value_var}&irn=eq.${district.value}&file_year=eq.${year.value}`, getToken())
        .then((data) => setDistrictValue(data[0][varData.value_var]))
    }
  }, [district, varData, year]);

  const marks = district ? districtValue ? [{
    value: +districtValue,
    label: `${district.label}: ${districtValue}`,
  }] : null : null;

  const unit = varData.var_type;
  //step
  const availableRange = value[1] - value[0];
  const step = availableRange / 50;

  //Format
  function valueLabelFormat(value) {
    let nf = new Intl.NumberFormat('en-US');
    const roundOut = Number.isInteger(value) ? value : value.toFixed(2);

    switch (unit) {
      case "%":
        return `${roundOut}${unit}`;
      case "$":
        if (roundOut >= 1000 && roundOut < 999999) {
            return moneyFormat({val: roundOut, base: 1000, tag: 'K'})
        } else if (roundOut >= 1000000 && roundOut < 999999999) {
            return moneyFormat({val: roundOut, base: 1000000, tag: 'M'})
        } else if (roundOut >= 1000000000) {
            return moneyFormat({val: roundOut, base: 1000000000, tag: 'B'})
        } else {
          return `${unit}${roundOut}`
        }
      default:
        return nf.format(roundOut);
    }
  }

  const handleChange = (event, newValue) => {
    if (newValue[0] < newValue[1]) {
      setValue(newValue);
      onChange(newValue);
    }
  };

   const handleInputChange = (event, type) => {
    let inputValue = +event.target.value;
    let newValue =
      type === "min" ? [inputValue, value[1]] : [value[0], inputValue];
    setValue(newValue);
    onChange(newValue);
  };

  const handleBlur = (event) => {
    let inputValue = +event.target.value;
    if (
      inputValue < varData.min_range ||
      inputValue > varData.max_range
    ) {
      setValue(varData.min_range);
      onChange(varData.max_range);
    }
  };
  

    return typeof varData.min_range === "number" && typeof varData.max_range === "number" ? (
      <>
    <RangeSlider
      getAriaLabel={() => varData.label}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      getAriaValueText={valuetext}
      min={varData.min_range}
      max={varData.max_range}
      marks={marks}
      step={step}
      disabled={disabled}
      classes={{
        root: "RangeSlider",
      }}
    />
          {toggle && (
        <div className={styles.varRangeSliderInputs} {...toggle}>
          <Input
            className={"input__min"}
            value={[+value[0].toFixed(2)]}
            margin="dense"
            onChange={(e) => handleInputChange(e, "min")}
            onBlur={handleBlur}
            inputProps={{
              step: value[0] < 1 ? 0.1 : 1,
              min: varData.min_range,
              max: varData.max_range,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
            disabled={disabled}
          />
          <Input
            className={"input__max"}
            value={[+value[1].toFixed(2)]}
            margin="dense"
            onChange={(e) => handleInputChange(e, "max")}
            onBlur={handleBlur}
            inputProps={{
              step: value[1] < 1 ? 0.1 : 1,
              min: varData.min_range,
              max: varData.max_range,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
            disabled={disabled}
          />
        </div>
      )}
      <ColorButton
        variant="outlined"
        onClick={() => setToggle(!toggle)}
      >
        Manual Input
      </ColorButton>
    </>
    
  ) : (
        <div className={styles.noDataMessage}>(Data unavailable)</div>
    );
}
