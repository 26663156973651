import React from "react";
import { Map, TileLayer, Polygon, Tooltip} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { currencyFormat } from '../../utils/helpsers';
// Styles
import './mapViewer.scss';

/**
 *
 * @param {object} data:
 *  - data: Array of values to build the polygon.
 *  - color: The polygon color.
 *  - name: District name.
 * @param {number} districtIrn: number ID of the reference district
 * @param {number} zoom: the amount of zoom applied on map load.
 * @returns {JSX.Element}
 */
const MapView = ({ data, districtIrn, zoom = window.innerWidth <= 1023 ? 6.8 : 7.5, height}) => {
  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  const ohioLocation = [40.1, -82.696216];
  return (
      <div className="map-viewer">
        <Map center={ohioLocation} zoom={zoom} scrollWheelZoom={false} zoomControl={true} zoomSnap={0.25} zoomDelta={.25} style={{height: height}}>
          <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            data.map((location, index) =>
                location.data && (
                    <Polygon key={index} positions={location.data}
                             color={location.irn === districtIrn ? "#FFC300" : "#f7f5e54f"}
                             fillColor={location.irn === districtIrn ? "#FFC300" : (location.color ?? "#4646FF")}
                             fillOpacity={.9}
                             weight={1}>
                      <Tooltip>
                        <div>{location.name}</div>
                        <div>Physical Address: <strong>{location.physical_address ? location.physical_address: "(Data Unavailable)"}</strong></div>
                        <div>Total Year-end Enrollment: <strong>{location.total_year_end_enrollment_fy1 ? internationalNumberFormat.format(parseInt(location.total_year_end_enrollment_fy1)) : "(Data Unavailable)"}</strong></div>
                        <div>Enrollment (ADM): <strong>{location.total_average_daily_membership_fy1 ? internationalNumberFormat.format(parseInt(location.total_average_daily_membership_fy1)) : "(Data Unavailable)"}</strong></div>
                        <div>Graduation Rate (4 Year Rate): <strong>{location.four_year_graduation_rate ? location.four_year_graduation_rate + "%" : "(Data Unavailable)"}</strong></div>
                        <div>State Revenue As % Of Total: <strong>{location.state_revenue_as_percent_of_total_fy? location.state_revenue_as_percent_of_total_fy + "%": "(Data Unavailable)"}</strong></div>
                        <div>Total Actual Expenses: <strong>{location.total_expenditures_5_050? currencyFormat(location.total_expenditures_5_050) : "(Data Unavailable)"}</strong></div>
                        {/* We don't receive any property called 'et_broadband_of_any_type' in the object */}
                        <div>Households with Broadband Access (ACS): <strong>{location.et_broadband_of_any_type ? location.et_broadband_of_any_type + "%" : "(Data Unavailable)"}</strong></div>
                      </Tooltip>
                    </Polygon>
                )
            )}
        </Map>
      </div>
  );
};

export default MapView;
