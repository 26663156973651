import React, { useState, useEffect } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import CustomEvents from "highcharts-custom-events";
import { buildTooltip } from "../../utils/helpsers";
import logoSignature from '../../IB_Signature.png';
import './pie-chart.scss';
import Button from '@mui/material/Button';

CustomEvents(Highcharts);

const PieChart = ({ title, subtitle = null, id, data, table = false, toggle = false }) => {
  const [displayTable, setDisplayTable] = useState(table);
  const [showRawDataTable, setShowRawDataTable] = useState(false);

  let colors = ['#E1D7F0', '#00D7BE', '#773dbd', '#ff5569', '#F5B335', '#F7AEF8'];

  useEffect(() => {
    let infoIcons = document.querySelectorAll('.info-icon');
    Array.from(infoIcons).forEach((el) => {
      let infoIcon = document.getElementById(el.id);
      let desId = el.id + '-desc';
      let description = document.getElementById(desId);
      buildTooltip({ infoIcon: infoIcon, info: description.innerHTML });
    });
  });

  let logoCredit;
  const pieChartData = {
    chart: {
      type: "pie",
      height: 550,
      backgroundColor: 'rgba(0,0,0,0)',
      style: {
        fontFamily: ['Titillium Web', 'sans-serif']
      },
      events: {
        load: function () {
          if (this.options.chart.forExport) {
            this.renderer.image(`${logoSignature}`, 10, 530, 160, 20).add();
          }
        },
        beforePrint: function () {
          logoCredit = this.renderer.image(`${logoSignature}`, 10, 530, 160, 20).add();
          this.print();
        },
        afterPrint: function () {
          logoCredit.element.remove();
        }
      },
      spacingBottom: 50,
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      useHTML: true,
      labelFormatter: function () {
        let definition = this.definition,
          labelId = (this.name).replace(/[^A-Z0-9]+/ig, "_") + '_' + id;
        if (definition) {
          document.getElementById(labelId);
          return `<span class="legend-name"> ${this.name}<span class="info-icon" id="${labelId}" style="font-size: 14px; fill:#773dbd">ⓘ</span></span>
                  <span class="legend-value">${this.formatted}</span>
                  <span id="${labelId}-desc" style="display: none">${definition}</span>`
        } else {
          return this.name + ' ' + this.formatted;
        }
      },
      width: 280,
      className: 'tableFormat',
      borderWidth: 1,
      borderColor: '#cdcdcd',
    },
    title: {
      text: '<b>' + title + '</b>',
      style: { "fontSize": "15px" }
    },
    subtitle: {
      text: subtitle,
      style: {
        fontSize: '14px',
      },
    },
    tooltip: {
      followPointer: false,
      formatter: function () {
        return `${this.key}: <b>${this.point.formatted}</b>`;
      },
      backgroundColor: '#FFF',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: '45%',
        size: 270,
        colors: colors,
        dataLabels: {
          enabled: !displayTable,
          connectorShape: 'crookedLine',
          crookDistance: '90%',
          alignTo: 'plotEdges',
          formatter: function () {
            let definition = this.point.definition,
              labelId = (this.key).replace(/[^A-Z0-9]+/ig, "_") + '_' + id;
            if (definition) {
              document.getElementById(labelId);
              return `<span class="label-key"> ${this.key}</span>
                      <span class="info-icon" id="${labelId}" style="font-size: 14px; fill:#773dbd">ⓘ</span>
                      </br>
                      <span>${this.point.options.formatted}</span>
                      <span id="${labelId}-desc" style="display: none">${definition}</span>`
            } else {
              return `<span> ${this.key}: ${this.point.options.formatted}</span>`
            }
          },
          style: {
            textOutline: false
          },
        },
        showInLegend: displayTable,
      }
    },
    exporting: {
      tableCaption: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: PDF in ${title}`,
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: CSV in ${title}`,
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: XLS in ${title}`,
                ]);
                this.downloadXLS();
              },
            },
            {
              separator: true,
            },
            {
              text: "Raw Data Table",
              onclick: function() {
                
                if(showRawDataTable)
                {
                  setShowRawDataTable(!showRawDataTable);
                  this.hideData();
                  
                } else {
                  setShowRawDataTable(!showRawDataTable);
                  this.viewData()
                }

                window._paq.push([
                  "trackEvent",
                  "Board District Insight",
                  `Opened the Raw Data Table`,
                ]);
              }        
            }
          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      }
    },
    series: [
      {
        type: 'pie',
        name: title,
        data: data
      }
    ]
  }

  return (
    <div className="pie-chart-wrapper">
      {
        toggle &&
        <Button color="primary" variant="outlined" className="toggle-table-btn" onClick={() => setDisplayTable(!displayTable)}>
          {displayTable === true ? 'Hide' : 'Show'} Table
        </Button>
      }
      <HighchartsReact
        highcharts={Highcharts}
        options={pieChartData}
      />
    </div>
  )
}

export default React.memo(PieChart);
