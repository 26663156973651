import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import DefinitionsCardSelector from "../../components/DefinitionsCardSelector/DefinitionsCardSelector";
import GroupedTree from "../../components/GroupedTree/GroupedTree";
import LayoutPage from "../../layout/LayoutPage";
import { simpleFetch } from "../../utils/helpsers";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { ReportButtons } from "../../components/BugReport/ReportButtons";

/**
 * Converts the data from the endpoint:
 * `${process.env.REACT_APP_API_URL}/hvd_source_variable?order=label.asc
 * To a compacted format. If a expected value is not present, will substitute that value with a 
 * default message.
 * @param {JSON} data A JSON map
 * @returns 
 */
function dataCheckAndFormat(data) {
  // For the alphabetical order.
  const alphabetical = {};
  // Grouped by domains order
  const grouped = {};
  // By Author
  const authors = {};
  // Optimizing memory.
  const dataMap = {};
  data.forEach(item => {

    const letter = item.label.charAt(item.label.indexOf('/') + 1).toUpperCase();

    if (!(letter in alphabetical)) {
      alphabetical[letter] = { id: letter, items: [] };
    }

    if (!(item.domain in grouped)) {
      grouped[item.domain] = { id: item.domain, items: [] };
    }

    if (!(item.author in authors)) {
      authors[item.author] = { id: item.author, items: [] };
    }

    if (!(item.value in dataMap)) {
      alphabetical[letter].items.push(item.value);
      grouped[item.domain].items.push(item.value);
      authors[item.author].items.push(item.value);

      const newItem = {
        id: item.value,
        title: item.label,
        domain: item.domain,
        subtitle: item.description ? item.description : 'No description available.',
        description: item.description,
        footnote: item.note,
        rows: [
          ['Data Type', item.var_type ? item.var_type : 'No data type available.'],
          ['Authority', item.author ? item.author : 'No source available.'],
          ['Citation', item.source ? item.source.apa_ref ? item.source.apa_ref : 'No APA reference available.' : 'No source available.'],
          [item.dwh_table === 'calculated' ? 'Formula' : 'Notes', item.notes ? item.notes : 'No note available.'],
          ['Years Available', item.available_years ? item.available_years : []],
        ]
      };

      dataMap[item.value] = newItem;
    }

    const obj = dataMap[item.value];

    for (let i = 0; i < obj.rows[4][1].length; i++) {
      if (obj.rows[4][1][i] === item.file_year) {
        obj.rows[4][1][i] = <span> <a href={item.source.r_page}>{item.file_year}</a>{i === obj.rows[4][1].length - 1 ? '' : ','}</span>;
      }
    }

    if (item.file_year > obj.rows[3][1])
      obj.rows[2][1] = item.file_year;

  });

  return [[alphabetical, grouped, authors], dataMap];
}

function Glossary() {
  // User session metadata and data
  const { user } = useAuth0();
  const metadata = user[`${process.env.REACT_APP_AUTH0_KEY}/district`];

  const history = useHistory();

  const [glossaryData, setGlossaryData] = useState([]);
  //const [alphabetical, setAlphabetical] = useState(null);
  const [orders, setOrders] = useState([]);
  //const [domainsOrder, setDomainsOrder] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [ordersType] = useState(['Alphabetical', 'Domains', 'Authors']);
  const sourceURL = `${process.env.REACT_APP_API_URL}/hvd_source_variable?order=label.asc`;

  //Button styles
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "rgba(0, 0, 0, 0.87)",
    border: "none",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  }));

  useEffect(() => {
    if (metadata) {
      const userSessionType = metadata.hasOwnProperty('sessionType') ? metadata.sessionType : 'forbidden';

      switch (userSessionType) {
        case 'district-access':
          window.localStorage.setItem('session-type', 'district-access');
          break;
        case 'full-access':
          window.localStorage.setItem('session-type', 'full-access');
          break;
        case 'multiple-district-access':
          window.localStorage.setItem('session-type', 'multiple-district-access');
          break;
        case 'forbidden':
          history.push('/AccessDenied');
          break;
        default:
          history.push('/AccessDenied');
          break;
      }
    } else {
      history.push('/AccessDenied');
    }
  }, [history, user, metadata]);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    simpleFetch(sourceURL)
      .then(data => dataCheckAndFormat(data))
      .then(([newOrders, data]) => {
        setOrders(
          newOrders.map(order =>
            Object.entries(order).map(i =>
              i[1]).sort((a, b) => a.id < b.id ? -1 : 1)));
        setGlossaryData(data);
      })
      .catch(error => alert(error.response.data.details));
  }, [sourceURL]);

  return (
    <LayoutPage
      pageTitle="Glossary"
      description="Build custom groups based on filters"
      type="page"
      customClass="page-glossary"
    >
      <div className="header__bottom">
        <div className="layout-wrapper" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <section className="section section--data-filters" >
            <div>
              <DefinitionsCardSelector sourceURL={sourceURL} />
            </div>
          </section>
          <div>
            <ColorButton onClick={() => {
              return setCurrentOrder(
                currentOrder >= orders.length - 1 ? 0 : currentOrder + 1)
            }
            }>
              {
                ordersType[currentOrder]
              }
            </ColorButton>
          </div>
        </div>
      </div>
      <div className={"layout-wrapper"}>
        {<GroupedTree data={glossaryData} groupBy={orders[currentOrder]} />}
      </div>
      <ReportButtons boardType={'Glossary'} />
    </LayoutPage>
  );
}

export default Glossary;
