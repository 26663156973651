import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./auth0/ProtectedRoute/ProtectedRoute";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Glossary from "./pages/basic-pages/Glossary";
import PageNotFound from "./pages/basic-pages/PageNotFound";
import CustomGroups from './pages/boards/CustomGroups';
import DataViewer from './pages/boards/DataViewer';
import DistrictExplorer from './pages/boards/DistrictExplorer';
import DistrictInsight from './pages/boards/DistrictInsight';
import ElectionHistory from './pages/boards/ElectionHistory';
import LaborDashboard from './pages/boards/LaborDashboard';
import Groups from './pages/cruds/Groups';
import Users from "./pages/cruds/Users";
import AccessDenied from "./pages/basic-pages/AccessDenied";
import { setToken } from './utils/helpsers';
import CustomBoards from './pages/cruds/CustomBoards';
import CrudCustomGroups from './pages/cruds/CustomGroups';
import Profile from "./pages/basic-pages/Profile/Profile";
import axios from "axios";
import { TokenContext } from "./utils/Context";
import PrivacyPolicy from "./pages/basic-pages/PrivacyPolicy";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Titillium Web',
      'san-serif',
    ].join(','),
  },
});

function App() {
  const [userToken, setUserToken] = useState(window.localStorage.getItem('token'));
  const { isLoading, logout, getIdTokenClaims, user } = useAuth0();
  axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;


  useEffect(() => {
    if (user) {
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function () {
        var u = `https://${process.env.REACT_APP_MATOMO_URL}/`;
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '1']);
        _paq.push(['setUserId', window.localStorage.getItem('user_email') + " - " + window.localStorage.getItem('session-type') + " - " + user[`${process.env.REACT_APP_AUTH0_KEY}/role`]]);
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = `//cdn.matomo.cloud/${process.env.REACT_APP_MATOMO_URL}/matomo.js`; s.parentNode.insertBefore(g, s);
      })();
    }
  }, [user])

  if (!window.localStorage.getItem('token')) {
    getIdTokenClaims().then(claims => {
      if (claims) {
        setToken(claims.__raw, claims.exp);
        window.localStorage.setItem('user_email', user.email);
        setTimeout(() => {
          setUserToken(window.localStorage.getItem('token'));
        }, 1000)
      }
    }).catch(err => {
      console.debug("Something went wrong", err);
    });
  } else if (window.localStorage.getItem('exp')) {
    var timeStampNow = Math.floor(Date.now() / 1000);

    if (window.localStorage.getItem('exp') < timeStampNow) {
      localStorage.removeItem('fixedSelection');
      localStorage.removeItem('token');
      localStorage.removeItem('exp');
      localStorage.removeItem('session-type');
      localStorage.removeItem('user_email');

      logout({
        returnTo: window.location.origin,
      })
    }
  }

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <TokenContext.Provider value={{ userToken, setUserToken }}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/insights" />
          </Route>
          <ProtectedRoute exact path="/insights" component={DistrictInsight} />
          <ProtectedRoute exact path="/explorer" component={DistrictExplorer} />
          <ProtectedRoute exact path="/custom-groups" component={CustomGroups} />
          <ProtectedRoute exact path="/election-history" component={ElectionHistory} />
          <ProtectedRoute exact path="/labor-dashboard" component={LaborDashboard} />
          <ProtectedRoute path="/glossary" component={Glossary} />
          <ProtectedRoute path="/data-viewer" component={DataViewer} />
          <ProtectedRoute exact path="/accounts" component={Groups} />
          <ProtectedRoute exact path="/users" component={Users} />
          <ProtectedRoute exact path="/custom-boards" component={CustomBoards} />
          <ProtectedRoute exact path="/crud/custom-groups" component={CrudCustomGroups} />
          <ProtectedRoute path="/AccessDenied" component={AccessDenied} />
          <ProtectedRoute path="/Profile" component={Profile} />
          <ProtectedRoute path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </TokenContext.Provider>
    </ThemeProvider>
  );
}

export default App;
