import LayoutPage from "../../layout/LayoutPage";
import { Box, Typography } from "@mui/material";
const PrivacyPolicy = () => {
  return (
    <LayoutPage
      pageTitle="Privacy Policy"
      description="Privacy Policy"
      type="basic"
    >
      <Box sx={{ margin: "1.2em auto", width: "90%", maxWidth:"1440px" }}>
        <Typography variant="h4">INSIGHTBOARD PRIVACY POLICY</Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          InsightBoard (“InsightBoard”, “we”, “our”, and “us”) respects your
          concerns about privacy and personal data protection and we value our
          relationship with you. This Privacy Policy (“Policy”) applies solely
          to personal information we collect from you via our website at
          InsightBoard.org (the “Site”) and through our on-demand support
          platform, tools and services offered on the Site (the “Service”).
          {"\n"}
          {"\n"}
          By accessing and using InsightBoard and Services you are acknowledging
          that you understand and agree to the terms of this Policy.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          PERSONAL INFORMATION WE COLLECT
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          <b>Personal Information You Manually Provide.</b> When you register
          for the Service or sign up for a trial account, or newsletter, we
          collect personal information such as:
          <ul>
            <li>Your name</li>
            <li>Organization name and address</li>
            <li>Email address</li>
            <li>Mobile phone (for two-factor authentication)</li>
          </ul>
          If you speak with us on the telephone, we will collect any personal
          information you provide to us and we may record such conversations for
          quality assurance and training purposes.
          {"\n"}
          {"\n"}
          <b>Information Sent to Us By Your Web Browser.</b> We collect
          information that is sent to us automatically by your web browser or
          mobile device. This information typically includes your IP address,
          the identity of your Internet service provider, the name and version
          of your operating system, the name and version of your browser, the
          date and time of your visit, and the pages you visit. The information
          we receive may depend on your browser or device settings.{"\n"}
          {"\n"}
          Generally, we use this information in the aggregate to help us improve
          this Site and make it more compatible with the technology used by our
          visitors. We may combine it with other information in an attempt to
          identify you or we may combine it with information that does identify
          you. We may also review our server logs for security purposes, for
          example, to detect intrusions into our network, and we might share our
          server logs, which contain visitors’ IP addresses, with the
          appropriate investigative authorities who could use that information
          to trace and identify you.{"\n"}
          {"\n"}
          <b>Information Collected by Cookies and Other Technologies.</b> We use
          “cookies” and other technologies to collect information and support
          certain features of the Site.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          HOW WE USE YOUR PERSONAL INFORMATION
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          InsightBoard uses the personal information it collects about you to
          operate and make the Service available to you, for billing,
          identification and authentication purposes, to contact you about your
          use of the Service, research purposes, and to generally improve the
          content and functionality of the Service and Site. InsightBoard’s
          practice is not to use your personal information for marketing
          purposes.{" "}
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          CHOICES ABOUT YOUR PERSONAL INFORMATION
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          We offer certain choices about how we communicate with you and what
          personal information we obtain about you and share with others.
          {"\n"}
          {"\n"}
          <b>In General.</b> We respect your right to make choices about the
          ways we collect, use, and disclose your information. In addition, we
          may ask you to indicate your choices at the time and on the page where
          you provide your information. For example, we may give you a choice
          about the types of email communications you receive from us and/or our
          business partners.
          {"\n"}
          {"\n"}
          <b>Email preferences.</b> You cannot opt out of transactional emails
          related to your membership/registration with the Site. If we send
          promotional emails, you can opt out of receiving them by selecting
          “unsubscribe” located at the bottom of each communication.
          {"\n"}
          {"\n"}
          <b>Do Not Track.</b> Because there currently is not an industry or
          legal standard for recognizing or honoring Do Not Track (“DNT”)
          signals, we do not respond to them at this time.
          {"\n"}
          {"\n"}
          <b>Cookies.</b> When you visit the Site or use the Service, we use
          session “cookies” – a piece of information stored on your computer –
          to allow the Site or Service to uniquely identify your browser while
          you are logged in and to enable InsightBoard to process your online
          transactions. Session cookies also help us confirm your identity and
          are required in order to use the Service. InsightBoard uses persistent
          cookies that only InsightBoard can read and use, to identify you as a
          InsightBoard customer and make it easier for you to log in to the
          Service. Users who disable their web browsers’ ability to accept
          cookies will not be able to access or take advantage of the Service.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          INFORMATION SHARING
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          InsightBoard may transmit or share your personal information with its
          third-party vendors and hosting partners to provide the necessary
          hardware, software, networking, storage, and other technology and
          services required to operate and maintain the Service, which may
          require that your personal information be transferred from your
          current location to the offices and servers of InsightBoard and the
          authorized third parties referred to here.
          {"\n"}
          {"\n"}
          We may also disclose personal information:
          <ul>
            <li>
              To respond to subpoenas, court orders, or legal process, or to
              establish or exercise our legal rights or defend against legal
              claims
            </li>
            <li>
              If we believe it is necessary in order to investigate, prevent, or
              take action regarding illegal activities, suspected fraud,
              situations involving potential threats to the physical safety of
              any person, violations of our Terms of Service, or as otherwise
              required by law. InsightBoard may also provide summary or group
              statistics about our customers, sales, traffic patterns, and
              related Site information to reputable third-party vendors, but
              these statistics will not include personally identifying
              information.{" "}
            </li>
            <li>
              In the event that we are subject to mergers, acquisitions, joint
              ventures, sales of assets, reorganizations, divestitures,
              dissolutions, bankruptcies, liquidations, or other types of
              business transactions. In these types of transactions, personal
              information may be shared, sold, or transferred, and it may be
              used subsequently by a third-party
            </li>
          </ul>
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          LOCATION OF OUR SITE
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          This Site is hosted and operated in the United States.
          {"\n"}
          {"\n"}
          If you are not a resident of the United States, you acknowledge and
          agree that we may collect and use your personal information outside
          your home jurisdiction, and that we may store your personal
          information in the United States or elsewhere. Please note that the
          level of legal protection provided in the United States from which you
          may access our Site may not be as stringent as that under privacy
          standards or the privacy laws of other countries, possibly including
          your home jurisdiction.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          AGGREGATE INFORMATION
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          InsightBoard may maintain, use, or disclose, or any combination of the
          foregoing, information collected on the Site in the aggregate.
          InsightBoard may use this data to improve the Site and the Service.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          CHILDREN’S PERSONAL INFORMATION
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          Compliance with Applicable Laws. The Customer and InsightBoard will
          jointly ensure compliance with the federal Family Educational Rights
          and Privacy Act ("FERPA") (20 U.S.C. Sec.1232g) through following the
          confidentiality provisions as set forth in this agreement, as well as
          applicable Customer policies to the extent such policies are
          consistent with the provisions of this Agreement.
          {"\n"} {"\n"}
          <b>
            The Service and Site are not intended for, nor does InsightBoard
            knowingly collect any personally identifiable information from
            children under the age of 17. If you are under 17, do not use or
            provide any information on the Site or on or through any of its
            features, including your name or email address. If we learn we have
            collected or received personal information from a child under 17
            without verification of parental consent, we will delete that
            information. If you believe we might have any information from or
            about a child under 17, please contact us at legal@InsightBoard.org.
          </b>
          {"\n"}
          {"\n"}
          The parties acknowledge and agree that the Customer is subject to
          federal and local laws relating to the protection of personally
          identifiable information ("PII") of students, including FERPA, and to
          the extent that InsightBoard receives PII, it is obtaining such PII as
          a "school official" under section 99.31 of FERPA for the purpose of
          providing the services hereunder. In addition to FERPA, InsightBoard
          shall comply with all federal, state and local laws, rules,
          regulations and ordinances that are now or may in the future become
          applicable to InsightBoard; the services being provided by
          InsightBoard; InsightBoard's business, equipment and personnel engaged
          in operations covered by this Agreement; and the protection of Pupil
          Records and PII, including but not limited to Education Code section
          49060 et seq., the Children's Online Privacy Protection Act (COPPA)
          and the Student Online Personal Information Protection Act (SOPIPA).
          InsightBoard certifies that it is familiar with these laws, as well as
          any other applicable requirements for the storage and transmission of
          Pupil Records and InsightBoard will comply with all such requirements.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          PROTECTION OF INFORMATION
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          InsightBoard maintains reasonable security measures to protect your
          information from loss, destruction, misuse, unauthorized access or
          disclosure. These technologies help ensure that your data is safe,
          secure, and only available to You and to those You provided authorized
          access. However, no data transmission over the Internet or information
          storage technology can be guaranteed to be 100% secure.
        </Typography>
        <Typography variant="h6" sx={{ margin: "10px 0" }}>
          Transfers, Storage, and Processing
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          Our Site is from and hosted on servers located in the United States.
          If you access and use our website from a location outside of the
          United States, any personal data you provide to us or that is
          otherwise collected may be transferred to and processed in the United
          States or any other jurisdiction in our sole discretion. Users of our
          Site should be aware that the laws that apply to the use and
          protection of personal data in the United States or other countries or
          jurisdictions to which we transfer, or in which we process, personal
          data may differ from those of your country of residence. Users who
          access or use our Site from jurisdictions outside of the United States
          do so at their own choice and risk and are solely responsible for
          compliance with local law.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          CHANGES TO PRIVACY POLICY
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          InsightBoard may update this Policy from time to time. You can review
          the most current version of this Policy at any time at
          InsightBoard.org. Your continued use of the Site or Service
          constitutes your agreement to be bound by such changes to the Policy.
        </Typography>
        <Typography variant="h5" sx={{ margin: "10px 0" }}>
          CONTACT US
        </Typography>
        <Typography
          variant="body1"
          display="block"
          sx={{ whiteSpace: "pre-line" }}
        >
          If you have questions regarding this Policy or about the security
          practices of InsightBoard, please contact us by email at
          legal@InsightBoard.org.{"\n"}
        </Typography>
      </Box>
    </LayoutPage>
  );
};

export default PrivacyPolicy;
