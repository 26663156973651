import React, {useState} from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import logoSignature from '../../IB_Signature.png';
import './column-chart.scss';

const ColumnChart = (props) => {

  const [showRawDataTable, setShowRawDataTable] = useState(false);

  let logoCredit;
  const pieChartData = {
    chart: {
      zoomType: 'xy',
      backgroundColor: 'rgba(0,0,0,0)',
      style: {
        fontFamily: ['Titillium Web', 'sans-serif']
      },
      events: {
        load: function () {
          if (this.options.chart.forExport) {
            this.renderer.image(`${logoSignature}`, 10, 15, 100, 15).add();
          }
        },
        beforePrint: function () {
          logoCredit = this.renderer.image(`${logoSignature}`, 10, 15, 100, 15).add();
          this.print();
        },
        afterPrint: function () {
          logoCredit.element.remove();
        }
      },
    },
    title: {
      text: props.title,
    },
    xAxis: [{
      categories: props.data[0].categories,
      crosshair: true
    }],
    yAxis: [{ // Primary yAxis
      labels: {
        format: '{value}',
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      title: {
        text: 'ADMs',
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      }
    }, { // Secondary yAxis
      title: {
        text: 'Districts'
      },
      labels: {
        format: '{value}'
      },
      opposite: true
    }],
    tooltip: {
      shared: true,
      backgroundColor: '#FFF',
    },
    exporting: {
      tableCaption: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: PDF in ${props.title}`,
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: CSV in ${props.title}`,
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: XLS in ${props.title}`,
                ]);
                this.downloadXLS();
              },
            },
            {
              separator: true,
            },
            {
              text: "Raw Data Table",
              onclick: function() {

                if(showRawDataTable)
                {
                  setShowRawDataTable(!showRawDataTable);
                  this.hideData();
                  
                } else {
                  setShowRawDataTable(!showRawDataTable);
                  this.viewData()
                }

                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsight",
                  `Opened the Raw Data Table`,
                ]);
              }          
            }
          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      }
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      floating: false,
      verticalAlign: 'top',
    },

    series: [{
      name: 'Districts',
      states: {
        select: {
          borderColor: '#ff5569',
          color: '#ff5569',
        }
      },
      type: 'column',
      yAxis: 1,
      color: '#773dbd',
      data: props.data[0].data,
    }, {
      name: 'ADMs',
      states: {
        select: {
          borderColor: '#f5b335',
          color: '#f5b335',
        }
      },
      color: '#E1D7F0',
      type: 'column',
      data: props.data[0].data_sum
    }]
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={pieChartData}
    />
  )
}

export default ColumnChart;
