import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function DefinitionCard(props) {
    const { title, subtitle, description, rows, note } = props;
    return (
        <Card sx={{ maxWidth: 550 }}>
            <CardContent sx={{ padding: 1 }}>
                <Typography variant="h4">
                    {title}
                </Typography>
                <Typography variant="h6">
                    {subtitle}
                </Typography>
                <Typography variant="body1" align="justify">
                    {description}
                </Typography>
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ maxWidth: 550 }} aria-label="simple table">
                        <TableBody>
                            {rows.map((row, i) => (
                                <TableRow
                                    key={`card-row-${i}`}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                    className="card__row-color"
                                >
                                    <TableCell component="th" scope="row">
                                        {row[0]}
                                    </TableCell>
                                    <TableCell align="justify">
                                        {row[1]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {note &&
                    <div className="card__note">
                        <br />
                        <strong><em>Note: </em></strong>
                        {note}
                    </div>
                }
            </CardContent>
        </Card>
    )
}