import React, { useState, useEffect } from 'react';
import styles from "./mainnav.module.scss"
import { NavLink } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import InlineHelp from "../InlineHelp/InlineHelp";
import AuthenticationButton from '../../auth0/AuthenticationButton/AuthenticationButton';
import { useAuth0 } from "@auth0/auth0-react";
import { styled, ThemeProvider } from '@mui/material/styles';
import customTheme from '../../utils/theme'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { MyProfile } from '../MyProfile/MyProfile';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 'none',
    background: 'transparent',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ color: '#fff', fontSize: '1rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    color: '#fff',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    borderTop: 'none',
    '&.Mui-expanded': {
        background: theme.color.primary_dark
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const MenuWrapper = styled('ul')(({ theme }) => ({
    background: theme.color.primary_dark,
    listStyleType: 'none',
    margin: 0,
    padding: 0,
}));

const MainNav = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [scroll, setScroll] = useState(false);
    const menu_toggle = () => setMenuActive(!menuActive);
    const { user } = useAuth0();
    const enabledShare = user ? user[`${process.env.REACT_APP_AUTH0_KEY}/district`].enable_share : false;
    const [expanded, setExpanded] = React.useState(null);

    useEffect(() => {
        // Menu button behavior on scroll.
        window.addEventListener('scroll', () => {
            let windowPosition = window.scrollY;
            if (windowPosition > 58) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        });
    }, []);

    const handleChange = (active) => (event, newExpanded) => {
        setExpanded(newExpanded ? active : false);
    };

    return (
      <ThemeProvider theme={customTheme}>
        <>
          {menuActive && <div className="main-nav__overlay"></div>}
          <button
            className={`button__toggle-menu ${menuActive ? "close" : "open"} ${
              scroll ? "on-scroll" : "on-top"
            }`}
            onClick={menu_toggle}
          >
            {menuActive ? (
              <CloseRoundedIcon
                className={"toggle-menu__icon"}
                style={{ fontSize: 35, color: "#FFF" }}
              />
            ) : (
              <MenuRoundedIcon
                className={`toggle-menu__icon`}
                style={{ fontSize: 50 }}
              />
            )}
          </button>
          <nav
            className={`main-nav__menu ${
              menuActive ? "menu--active" : "menu--hide"
            }`}
          >
            {user && ["api_group_admin", "api_super_admin", "api_user"].includes(
              user[`${process.env.REACT_APP_AUTH0_KEY}/role`]
            ) && (
              <>
                <div className={styles.myProfile}>
                  <MyProfile />
                </div>
                <Accordion
                  expanded={expanded === "boards"}
                  onChange={handleChange("boards")}
                >
                  <AccordionSummary
                    aria-controls="boards-content"
                    id="boards-header"
                  >
                    Boards
                  </AccordionSummary>
                  <MenuWrapper>
                    <li>
                      <NavLink
                        to="/insights"
                        className="main-nav__link"
                        activeClassName="selected"
                        onClick={menu_toggle}
                        exact
                      >
                        District Insights
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/explorer"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        District Explorer
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/election-history"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        Election History
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/data-viewer"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        Data Viewer
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/glossary"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        Glossary
                      </NavLink>
                    </li>
                  </MenuWrapper>
                </Accordion>
              </>
            )}
            {user && ["api_group_admin", "api_super_admin", "api_user"].includes(
              user[`${process.env.REACT_APP_AUTH0_KEY}/role`]
            ) &&
              (enabledShare ? (
                <Accordion
                  expanded={expanded === "saveShare"}
                  onChange={handleChange("saveShare")}
                >
                  <AccordionSummary
                    aria-controls="admin-content"
                    id="admin-header"
                  >
                    Save & Share
                  </AccordionSummary>
                  <MenuWrapper>
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/custom-groups"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        Custom Group Editor
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/custom-boards"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        Custom Boards
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/crud/custom-groups"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        Custom Groups
                      </NavLink>
                    </li>
                  </MenuWrapper>
                </Accordion>
              ) : (
                  <NavLink
                    className="main-nav__link"
                    to="/custom-groups"
                    onClick={menu_toggle}
                    activeClassName="selected"
                  >
                    Custom Group Editor
                  </NavLink>
              ))}
            {(user && ["api_super_admin", "api_group_admin"].includes(
              user[`${process.env.REACT_APP_AUTH0_KEY}/role`]
            )) && (
              <Accordion
                expanded={expanded === "admin"}
                onChange={handleChange("admin")}
              >
                <AccordionSummary
                  aria-controls="admin-content"
                  id="admin-header"
                >
                  Admin
                </AccordionSummary>
                <MenuWrapper>
                  {user && ["api_super_admin","api_group_admin"].includes(
                    user[`${process.env.REACT_APP_AUTH0_KEY}/role`]
                  ) && (
                    <li>
                      <NavLink
                        className="main-nav__link"
                        to="/accounts"
                        onClick={menu_toggle}
                        activeClassName="selected"
                      >
                        Accounts
                      </NavLink>
                    </li>
                  )}
                  {user && ["api_group_admin", "api_super_admin"].includes(
                    user[`${process.env.REACT_APP_AUTH0_KEY}/role`]
                  ) && (
                    <>
                      <li>
                        <NavLink
                          className="main-nav__link"
                          to="/users"
                          onClick={menu_toggle}
                          activeClassName="selected"
                        >
                          Search Users and Licenses
                        </NavLink>
                      </li>
                    </>
                  )}
                </MenuWrapper>
              </Accordion>
            )}
            {user && ["api_group_admin", "api_super_admin", "api_user"].includes(
              user[`${process.env.REACT_APP_AUTH0_KEY}/role`]
            ) && <InlineHelp />}
            <AuthenticationButton />
          </nav>
        </>
      </ThemeProvider>
    );
}

export default MainNav;


