import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { buildTooltip } from "../../utils/helpsers";
import logoSignature from '../../IB_Signature.png';
import './radar-chart.scss';

const RadarChart = (props) => {
  let categories = (props.data[0].definition).map(category => category.name);
  const [showRawDataTable, setShowRawDataTable] = useState(false);
  let reloadTooltips = false;

  const placeTooltips = () => {
    let infoIcons = document.querySelectorAll('.radar-info-icon');
    Array.from(infoIcons).forEach((el) => {
      let infoIcon = document.getElementById(el.id);
      let desId = el.id + '-desc';
      let description = document.getElementById(desId);
      buildTooltip({ infoIcon: infoIcon, info: description.innerHTML });
    });
  }

  useEffect(() => {
    let legend = document.querySelector('.highcharts--radar-chart .highcharts-legend');
    legend.addEventListener('click', () => {
      let labels = document.querySelector('.highcharts--radar-chart div.highcharts-radial-axis-labels')
      // If labels are removed, prepare to refresh tooltips.
      if(!labels) return reloadTooltips = true;
      if(labels && reloadTooltips) {
        placeTooltips();
        reloadTooltips = false;
      }
    });
    // Set tooltips on load.
    placeTooltips();
  })

  let logoCredit;
  const polarChartData = {
    chart: {
      polar: true,
      type: 'line',
      backgroundColor: 'rgba(0,0,0,0)',
      style: {
        fontFamily: ['Titillium Web', 'sans-serif'],
      },
      events: {
        load: function () {
          if (this.options.chart.forExport) {
            this.renderer.image(`${logoSignature}`, 10, 15, 100, 15).add();
          }
        },
        beforePrint: function () {
          logoCredit = this.renderer.image(`${logoSignature}`, 10, 15, 100, 15).add();
          this.print();
        },
        afterPrint: function () {
          logoCredit.element.remove();
        }
      },
      className: 'highcharts--radar-chart'
    },
    title: {
      text: '<b>' + props.title + '</b>',
    },
    pane: {
      size: '80%'
    },
    xAxis: {
      categories: categories,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        formatter: function () {
          if (typeof this.value === 'string') {
            let definition = (props.data[0].definition).filter(el => el.name === this.value)
            let labelId = ((this.value).toString()).replace(/[^A-Z0-9]+/ig, "_");
            return `<span>${this.value}</span><span class="radar-info-icon" id="${labelId}">ⓘ</span><span id="${labelId}-desc" style="display: none">${definition[0].value}</span>`
          }
        },
        useHTML: true
      },
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      labels: {
        formatter: function () {
          if (this.value !== 0) {
            return this.value;
          }
        }
      },
      lineWidth: 0,
      min: 0,
      max: 1
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOver: function () {
              const labels = document.querySelector('.highcharts--radar-chart div.highcharts-radial-axis-labels');
              labels.classList.add('to-back');
            },
            mouseOut: function () {
              const labels = document.querySelector('.highcharts--radar-chart div.highcharts-radial-axis-labels');
              labels.classList.remove('to-back');
            }
          }
        },
      }
    },
    tooltip: {
      shared: true,
      pointFormat: '<span style="color:#222"><b><span style="color: #444">{series.name}:</span> {point.value}</b><br/>',
      valueDecimals: 2,
      backgroundColor: '#FFF',
      hideDelay: 0,
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      floating: false,
    },
    series: [{
      showInLegend: true,
      name: props.referenceDistrict,
      data: props.data[0].data,
      pointPlacement: 'on',
      color: '#773dbd',
      marker: {
        fillColor: '#f5b335',
      }
    },
    {
      showInLegend: true,
      name: props.groupName,
      data: props.data[0].data_avg,
      pointPlacement: 'on',
      color: '#00D7BE',
      marker: {
        fillColor: '#F5B335',
      }
    },
    {
      showInLegend: true,
      name: 'Ohio Best Performance',
      data: props.data[0].data_max,
      pointPlacement: 'on',
      color: '#af96dc',
      marker: {
        fillColor: '#F5B335',
      }
    },
    {
      showInLegend: true,
      name: 'Ohio Worst Performance',
      data: props.data[0].data_min,
      color: '#af96dc',
      marker: {
        fillColor: '#F5B335',
      }
    },
    {
      showInLegend: true,
      name: 'OH Avg Performance',
      data: props.data[0].data_global_avg,
      pointPlacement: 'on',
      color: '#ff6559',
      marker: {
        fillColor: '#F5B335',
      }
    }],
    exporting: {
      enabled: false,
      tableCaption: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: PDF in ${props.title}`,
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: CSV in ${props.title}`,
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsights",
                  `Downloaded: XLS in ${props.title}`,
                ]);
                this.downloadXLS();
              },
            },
            {
              separator: true,
            },
            {
              text: "Raw Data Table",
              onclick: function() {
                                
                if(showRawDataTable)
                {
                  setShowRawDataTable(!showRawDataTable);
                  this.hideData();
                  
                } else {
                  setShowRawDataTable(!showRawDataTable);
                  this.viewData()
                }

                window._paq.push([
                  "trackEvent",
                  "Board DistrictInsight",
                  `Opened the Raw Data Table`,
                ]);
              }          
            }
          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      }
    }
  };

  return (
    props.data && (
      <HighchartsReact
        highcharts={Highcharts}
        options={polarChartData}
      />
    )
  )
}
export default React.memo(RadarChart);
