import React, { useEffect, useState, useCallback } from 'react';
import LayoutPage from "../../layout/LayoutPage";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import InteractiveFile from '../../components/InteractiveFile/InteractiveFile';
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import UsersByAccount from "./UsersByAccount";
import GroupIcon from '@mui/icons-material/Group';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LicensesByAccount from "./LicensesByAccount";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import { allowedDomain } from '../../utils/helpsers';

const Groups = () => {
  const [group, setGroup] = useState([]);
  const [activeGroup, setActiveGroup] = useState();
  const [districts, setDistricts] = useState([]);
  const [roleTotals, setRoleTotals] = useState([]);
  const [typeTotals, setTypeTotals] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const { user } = useAuth0();
  const history = useHistory();

  if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] !== 'api_super_admin' && user[`${process.env.REACT_APP_AUTH0_KEY}/role`] !== 'api_group_admin') {
    history.push('/AccessDenied');
  }

  let columns = [
    {
      title: 'Name', field: 'name', validate: rowData => {
        if (rowData.name) {
          return Boolean((rowData.name).length > 2)
        } else {
          return false;
        }
      }
    },
    { title: 'Description', field: 'description' },
    { title: 'Allowed Domains', field: 'allowed_domains', validate: rowData => allowedDomain(rowData) ? true : 'Domain name is not valid.' },
    {
      title: 'Allowed IRN File', field: 'allowed_irns',
      render: props => {
        return (
          <InteractiveFile action='Download' rowProps={props} defaultDistricts={districts} dataEndpoint={props} field='allowed_irns' nameField={props.name} />
        );
      },
      editComponent: props => {
        return (
          <>
            <InteractiveFile action='Upload' rowProps={props} actionUrl={`${process.env.REACT_APP_API_URL}/groups`} defaultDistricts={districts} dataEndpoint={props.rowData} field='allowed_irns' identifier='id' />
          </>
        );
      }
    },
    {
      title: 'Licenses (Total/Full/Restricted)', editable: 'never', render: rowData => {
        let totalTypeRow = typeTotals.find((element, index) => {
          let valid = false;

          if (element.group_id === rowData.id) {
            valid = true;
          }

          return valid;
        });

        let total;
        let full;
        let restricted;

        if (totalTypeRow) {
          total = totalTypeRow.data[0].total ? totalTypeRow.data[0].total : 0;
          full = totalTypeRow.data[0].full_access ? totalTypeRow.data[0].full_access : 0;
          restricted = totalTypeRow.data[0].district_access ? totalTypeRow.data[0].district_access : 0;
        }

        return <>
          <p><b>{total} | {full} | {restricted}</b></p>
        </>;
      }
    },
    {
      title: 'Users (Total/Admin/Regular)', editable: 'never', render: rowData => {
        let totalRoleRow = roleTotals.find((element, index) => {
          let valid = false;
          
          if (element.group_id === rowData.id) {
            valid = true;
          }

          return valid;
        });

        let total;
        let admin;
        let regular;

        if (totalRoleRow) {
          total = totalRoleRow.data[0].total ? totalRoleRow.data[0].total : 0;
          admin = totalRoleRow.data[0].group_admin ? totalRoleRow.data[0].group_admin : 0;
          regular = totalRoleRow.data[0].api_user ? totalRoleRow.data[0].api_user : 0;
        }

        return <>
          <p><b>{total} | {admin} | {regular}</b></p>
        </>;
      }
    },
    { title: 'Is sharing enabled?', field: 'enable_share', type: 'boolean', hidden: true },
    { title: 'Created At', field: 'created_at', sorting: true, defaultSort: 'desc', editable: 'never', type: 'date',
      render: rowData => {
        let date = new Date(rowData.created_at);
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return month + "/" + day + "/" + year;

      } 
   },
  ];

  const getGroups = useCallback(() => {
    if (activeGroup) {
      const roleCondition = user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === "api_group_admin" ? typeof activeGroup === 'number' ? `?id=eq.${activeGroup}` : '' : '';

      axios.get(`${process.env.REACT_APP_API_URL}/groups${roleCondition}`, {
        headers: {
          'ngrok-skip-browser-warning': true  
        }
      })
        .then(res => {
          const groups = res.data;
          setRoleTotals([]);
          setTypeTotals([]);

          groups.forEach(element => {
            getRoleTotal(element.id)
              .then((data) => {
                let groupTotal = { 'group_id': element.id, data: data };
                setRoleTotals(oldTotals => [...oldTotals, groupTotal]);
              });
            getTypeTotal(element.id)
              .then((data) => {
                let groupTotal = { 'group_id': element.id, data: data };
                setTypeTotals(oldTotals => [...oldTotals, groupTotal]);
              });
          });

          setGroup(groups);
        });
    }
  }, [user, activeGroup]);

  const getRoleTotal = (id) => {
    let url = `${process.env.REACT_APP_API_URL}/rpc/role_counter?_groupid=` + id;
    return axios.get(url, { headers: {
      'ngrok-skip-browser-warning': true  
    }}).then(response => response.data);
  }

  const getTypeTotal = async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/rpc/type_counter?_groupid=` + id;
    return await axios.get(url, { headers: {
      'ngrok-skip-browser-warning': true  
    }}).then(response => response.data);
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/users?email=eq.${user.email}`, {
      headers: {
        'ngrok-skip-browser-warning': true  
      }})
      .then(res => {
        setActiveGroup(res.data[0].group_id);
      });
    getGroups();
    axios.get(`${process.env.REACT_APP_API_URL}/hvd_district`, {
      headers: {
        'ngrok-skip-browser-warning': true  
      }
    })
      .then(res => {
        const districts = res.data;
        setDistricts(districts);
      });
  }, [getGroups, user]);

  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve) => {
    //validating the data inputs
    let errorList = []

    if (errorList.length < 1) {
      axios.put(`${process.env.REACT_APP_API_URL}/groups?id=eq.${newData.id}`, newData, {
        headers: {
          'ngrok-skip-browser-warning': true
        }
      })
        .then(response => {
          getGroups();
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Update Failed! ${error.response.data.details}`]);
          }
          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  }

  //function for deleting a row
  const handleRowDelete = (oldData, resolve) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/groups?id=eq.${oldData.id}`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }})
      .then(response => {
        getGroups();
        resolve();
      })
      .catch(error => {
        if (error.response) {
          // Request made and server responded
          setErrorMessages([`Delete failed! ${error.response.data.details}`]);
        }
        setIserror(true);
        resolve();
      })
  }

  //function for adding a new row to the table
  const handleRowAdd = (newData, resolve) => {
    //validating the data inputs
    let errorList = []

    if (errorList.length < 1) {
      axios.post(`${process.env.REACT_APP_API_URL}/groups`, newData,{
        headers: {
          'ngrok-skip-browser-warning': true  
        }
      })
        .then(response => {
          getGroups();
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Cannot add data. ${error.response.data.details}`]);
          }
          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
  }

  return (
    <LayoutPage
      pageTitle='Accounts'
      description='Create, update, and delete accounts.'
      customClass='page-crud'
    >
      <div className='accounts-table'>
        <MaterialTable
          title="Accounts"
          columns={columns}
          data={group}
          detailPanel={[
            {
              icon: GroupOutlinedIcon,
              openIcon: GroupIcon,
              tooltip: 'Show Users',

              render: data => {
                return (
                  <UsersByAccount groupID={data.rowData.id} groupName={data.rowData.name} />
                )
              },
            },
            {
              icon: KeyOutlinedIcon,
              tooltip: 'Show Licences',
              render: data => {
                return (
                  <LicensesByAccount groupID={data.rowData.id} groupName={data.rowData.name} />
                )
              },
            },
          ]}
          components={{
            Toolbar: props => (
              <MTableToolbar {...props} classes={{ root: "board-toolbar" }} />
            ),
          }}
          icons={{
            Add: props => <div className="Table-button"><AddIcon {...props} /> Add Account</div>,
          }}
          options={{
            headerStyle: { borderBottomColor: 'rgb(102, 16, 242)', borderBottomWidth: '3px' },
            actionsColumnIndex: 0,
            toolbarButtonAlignment: "left",
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          editable={
            user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_super_admin' && {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  handleRowUpdate(newData, oldData, resolve);
                }),
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  handleRowAdd(newData, resolve)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  handleRowDelete(oldData, resolve)
                }),
            }}
        />
      </div>

      <div>
        {iserror &&
          <Alert severity="error">
            <AlertTitle>ERROR</AlertTitle>
            {errorMessages.map((msg, i) => {
              return <div key={i}>{msg}</div>
            })}
          </Alert>
        }
      </div>
      <ReportButtons boardType={'Groups'} />
    </LayoutPage>
  );
}

export default Groups;
