import React, { useCallback, useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { getGroupData, getToken, groupTypes, yearList, simpleFetch, fetchById } from "../../utils/helpsers";
import useQuery from "../../utils/userLocation";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { styled, ThemeProvider } from '@mui/material/styles';
import customTheme from '../../utils/theme';

function BoardBuilder({ group = false, year = false, boardData, onSubmit, existingBoard }) {
    const [districts, setDistricts] = useState([]);
    const [referenceDistrict, setReferenceDistrict] = useState(null);
    const [groupTypeOptions, setGroupTypesOptions] = useState([]);
    const [groupType, setGroupType] = useState(null);
    const [groups, setGroups] = useState([]);
    const [comparisonGroup, setComparisonGroup] = useState(null);
    const [comparisonYear, setComparisonYear] = useState(null);

    const [irns, setIrns] = useState();
    const [groupCount, setGroupCount] = useState();
    const [fixedSelection, setFixedSelection] = useState(false);
    
    const { user } = useAuth0();

    let rootClass = "board-builder";
    let query = useQuery();

    // Set districts allowed based on storage data
    useEffect(()=> {
        if (localStorage.getItem('allowed_districts')) {
            let districts = JSON.parse(localStorage.allowed_districts);
            setDistricts(districts);
            let conditionalDistrict = user[`${process.env.REACT_APP_AUTH0_KEY}/district`].sessionType === 'district-access' ? districts : districts[0];
            setReferenceDistrict(conditionalDistrict)
        } else {
            // No district saved on storage, request district.
            let userEmail = user.email;
            simpleFetch(`${process.env.REACT_APP_API_URL}/rpc/hvd_allowed_districts?_email=${userEmail}`)
                .then(data => {
                    let conditionalDistrict;
                    if(user[`${process.env.REACT_APP_AUTH0_KEY}/district`].sessionType === 'district-access') {
                        localStorage.setItem('allowed_districts', JSON.stringify(data[0]));
                        conditionalDistrict = [data[0]];
                    } else {
                        localStorage.setItem('allowed_districts', JSON.stringify(data[0].sort(function(a, b) {
                            return a.label.localeCompare(b.label);
                        })));
                        conditionalDistrict = data[0];
                    }

                    setDistricts(conditionalDistrict);
                    setReferenceDistrict(conditionalDistrict);
                });
        }

        // Get comparison group types.
        setGroupTypesOptions(query.get('shared') ? [{ label: 'Custom Boards', value: 'custom_boards', selected: true }] : groupTypes);
    },[query, user])

    // If fixed configs, set board filters.
    useEffect(() => {
      const getQueryVariable = (variable) => {
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split("=");
          if (pair[0] === variable) {
            return pair[1];
          }
        }
        return false;
      }
      if (existingBoard){
        let savedId = getQueryVariable('shared');
        simpleFetch(`${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.` + savedId)  
            .then(data => {
                let savedData = Object.values(data[0].data)[0]
                setReferenceDistrict(savedData.referenceDistrict);
                setGroupType(savedData.groupType);
                setComparisonGroup(savedData.groupInfo);
                setComparisonYear(savedData.year);
                setFixedSelection(false);
            })
    }
   else if (localStorage.getItem('fixedSelection')) {
            let fixedSelection = JSON.parse(localStorage.fixedSelection);
            setReferenceDistrict(fixedSelection?.referenceDistrict);
            setGroupType(fixedSelection?.groupType);
            setComparisonGroup(fixedSelection?.groupInfo);
            setComparisonYear(fixedSelection?.year);
            setFixedSelection(true);
        }
    }, [query, user, existingBoard])

    useEffect(() => {
        if (groups.length > 0 && groupType && comparisonGroup) {
            let groupId = comparisonGroup.label;
            let urlGroupSelected = `${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&label=eq.`;

            if (groupType.value === 'Enrollment+Brackets') {
                groupId = comparisonGroup.value;
                urlGroupSelected = `${process.env.REACT_APP_API_URL}/hvd_group_selected?select=count,label,value,irns&value=eq.`;
            }

            // Getting group count.
            if (groupType.value === 'custom_groups' && comparisonGroup.custom_ranges) {
                let varKey = (Object.keys(comparisonGroup.custom_ranges).length);
                let varData = comparisonGroup.custom_ranges[`var${varKey}`];
                setIrns([...varData.irns]);
                setGroupCount(varData.districtsCount);
            } else if (groupType.value === 'custom_boards') {
                setGroupCount(comparisonGroup.data.groupInfo.count);
                setIrns(comparisonGroup.data.irns);
                setComparisonYear(comparisonGroup.data.year);
            }
            else {
                // @TODO BackEnd must update the group ID label to remove the 's.

                groupId = groupId === "All Ohio School Districts" ? "All Ohio's School Districts" : groupId;
                if (groups.some(group => typeof groupId === 'number' ? group.value === groupId : group.label === groupId)) {
                    fetchById(urlGroupSelected, groupId, getToken())
                        .then(data => {
                            if (data[0]) {
                                setIrns(data[0].irns);
                                setGroupCount(data[0].count)
                            }
                        });
                } else {
                    setComparisonGroup(null);
                }
            }
        }
    }, [groups, groupType, comparisonGroup, comparisonYear]);

    const getComparisonGroup = useCallback((group, districtInfo) => {
        let groupId = group.value;
        setComparisonGroup(null);

        if (groupId === 'All') {
            setComparisonGroup({ label: "All Ohio School Districts", value: 1 });
        } else if (groupId === 'custom_groups') {
            simpleFetch(`${process.env.REACT_APP_API_URL}/custom_groups`, getToken())
                .then((customGroups) => {
                    const availableGroups = customGroups.filter(group => {
                        let valid = group['created_by'] === user.email ? true : false;

                        if (group['available_users_share']) {
                            if (group['available_users_share'].filter(userAvailable => userAvailable.value === user.email).length > 0) {
                                valid = true;
                            }
                        }

                        return valid;
                    });

                    setGroups(availableGroups);
                });
        } else {
            setComparisonGroup(null);

            // Data to fill group selector field.
            getGroupData(`${process.env.REACT_APP_API_URL}/rpc/hvd_group_name?_irn=` + districtInfo.value + "&_reference=" + districtInfo.label + "&_group_type=", groupId, setGroups, getToken());
            if (localStorage.getItem('fixedSelection')) {
                let fixedSelection = JSON.parse(localStorage.fixedSelection);
                setComparisonGroup(fixedSelection?.groupInfo);
            }
        }
    }, [user])

    useEffect(() => {
        if (referenceDistrict && groupType) return getComparisonGroup(groupType, referenceDistrict);
    }, [referenceDistrict, groupType, getComparisonGroup])

    const builderData = {
        referenceDistrict,
        irns,
        groupType,
        groupTypeOptions,
        groupCount,
        groupInfo: comparisonGroup,
        year: comparisonYear,
    };

    const SubmitButton = styled(Button)(({ theme }) => ({
        backgroundColor: theme.color.secondary,
        fontWeight: 'bold',
        marginTop: 10,
        '&:hover': {
            backgroundColor: theme.color.secondary,
        },
    }));

    const handleChange = (event) => {
        setFixedSelection(event.target.checked);
        if (event.target.checked) {
            return localStorage.setItem('fixedSelection', JSON.stringify(builderData))
        } else  {
            return localStorage.removeItem('fixedSelection')
        }
    };

    return (
      <form
        className={rootClass}
        onSubmit={(e) => {
          e.preventDefault();
          fixedSelection &&
            localStorage.setItem("fixedSelection", JSON.stringify(builderData));
          boardData && boardData(builderData);
          onSubmit && onSubmit();
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={0}>
          <Grid item xs={12}>
              <FormGroup>
              <FormControlLabel
              disabled={existingBoard ? true : false}
                control={
                  <Switch checked={fixedSelection} onChange={handleChange} />
                }
                label="Fix Selection"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            {districts.length > 1 ? (
              <Autocomplete
                id="referenceDistrict"
                value={referenceDistrict}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                options={districts}
                fullWidth={true}
                onChange={(event, newValue) => {
                  setReferenceDistrict(newValue);
                  // Matomo code.
                  if (newValue) {
                    window._paq.push([
                      "trackEvent",
                      "Board InsightBoard",
                      "Clicked: Reference Distric",
                      `Selected: ${newValue.label}`,
                    ]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Reference district"
                    required={true}
                  />
                )}
              />
            ) : (
             <>
                 <div>{referenceDistrict ? referenceDistrict.label : "Loading"}</div>
             </>
            )}
          </Grid>
          {((group && referenceDistrict) ||
            window.localStorage.getItem("session-type") ===
              "district-access") && (
            <Grid item xs={12}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="groupType"
                    value={groupType}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    options={groupTypes}
                    fullWidth={true}
                    onChange={(event, newValue) => {
                      setGroupType(newValue);
                      // Matomo code.
                      if (newValue) {
                        window._paq.push([
                          "trackEvent",
                          "Board InsightBoard",
                          "Clicked: Comparison group",
                          `Selected: ${newValue.label}`,
                        ]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Comparison group"
                        required={true}
                      />
                    )}
                  />
                </Grid>
                {groupType && groupType.value !== "All" && (
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="comparisonGroup"
                      value={comparisonGroup}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      options={groups}
                      fullWidth={true}
                      onChange={(event, newValue) => {
                        setComparisonGroup(newValue);

                        if (newValue) {
                          window._paq.push([
                            "trackEvent",
                            "Board InsightBoard",
                            `Clicked: ${groupType.label}`,
                            `Selected: ${newValue.label}`,
                          ]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={groupType.label}
                          required={true}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {year && (
            <Grid item xs={12}>
              <Autocomplete
                id="year"
                value={comparisonYear}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                options={yearList}
                fullWidth={true}
                onChange={(event, newValue) => {
                  setComparisonYear(newValue);
                  if (newValue) {
                    window._paq.push([
                      "trackEvent",
                      "Board InsightBoard",
                      "Clicked: Year",
                      `Selected: ${newValue.label}`,
                    ]);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"Year"} required={true} />
                )}
              />
            </Grid>
          )}
        </Grid>
        <div className={"actions"}>
          <ThemeProvider theme={customTheme}>
            <SubmitButton
              onClick={() => window._paq.push(["trackGoal", 6])}
              variant="contained"
              color="primary"
              type="submit"
            >
              Build Board
            </SubmitButton>
          </ThemeProvider>
        </div>
      </form>
    );
}

export default BoardBuilder;
