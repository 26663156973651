import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import parallel from 'highcharts/modules/parallel-coordinates';
import logoSignature from '../../IB_Signature.png';

// Load Highcharts module
parallel(Highcharts);

const ParallelChart = ({ data, rangeData, referenceDistrictId }) => {

  Highcharts.setOptions({
    lang: {
      numericSymbols: [' K ', ' M ', ' B ']
    }
  });

  let logoCredit;
  const parallelData = {
    chart: {
      type: 'spline',
      backgroundColor: 'rgba(0,0,0,0)',
      parallelCoordinates: true,
      parallelAxes: {
        lineWidth: 2
      },
      events: {
        load: function () {
          if (this.options.chart.forExport) {
            this.renderer.image(`${logoSignature}`, 10, 375, 100, 20).add();
          }
        },
        beforePrint: function () {
          logoCredit = this.renderer.image(`${logoSignature}`, 10, 375, 100, 20).add();
          this.print();
        },
        afterPrint: function () {
          logoCredit.element.remove();
        }
      },
    },
    title: {
      text: 'Custom Group set'
    },
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        states: {
          hover: {
            halo: {
              size: 0
            }
          }
        },
        events: {
          mouseOver: function () {
            this.group.toFront();
          }
        }
      }
    },
    tooltip: {
      pointFormat: '<span style="color:{point.color}">\u25CF</span>' +
        '{series.name}: <b>{point.formatted}</b><br/>',
      backgroundColor: '#FFF',
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board CustomGroup",
                  "Downloaded: PDF in Parallel chart",
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board CustomGroup",
                  "Downloaded: CSV in Parallel chart",
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board CustomGroup",
                  "Downloaded: XLS in Parallel chart",
                ]);
                this.downloadXLS();
              },
            }
          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      }
    },
    xAxis: {
      categories: data.categories,
      offset: 10
    },
    yAxis: rangeData.map(item => {
      item = { ...item, endOnTick: false, maxPadding: 0, startOnTick: false }
      if (item.higher_number === 'Red') {
        return { ...item, reversed: true }
      } else {
        return { ...item, reversed: false }
      }
    }
    ),
    colors: ['rgba(140, 101, 205, 0.4)'],
    series: data.data.map(function (set, i) {

      if (data.irn[i] === referenceDistrictId) {
        return {
          states: {
            inactive: {
              opacity: 1
            }
          },
          name: data.district[i],
          data: set,
          color: '#f5b335',
          zIndex: 2,
          shadow: false
        };
      }

      return {
        name: data.district[i],
        data: set,
        shadow: false
      };
    })
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={parallelData}
    />
  )
}

export default ParallelChart;
