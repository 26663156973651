import React, { useEffect, useRef, useState } from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './saveGroup.module.scss';
import { useAuth0 } from "@auth0/auth0-react";
import { getToken } from '../../utils/helpsers';

import Button from '@mui/material/Button';
import customTheme from '../../utils/theme';
import axios from 'axios';
import { useHistory } from "react-router-dom";

/**
 * Returns a form to save groups created by the user.
 * @param {object} groupData: All the options set and required to load the board.
 * @param {boolean} layout: True to set a wrapper layout, false to set a full layout.
 * @param {string} type: The board type on lowercase. Example: insights.
 * @param {string} endpoint: The url to fetch the post.
 * @param {string} existingData: The saved data. 
 * @returns {JSX.Element}
 */
export default function SaveGroupForm({ type, groupData, endpoint, layout = false, existingData, onChange, onSave }) {

  const Text = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root, MuiOutlinedInput-input': {
      backgroundColor: '#221349',
      color: '#fff',
    },

    '& .MuiInputLabel-root': {
      color: '#fff',

      top: '0px',
    },

    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  }));

  const ActionButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.color.secondary,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: theme.color.secondary,
    },
  }));

  const history = useHistory();

  // User session metadata
  const { user } = useAuth0();
  // Message response on save group.
  const [postResponse, setPostResponse] = useState(null);
  const [lastVersion, setLastVersion] = useState(null);
  const [activeVersion, setActiveVersion] = useState(null);
  const [activeAction, setActiveAction] = useState(null);

  const [displayResponseMessage, setDisplayResponseMessage] = useState(false);
  const [repeatedError, setRepeatedError] = useState(false);

  const [boardsName, setBoardsName] = useState("");
  const [boardsRepeated, setBoardsRepeated] = useState(false);

    const groupInfo = useRef({
    name: null,
    version: null,
  })

  useEffect(() => {
    if (!lastVersion && existingData) {
      let unsorted = Object.keys(existingData.data);
      unsorted = unsorted.map(version => {
        return { 'label': version, 'value': version, "datetime": existingData.data[version]['created_at'] }
      }
      );
      let sortedDates = unsorted.sort((a, b) => a.datetime - b.datetime);

      let lastVersion = sortedDates[sortedDates.length - 1];

      groupInfo.current.version = lastVersion.value;
      setActiveVersion(lastVersion);
      setLastVersion(lastVersion);
    }
  }, [existingData, groupData, lastVersion]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/custom_boards?created_by=eq.${user.email}`, {
          headers: {
            'ngrok-skip-browser-warning': true
          }
        })
            .then((res) => {
                setBoardsName(res.data);
            });
    },[user.email])

  const handleSubmit = () => {
    let label = existingData ? existingData.label : groupInfo.current.name;
    let description = "";
    groupData['done_by'] = user.email;
    groupData['created_at'] = new Date().toISOString();

    let versionName = groupInfo.current.version;

    let bodyJson = JSON.stringify({
      "type": type,
      "label": label,
      "description": description,
      "data": { [versionName ? versionName : new Date().toISOString()]: groupData },
      "created_by": user.email
    });

    if (existingData && versionName && versionName !== "" && activeAction === 'new-version') {
      let uuid = existingData.uuid;
      bodyJson = existingData;

      if (activeAction === 'edit' && existingData.data[activeVersion.value]['done_by'] === user.email) {
        delete existingData.data[activeVersion.value];
      } else if (activeAction === 'edit' && existingData.data[activeVersion]['done_by'] !== user.email) {
        groupInfo.current.version = `${versionName} - by ${existingData.data[activeVersion]['done_by']}`;
      }

      existingData.data[versionName] = groupData;
      bodyJson.data[versionName] = groupData;

      bodyJson = JSON.stringify(bodyJson);

      fetch(`${endpoint}?uuid=eq.${uuid}`, {
        method: 'PUT',
        headers: {
          'ngrok-skip-browser-warning': true,
          'Authorization': 'Bearer ' + getToken(),
          'Content-Type': 'application/json',
        },
        body: bodyJson
      }).then(response => {
        setDisplayResponseMessage(true);
        setPostResponse(response);
        setActiveAction();
        window.location.reload();
      })
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;

      if (activeAction === 'new-copy') {
        let bodyParse = JSON.parse(bodyJson);
        bodyParse.label = groupInfo.current.name;
        bodyParse.data = { [new Date().toISOString()]: groupData };
        bodyJson = JSON.stringify(bodyParse);
      }

      let validName = true;
      for (const [key, value] of Object.entries(boardsName)) {
        if (value.label === groupInfo.current.name) {
          setRepeatedError(true);
          setBoardsRepeated(true);
          // Set false to block post.
          validName = false
          break;
        }
      }
      // If name is unique execute post.
      if(validName) {
        axios.post(endpoint, bodyJson, {
            headers: {
              'ngrok-skip-browser-warning': true,
              'Prefer': 'return=representation',
              'Content-Type': 'application/json'
            }
          }).then(response => {
          setDisplayResponseMessage(true);
          setPostResponse(response);
          setTimeout(() =>
              history.push(`?shared=${response.data[0].uuid}`), 1000)
        });
      }
    }
  }

  return (
    <div className={styles.saveGroupForm}>
      <div
        className={`${styles.saveGroupFormWrapper} layout-${
          layout ? "wrapper" : "full"
        }`}
      >
        <Grid container spacing={2} className={styles.customGroupsFormWrapper}>
          {existingData && !activeAction && (
            <Grid
              item
              xs={12}
              sm={12}
              className={`${styles.customGroupsFormItem} ${styles.doneButton}`}
              style={{ marginTop: existingData ? 15 : null }}
            >
              <ThemeProvider theme={customTheme}>
                <ActionButton
                  onClick={() => setActiveAction("new-version")}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save new version
                </ActionButton>
              </ThemeProvider>
            </Grid>
          )}
          {!activeAction && (
            <Grid
              item
              xs={12}
              sm={12}
              className={`${styles.customGroupsFormItem} ${styles.doneButton}`}
            >
              <ThemeProvider theme={customTheme}>
                <ActionButton
                  onClick={() =>
                    !existingData
                      ? setActiveAction("new-board")
                      : setActiveAction("new-copy")
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginTop: !existingData ? 15 : null }}
                >
                  {!existingData ? "Save new board" : "Make a copy"}
                </ActionButton>
              </ThemeProvider>
            </Grid>
          )}
          {(activeAction === "new-board" || activeAction === "new-copy") && (
            <Grid
              item
              xs={12}
              sm={12}
              className={`${styles.customGroupsFormItem} ${styles.doneButton}`}
            >
              <Text
                style={{ marginTop: 35 }}
                autoComplete="off"
                id="GroupName"
                fullWidth
                variant="outlined"
                placeholder="Board Name"
                required
                onChange={(e) => (groupInfo.current.name = e.target.value)}
              />
            </Grid>
          )}
          {activeAction === "new-version" && (
            <Grid
              item
              xs={12}
              sm={12}
              className={`${styles.customGroupsFormItem} ${styles.doneButton}`}
            >
              <Text
                style={{ marginTop: 35 }}
                autoComplete="off"
                id="VersionName"
                fullWidth
                variant="outlined"
                placeholder="Version Name"
                required
                defaultValue={groupInfo.current.version}
                onChange={(e) => (groupInfo.current.version = e.target.value)}
                inputProps={{ style: { color: "white" } }}
              />
            </Grid>
          )}

          {activeAction && (
            <Grid
              item
              xs={12}
              sm={12}
              className={`${styles.customGroupsFormItem} ${styles.doneButton}`}
            >
              <ThemeProvider theme={customTheme}>
                <ActionButton
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </ActionButton>
              </ThemeProvider>
            </Grid>
          )}
        </Grid>
      </div>
      {postResponse && (
        <Snackbar
          className={styles.saveMessage}
          open={displayResponseMessage}
          autoHideDuration={6000}
          onClose={() => setDisplayResponseMessage(false)}
          message={
            postResponse.ok === true || postResponse.status === 201 ? (
              <p className={styles.success}>
                Custom Board: <strong>{groupInfo.current.name}</strong> saved!
              </p>
            ) : (
              <p className={styles.error}>
                Something went wrong. Error{" "}
                <strong>{postResponse.status}</strong>
              </p>
            )
          }
          action={
            <div onClose={() => setDisplayResponseMessage(false)}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setDisplayResponseMessage(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      )}
      {boardsRepeated && (
        <Snackbar
          className={styles.saveMessage}
          open={repeatedError}
          autoHideDuration={6000}
          onClose={() => setRepeatedError(false)}
          message="You already used this name for a board"
          action={
            <div onClose={() => setRepeatedError(false)}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setRepeatedError(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
      )}
    </div>
  );
}
