import {
  Paper,
  Container,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";

export default function Footer() {
 const history = useHistory();

  return (
    <Paper
      sx={{
        width: "100%",
        background: "#E1D7F0",
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: "1",
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            position: "relative",
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="caption" color="initial">
            <Button
              onClick={() => history.push("/PrivacyPolicy")}
              variant="text"
              sx={{ color: "#221349", marginBottom: "0", marginTop: "8px" }}
            >
              Privacy Policy
            </Button>
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}
