import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';

const FormSelect = ({ options, id, label, onChange, value, styles, defaultValue, isMultiple = false }) => {
  return (
    <>
      {
        options
          ?
          <>
            {
              isMultiple ?
                <Autocomplete
                  multiple
                  id={id}
                  label={label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  defaultValue={defaultValue ? defaultValue : undefined}
                  value={value ? value : undefined}
                  options={options ? options : undefined}
                  fullWidth={true}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) =>
                    <TextField {...params} label="" />
                  } /> :
                <Autocomplete
                  id={id}
                  label={label}
                  defaultValue={defaultValue ? defaultValue : undefined}
                  value={value ? value : undefined}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={options ? options : undefined}
                  fullWidth={true}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) =>
                    <TextField {...params} label="" />
                  } />
            }

          </>
          : <CircularProgress value={'Loading Data...'} height={30} width={30} />
      }
    </>
  )
}

export default FormSelect
