import React, { useEffect, useState, useCallback } from 'react';
//import LayoutPage from "../../layout/LayoutPage";
import Grid from "@mui/material/Grid";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getToken } from '../../utils/helpsers';
import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from '@mui/icons-material/Add';
import Select from '../../components/Select/Select';
import InteractiveFile from '../../components/InteractiveFile/InteractiveFile';

const Licenses = ({ groupID, groupName }) => {
  const [licenses, setLicenses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [authToken, setAuthToken] = useState([]);

  const { user } = useAuth0();

  let columns = [];

  let optTypes = [
    { 'value': 'full-access', 'label': 'Full access' },
    { 'value': 'district-access', 'label': 'District access' },
    { 'value': 'multiple-district-access', 'label': 'Multiple district access' }
  ];

  if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_super_admin') {
    columns = [
      {
        title: 'Type', field: 'type',
        render: props => {
          let currentType = optTypes.find(type => type.value === props.type);
          return (<p>{currentType ? currentType.label : null}</p>);
        },
        editComponent: props => {
          let currentType = props.rowData.type ?? null;
          return (<>
            <Select
              options={optTypes}
              label={'Type'}
              id={'type'}
              isMultiple={false}
              defaultValue={currentType}
              onChange={e => {
                // If irns defined remove on change type.
                props.rowData.irns = null;
                if (e) return props.onChange(e.value);
              }}
            />
          </>
          );
        }, validate: rowData => Boolean(rowData.type)
      },
      {
        title: 'Account', field: 'group_id',
        initialEditValue: groupID,
        render: props => groupName,
        editComponent: props => groupName,
      },
      {
        title: 'Allowed IRN', field: 'irns',
        render: props => {
          let irnsRender;
          let currentGroup = groups.find(group => group.id === props.group_id);

          if (currentGroup) {
            if (props.type === 'district-access') {
              let label = props.irns ? props.irns.label : null;
              irnsRender = <p>{label}</p>
            }

            if (props.type === 'multiple-district-access') {
              irnsRender = <InteractiveFile action='Download' defaultDistricts={districts} dataEndpoint={props} field='irns' nameField={props.uuid} />;
            }

            if (props.type === 'full-access') {
              irnsRender = <p>All allowed districts in group</p>
            }
          } else {
            irnsRender = <><p>Not assigned to a group</p></>
          }
          return irnsRender;
        },
        editComponent: props => {
          let listIrns = [];
          let renderEdit = <></>;

          if (props.rowData.type && Object.keys(props.rowData.type).length > 0 && props.rowData.type !== 'full-access') {
            let groupRow = groups.find((element) => {
              let valid = false;

              if (parseInt(element.id) === parseInt(props.rowData.group_id)) {
                valid = true;
              }

              return valid;
            });

            if (groupRow) {
              listIrns = groupRow.allowed_irns ? groupRow.allowed_irns : districts;
            }
          }

          if (props.rowData.type) {
            if (props.rowData.type === 'district-access') {
              renderEdit = <Select options={listIrns} defaultValue={props.rowData.irns ?? null} label={'IRN'} id={'írns'} isMultiple={false} onChange={e => { props.onChange(e) }} />;
            } else if (props.rowData.type === 'multiple-district-access') {
              renderEdit = <InteractiveFile action='Upload' rowProps={props} actionUrl={`${process.env.REACT_APP_API_URL}/licenses`} defaultDistricts={listIrns} dataEndpoint={props.rowData} field='irns' identifier='uuid' />;
            }
          }

          return renderEdit;
        }, validate: rowData => {
          if (rowData.type === 'full-access') return true;
          if (rowData.irns) {
            if (rowData.type === 'district-access') return Boolean(rowData.irns);
            if (rowData.type === 'multiple-district-access') return Boolean((rowData.irns).length > 0);
          } else {
            return false
          }
        }
      },
      { title: 'Expiration Date', field: 'expired_at', editable: 'never', type: 'date', 
      render: rowData => {
        let date = new Date(rowData.expired_at);
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return month + "/" + day + "/" + year;
      } 
      },
      { title: 'Metadata (Experts only)', field: 'data' },
    ];
  } else if (user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_group_admin') {
    columns = [
      {
        title: 'Type', field: 'type',
        render: props => {
          let currentType = optTypes.find(type => type.value === props.type);
          return (<p>{currentType ? currentType.label : null}</p>);
        },
        editComponent: props => {
          let currentType = props.rowData.type ?? null;
          return (<>
            <Select
              options={optTypes}
              label={'Type'}
              id={'type'}
              isMultiple={false}
              defaultValue={currentType}
              onChange={e => {
                // If irns defined remove on change type.
                props.rowData.irns = null;
                if (e) return props.onChange(e.value);
              }}
            />
          </>
          );
        }, validate: rowData => Boolean(rowData.type)
      },
      { title: 'Account', field: 'group_id', lookup: groups.reduce((a, v) => ({ ...a, [v.id]: v.name }), {}) },
      {
        title: 'Allowed IRN', field: 'irns',
        render: props => {
          let irnsRender;
          let currentGroup = groups.find(group => group.id === props.group_id);

          if (currentGroup) {
            if (props.type === 'district-access') {
              let label = props.irns ? props.irns.label : null;
              irnsRender = <p>{label}</p>
            }

            if (props.type === 'multiple-district-access') {
              irnsRender = <InteractiveFile action='Download' defaultDistricts={districts} dataEndpoint={props} field='irns' nameField={props.uuid} />;
            }

            if (props.type === 'full-access') {
              irnsRender = <p>All allowed districts in group</p>
            }
          } else {
            irnsRender = <><p>Not assigned to a group</p></>
          }
          return irnsRender;
        },
        editComponent: props => {
          let listIrns = [];
          let renderEdit = <></>;

          if (props.rowData.type && Object.keys(props.rowData.type).length > 0 && props.rowData.type !== 'full-access') {
            let groupRow = groups.find((element) => {
              let valid = false;

              if (parseInt(element.id) === parseInt(props.rowData.group_id)) {
                valid = true;
              }

              return valid;
            });

            if (groupRow) {
              listIrns = groupRow.allowed_irns ? groupRow.allowed_irns : districts;
            }
          }

          if (props.rowData.type) {
            if (props.rowData.type === 'district-access') {
              renderEdit = <Select options={listIrns} defaultValue={props.rowData.irns ?? null} label={'IRN'} id={'írns'} isMultiple={false} onChange={e => { props.onChange(e) }} />;
            } else if (props.rowData.type === 'multiple-district-access') {
              renderEdit = <InteractiveFile action='Upload' rowProps={props} actionUrl={`${process.env.REACT_APP_API_URL}/licenses`} defaultDistricts={listIrns} dataEndpoint={props.rowData} field='irns' identifier='uuid' />;
            }
          }

          return renderEdit;
        }, validate: rowData => {
          if (rowData.type === 'full-access') return true;
          if (rowData.irns) {
            if (rowData.type === 'district-access') return Boolean(rowData.irns);
            if (rowData.type === 'multiple-district-access') return Boolean((rowData.irns).length > 0);
          } else {
            return false
          }
        }
      },
      { title: 'Expiration Date', field: 'expired_at', editable: 'never', type: 'date', detaSetting: { 'locale': 'en-US' } },
      { title: 'UUID', field: 'uuid', editable: 'never' },
    ];
  }

  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;

  const getAuthToken = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/rpc/auth0-token`, {
      headers: {
        'ngrok-skip-browser-warning': true  
      }})
      .then(res => {
        setAuthToken(res.data[0]);
      });
  }

  const getLicenses = useCallback(() => {
    let userRole = user[`${process.env.REACT_APP_AUTH0_KEY}/role`];
    axios.get(`${process.env.REACT_APP_API_URL}/licenses`, {
      headers: {
        'ngrok-skip-browser-warning': true  
      }
    })
      .then(res => {
        const license_data = res.data;
        if (userRole === 'api_super_admin') {
          return setLicenses(license_data.filter(license => license.group_id === groupID));
        }
        if (userRole === 'api_group_admin') {
          axios.get(`${process.env.REACT_APP_API_URL}/users?email=eq.${user.email}`, {
            headers: {
             'ngrok-skip-browser-warning': true   
            }
          })
            .then(res => {
              return setLicenses(license_data.filter(license => license.group_id === groupID));
            })
        }
      });
  }, [user, groupID]);

  useEffect(() => {
    getLicenses();
    getAuthToken();
    axios.get(`${process.env.REACT_APP_API_URL}/groups`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(res => {
        const groups = res.data;
        setGroups(groups);
      });
    axios.get(`${process.env.REACT_APP_API_URL}/users`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(res => {
        setUsers(res.data);
      });
    axios.get(`${process.env.REACT_APP_API_URL}/hvd_district`, {
      headers: {
        'ngrok-skip-browser-warning': true  
      }
    })
      .then(res => {
        const districts = res.data;
        setDistricts(districts);
      });
  }, [getLicenses])

  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve) => {
    //validating the data inputs
    let errorList = [];

    if (errorList.length < 1) {
      newData.irns = newData.type === 'full-access' ? null : newData.irns;

      axios.put(`${process.env.REACT_APP_API_URL}/licenses?uuid=eq.${newData.uuid}`, newData, { headers: {
        'ngrok-skip-browser-warning': true
      }})
        .then(response => {
          getLicenses();

          let currentUser = users.find(user => user.license_id === newData.uuid);

          if (currentUser) {
            axios.get(`${process.env.REACT_APP_API_URL}/rpc/create_user?_id=` + currentUser.id, {
              headers: {
                'ngrok-skip-browser-warning': true
              }
            })
              .then(res => {
                let userData = res.data[0]['app_metadata'];
                userData = { 'app_metadata': userData }

                axios.patch('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/api/v2/users/' + currentUser['user_id'], userData,
                  {
                    headers: {
                      'ngrok-skip-browser-warning': true,
                      'Authorization': `Bearer ${authToken}`,
                      'Content-Type': 'application/json'
                    }
                  });
              });
            resolve();
            setIserror(false);
            setErrorMessages([]);
          }
          resolve();
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Update Failed! ${error.response.data.details}`]);
          }

          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  }

  //function for deleting a row
  const handleRowDelete = (oldData, resolve) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/licenses?uuid=eq.${oldData.uuid}`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(response => {
        getLicenses();
        resolve();
      })
      .catch(error => {
        if (error.response) {
          // Request made and server responded
          setErrorMessages([`Delete failed! ${error.response.data.details}`]);
        }

        setIserror(true);
        resolve();
      })
  }

  //function for adding a new row to the table
  const handleRowAdd = (newData, resolve) => {
    //validating the data inputs
    let errorList = []

    if (errorList.length < 1) {
      axios.post(`${process.env.REACT_APP_API_URL}/licenses`, newData, {
        headers: {
          'ngrok-skip-browser-warning': true,
          'Prefer': 'return=representation',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          getLicenses();
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Cannot add data. ${error.response.data.details}`]);
          }
          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  }

  return (
    <>
      <div className="table-wrapper" style={{ padding: 20 }}>
        <MaterialTable
          title="Licenses"
          columns={columns}
          data={licenses}
          components={{
            Toolbar: props => (
              <MTableToolbar {...props} classes={{ root: "board-toolbar" }} />
            ),
          }}
          detailPanel={rowData => {
            let currentUser = users.find(user => user.license_id === rowData.rowData.uuid);
            let renderUser;
            if (currentUser) {
              let email = currentUser.email;
              let name = currentUser.name;
              renderUser = <Grid container style={{ padding: '10px' }}>
                <Grid item xs={3}>
                  <label>License UUID</label>
                  <p>{rowData.rowData.uuid}</p>
                </Grid>
                <Grid item xs={3}>
                  <label>User Name</label>
                  <p>{name}</p>
                </Grid>
                <Grid item xs={3}>
                  <label>User Email</label>
                  <p>{email}</p>
                </Grid>
                <Grid item xs={3}>
                  <label>License Created At</label>
                  <p>{rowData.rowData.created_at}</p>
                </Grid>
              </Grid>;
            } else {
              renderUser = <Grid container>
                <Grid item xs={4}>
                  <label>License UUID</label>
                  <p>{rowData.rowData.uuid}</p>
                </Grid>
                <Grid item xs={4}><p>No user has been assigned to this license.</p></Grid>
                <Grid item xs={4}>
                  <label>License Created At</label>
                  <p>{rowData.rowData.created_at}</p>
                </Grid>
              </Grid>
            }
            return renderUser;
          }}
          icons={{
            Add: props => <div className="Table-button"><AddIcon {...props} /> Add License</div>,
          }}
          options={{
            headerStyle: { borderBottomColor: 'rgb(102, 16, 242)', borderBottomWidth: '3px' },
            actionsColumnIndex: 0,
            toolbarButtonAlignment: "left",
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          editable={
            user[`${process.env.REACT_APP_AUTH0_KEY}/role`] === 'api_super_admin' ?
              {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    handleRowUpdate(newData, oldData, resolve);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    handleRowAdd(newData, resolve)
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve)
                  }),
              }
              : {}
          }
        />

        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
      </div>
    </>
  );
}

export default Licenses;
