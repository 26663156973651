import React from "react";
import Slider from '@mui/material/Slider';
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import styles from "./slider.module.scss";
import {moneyFormat} from "../../utils/helpsers";

/**
 * Returns range slider component.
 * @param {[int, int]} ranges: Variable min and max range.
 * @param {[int, int]} values: The min and max value number based on group selected
 * @param {function} onChange: Set the variable min/max ranges selected by the user.
 * @param {string} label: The descriptive label for the range slider
 * @param {object} markData: label, value object with reference district values related with the var.
 * @returns {JSX.Element}
 */

function RangeSlider({ ranges, values, id, unit, onChange, label, markData = null }) {
  const [value, setValue] = React.useState(values);
  const [toggle, setToggle] = React.useState(false);

  //Button styles
  const ColorButton = styled(Button)(({ theme }) => ({
    margin: "10px 0",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
  }));

  const RangeSlider = styled(Slider)(({theme}) => ({
    marginBottom: "10px",
    "&.MuiSlider-root": {
      display: "block",
      color: "#773DBD",
      margin: "0 auto 0",
      width: "calc(100% - 12px)",
      zIndex: "0"
    },
    ".MuiSlider": {
      "&-thumb[data-index='0']": {
        color: "#221349",
        background: "#fff",
        boxShadow: "0 0 5px #000",
        marginLeft: "0",
        width: "15px",
        height: "15px",
        ".MuiSlider-valueLabel": {
          opacity: ".90",
          top: "20px",
          right: "10px",
          "&::before": {
            display: "none"
          },
          ".& > span": {
            height: "auto !important",
            padding: "0 !important",
            width: "auto",
          },
          "span": {
            color: "#ffff",
            borderRadius: "4px",
            fontWeight: "bold",
            transform: "none",
          }
        }
      },
      "&-thumb[data-index='1']": {
        ".MuiSlider-valueLabel": {
          opacity: '0.9',
          top: '20px',
          left: '11px',
          "&::before": {
            display: 'none',
          },
          "& > span": {
            height: 'auto !important',
            padding: '0 !important',
            width: 'auto',
          },
          "span": {
            color: '#ffff',
            "border-radius": '4px',
            "font-weight": 'bold',
            transform: 'none',
          }
        }
      },
      '&-mark': {
        height: '8px',
        marginTop: '-3px',
        background: '#F5B335',
        width: '3px',
      },
      '&-markLabel': {
        display: 'none',
      },

      '&-thumb': {
        color: '$primary-color',
        background: '#fff',
        boxShadow: '0 0 5px #000',
        marginLeft: '0',
        width: '15px',
        height: '15px',
      },

      '&-mark:hover + .MuiSlider-markLabel': {
        display: 'block'
      },

      '&-markLabelActive': {
        background: 'rgba(0, 0, 0, 0.9)',
        color: '#fff',
        zIndex: '2',
        padding: '3px 6px',
      }
    },
    '.Mui-focusVisible': {
      '.MuiSlider-valueLabel': {
        opacity: '1'
      }
    }
  }));

  //step
  //const availableRange = value[1] - value[0];
  //const step = availableRange / 50;

  //Format
  function valueLabelFormat(value) {
    let nf = new Intl.NumberFormat('en-US');
    const roundOut = Number.isInteger(value) ? value : value.toFixed(2);

    switch (unit) {
      case "%":
        return `${roundOut}${unit}`;
      case "$":
          if (roundOut >= 1000 && roundOut < 999999) {
              return moneyFormat({val: roundOut, base: 1000, tag: 'K'})
          } else if (roundOut >= 1000000 && roundOut < 999999999) {
              return moneyFormat({val: roundOut, base: 1000000, tag: 'M'})
          } else if (roundOut >= 1000000000) {
              return moneyFormat({val: roundOut, base: 1000000000, tag: 'B'})
          } else {
              return `${unit}${roundOut}`
          }
      default:
        return nf.format(roundOut);
    }
  }

  const handleSliderChange = (event, newValue) => {
    if (newValue[0] < newValue[1]) {
      setValue(newValue);
      onChange(newValue);
    }
    window._paq.push([
      "trackEvent",
      "Board DistrictExplorer",
      `Clicked: Slider ${id} - ${newValue}`,
    ]);
  };

  const handleInputChange = (event, type) => {
    let inputValue = +event.target.value;
    let newValue =
      type === "min" ? [inputValue, value[1]] : [value[0], inputValue];
    setValue(newValue);
    onChange(newValue);
  };

  const handleBlur = (event) => {
    let inputValue = +event.target.value;
    if (
      inputValue < +ranges[0].toFixed(2) ||
      inputValue > +ranges[1].toFixed(2)
    ) {
      setValue([+ranges[0].toFixed(2), +ranges[1].toFixed(2)]);
      onChange([+ranges[0].toFixed(2), +ranges[1].toFixed(2)]);
    }
  };


  return typeof ranges[0] === "number" && typeof ranges[1] === "number" ? (
    <>
      <RangeSlider
        getAriaLabel={() => label}
        value={[+value[0].toFixed(2), +value[1].toFixed(2)]}
        onChange={handleSliderChange}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={() => `${value}`}
        min={+ranges[0].toFixed(2)}
        max={+ranges[1].toFixed(2)}
        step={0.1}
        classes={{
          root: "RangeSlider",
        }}
        marks={markData}
      />
      {toggle && (
        <div className={styles.varRangeSliderInputs} {...toggle}>
          <Input
            className={"input__min"}
            value={[+value[0].toFixed(2)]}
            margin="dense"
            onChange={(e) => handleInputChange(e, "min")}
            onBlur={handleBlur}
            inputProps={{
              step: value[0] < 1 ? 0.1 : 1,
              min: +ranges[0].toFixed(2),
              max: +ranges[1].toFixed(2),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
          <Input
            className={"input__max"}
            value={[+value[1].toFixed(2)]}
            margin="dense"
            onChange={(e) => handleInputChange(e, "max")}
            onBlur={handleBlur}
            inputProps={{
              step: value[1] < 1 ? 0.1 : 1,
              min: +ranges[0].toFixed(2),
              max: +ranges[1].toFixed(2),
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </div>
      )}
      <ColorButton
        variant="outlined"
        onClick={() => setToggle(!toggle)}
      >
        Manual Input
      </ColorButton>
    </>
  ) : (
    <div className={styles.noDataMessage}>(Data unavailable)</div>
  );
}

export default React.memo(RangeSlider);
