import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Box from "@mui/material/Box"
import CircularProgress from '@mui/material/CircularProgress';
import { buildTooltip } from "../../utils/helpsers";
import styles from "./bubblechart.module.scss";

const BubbleChart = (props) => {

  // Build X axis title based on var has definition.
  let xDescription = props.x.description;
  let xAxisTitle = xDescription
    ? `<span>${props.x.label}</span><span class=${styles.infoIcon} id="bubble-axis-x">ⓘ</span>` : props.x.label;
  // Build Y axis title based on var has definition.
  let yDescription = props.y.description;
  let yAxisTitle = yDescription
    ? `<span>${props.y.label}</span><span class=${styles.infoIcon} id="bubble-axis-y">ⓘ</span>` : props.y.label;

  useEffect(() => {
    // Set Tooltip if var description is available.
    if (xDescription) {
      let xInfoIcon = document.getElementById('bubble-axis-x');
      buildTooltip({ infoIcon: xInfoIcon, info: xDescription });
    }
    if (yDescription) {
      let yInfoIcon = document.getElementById('bubble-axis-y');
      buildTooltip({ infoIcon: yInfoIcon, info: yDescription });
    }
  });

  const BubbleData = {
    chart: {
      type: 'bubble',
      height: 620,
      backgroundColor: 'rgba(0,0,0,0)',
      plotBorderWidth: 1,
      zoomType: 'xy',
      events: {
        render: function () {
          let points = this.series[0].points;
          points.forEach((point) => {
            if (point.selected === true) {
              this.tooltip.refresh(point);
            }
          });
        },
      },
      style: {
        fontFamily: ['Titillium Web', 'sans-serif']
      },
      className: 'highcharts--scatter-chart'
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.'
      }
    },
    xAxis: {
      gridLineWidth: 1,
      title: {
        useHTML: true,
        text: xAxisTitle,
      },
      labels: {
        format: '{value}'
      },
      accessibility: {
        rangeDescription: ''
      },
      plotLines: [{
        dashStyle: 'shortdash',
        value: props.xCustomRange ? props.xCustomRange[0] : 0,
        width: props.xCustomRange ? 2 : 0,
        color: '#00D7BE'
      }, {
        dashStyle: 'shortdash',
        value: props.xCustomRange ? props.xCustomRange[1] : 0,
        width: props.xCustomRange ? 2 : 0,
        color: '#00D7BE',
      }],
    },
    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        useHTML: true,
        text: yAxisTitle
      },
      labels: {
        format: '{value}'
      },
      maxPadding: 0.2,
      accessibility: {
        rangeDescription: ''
      },
      plotLines: [{
        dashStyle: 'shortdash',
        value: props.yCustomRange ? props.yCustomRange[0] : 0,
        width: props.yCustomRange ? 2 : 0,
        color: '#00D7BE'
      }, {
        dashStyle: 'shortdash',
        value: props.yCustomRange ? props.yCustomRange[1] : 0,
        width: props.yCustomRange ? 2 : 0,
        color: '#00D7BE',
      }],
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<table>',
      pointFormat: '<tr><th colspan="2"><h3>{point.district_name}</h3></th></tr>' +
        '<tr><th>' + props.x.label + '</th><td>{point.x}</td></tr>' +
        '<tr><th>' + props.y.label + '</th><td>{point.y}</td></tr>' +
        '<tr><th>' + props.z + '</th><td>{point.z}</td></tr>',
      footerFormat: '</table>',
      followPointer: true,
      backgroundColor: '#FFF',
      hideDelay: 0,
    },
    plotOptions: {
      bubble: {
        maxSize: 60,
        marker: {
          states: {
            select: {
              lineColor: '#F5B335',
              fillColor: '#F5B335',
            }
          }
        }
      },
      series: {
        point: {
          events: {
            mouseOver: function () {
              const labels = document.querySelector('.highcharts--scatter-chart div.highcharts-yaxis');
              labels.classList.add('to-back');
            },
            mouseOut: function () {
              const labels = document.querySelector('.highcharts--scatter-chart div.highcharts-yaxis');
              labels.classList.remove('to-back');
            }
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        },
      }
    },
    exporting: {
      tableCaption: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  "Downloaded: PDF in Bubble chart",
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  "Downloaded: CSV in Bubble chart",
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  "Downloaded: XLS in Bubble chart",
                ]);
                this.downloadXLS();
              },
            },
          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      },
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!item || item instanceof Highcharts.Axis) {
            return props.x.label;
          } else {
            return item.name;
          }
        },
      },
    },
    series: [{
      data: props.options,
      color: '#4646FF',
    }]
  };

  switch (props.options) {
    case ('loading'):
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        >
          <CircularProgress size={80} />
        </Box>
      );
    case ('no-data'):
      return <div className={styles.noData}>MISSING PARAMETER</div>
    default:
      return <HighchartsReact highcharts={Highcharts} options={BubbleData} />
  }
};

export default BubbleChart;
