import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import highcharts_more from 'highcharts/highcharts-more';
import dumbbell from 'highcharts/modules/dumbbell'
import lollipop from 'highcharts/modules/lollipop'
import { buildTooltip } from "../../utils/helpsers";
import logoSignature from '../../IB_Signature.png';
import './histogram-chart.scss';

highcharts_more(Highcharts);

// Load Highcharts modules
dumbbell(Highcharts);
lollipop(Highcharts);

const HistogramChart = ({ data, title, description, id, ranges = null, customRanges = null }) => {
  let axisTitle = description ? title + '<span class="info-icon" id="' + id + '" style="font-size: 14px; fill:#773dbd"><b>ⓘ</b></span>' : title;

  const [showRawDataTable, setShowRawDataTable] = useState(false);

  // On component did mount if description build tooltip.
  useEffect(() => {
    if (description) {
      let infoIcon = document.getElementById(id);
      if (infoIcon) {
        buildTooltip({ infoIcon: infoIcon, info: description });
      }
    }
  });

  Highcharts.setOptions({
    lang: {
      numericSymbols: [' K ', ' M ', ' B ']
    }
  });

  let logoCredit;
  const Data = {
    chart: {
      type: "lollipop",
      height: 360,
      backgroundColor: 'rgba(0,0,0,0)',
      style: {
        fontFamily: ['Titillium Web', 'sans-serif']
      },
      events: {
        load: function () {
          if (this.options.chart.forExport) {
            this.renderer.image(`${logoSignature}`, 10, 350, 100, 10).add();
          }
        },
        beforePrint: function () {
          logoCredit = this.renderer.image(`${logoSignature}`, 10, 350, 100, 10).add();
          this.print();
        },
        afterPrint: function () {
          logoCredit.element.remove();
        }
      },
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: data.categories,
      crosshair: true,
      labels: {
        enabled: false,
      }
    },
    yAxis: {
      max: ranges && ranges[1] ? +(ranges[1]).toFixed(2) : null,
      min: ranges && ranges[0] ? +(ranges[0]).toFixed(2) : null,
      color: '#773DBD',
      title: {
        enabled: true,
        text: axisTitle,
      },
      plotLines: [{
        dashStyle: 'shortdash',
        value: customRanges ? customRanges[0] : 0,
        width: customRanges ? 2 : 0,
        color: '#00D7BE',
        zIndex: "10",
      }, {
        dashStyle: 'shortdash',
        value: customRanges && customRanges[1] ? customRanges[1] : 0,
        width: customRanges && customRanges[0] ? 2 : 0,
        color: '#00D7BE',
        zIndex: "10",
      }],
    },
    tooltip: {
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      backgroundColor: '#FFF',
    },
    plotOptions: {
      lollipop: {
        marker: {
          radius: 3,
          states: {
            select: {
              lineColor: '#EB9E0C',
              fillColor: '#F5B335',
              radius: 5,
            }
          }
        },
      },
    },
    exporting: {
      tableCaption: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  `Downloaded: PDF in ${title} chart`,
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  `Downloaded: CSV in ${title} chart`,
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  `Downloaded: XLS in ${title} chart`,
                ]);
                this.downloadXLS();
              },
            },
            {
              separator: true,
            },
            {
              text: "Raw Data Table",
              onclick: function() {

                if(showRawDataTable)
                {
                  setShowRawDataTable(!showRawDataTable);
                  this.hideData();
                  
                } else {
                  setShowRawDataTable(!showRawDataTable);
                  this.viewData()
                }

                window._paq.push([
                  "trackEvent",
                  "Board District Explorer",
                  `Opened the Raw Data Table`,
                ]);
              }              
            }
          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      }
    },
    series: [
      {
        showInLegend: false,
        name: "Data",
        data: data.data,
        color: '#773DBD',
      },
    ],
  };

  switch (data) {
    case ('loading'):
      return (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop:"10px" }}>
          <CircularProgress size={80}/>
        </Box>
      );
    case ('no-data'):
      return <div className="no-data">(Data unavailable)</div>
    default:
      return <HighchartsReact
        highcharts={Highcharts}
        options={Data}
      />
  }
};

export default HistogramChart;
