import React, {useContext, useEffect, useState} from 'react'
import {TokenContext} from "../../utils/Context";
import CircularProgress from '@mui/material/CircularProgress';
// styles
import './async-wrapper.scss';

const AsyncWrapper = ({label, children, endPoint, dataKey = null, dataType = null, callback}) => {
  const[errorInfo, setErrorInfo] = useState();
  const[status, setStatus] = useState();

  // User session token
  const {userToken} = useContext(TokenContext);

  useEffect(()=> {
    setStatus('loading');
    let isMounted = true;
    fetch(endPoint, { headers: { 'Authorization': 'Bearer ' + userToken }})
      .then(async response => {
        const data = await response.json();
        if(isMounted) {
          // Handle error response.
          if (!response.ok) {
            let errorInfo = {type: 'HTTP Error', message: data.message, status: response.status, text: response.statusText, url: response.url}
            return Promise.reject(errorInfo);
          }
          // Data type validation.
          if((dataKey !== null && dataType !== null)  && typeof data[dataKey] !== dataType) {
            let errorInfo = {type: 'Data Error', wrongType: typeof data[dataKey]}
            return Promise.reject(errorInfo);
          }
          // If no errors, return hook with data.
          callback(dataKey !== null ? data[dataKey] : data);
          setStatus('success');
        }
      })
      .catch( error => {
        if(isMounted) {
          setStatus('error');
          setErrorInfo(error);
        }
      })

    // Cleaning component.
    return function cleanup() {
      isMounted = false
    }

  },[callback, dataKey, dataType, endPoint, userToken]);

  const errorLog = ({errorInfo}) => {
    switch (errorInfo.type) {
      case 'HTTP Error':
        return (
          <div className='async-wrapper__error'>
            <h3 className='error__title'>{errorInfo.type}: {errorInfo.status} {errorInfo.text}</h3>
            <p className='error__message'>{errorInfo.message}</p>
            <p className='error__url'>{errorInfo.url}</p>
          </div>
        )
      case 'Data Error':
        return (
          <div className='async-wrapper__error'>
            <h3 className='error__title'>Data type Error</h3>
            <p>Invalid type <span className='error__wrong-type'>{errorInfo.wrongType}</span> for data[{dataKey}], expected type: {dataType}</p>
          </div>
        )
      default :
        return (
            <div className='async-wrapper__error'>
              <h3>{errorInfo.name}</h3>
              <p>{errorInfo.message}</p>
            </div>
        )
    }
  }

  return (
      <div className='async-wrapper'>
        {
          status === 'loading' &&
            <div className='async-wrapper__loading'>
              <p className='loading__message'>Fetching <span className='loading__message-label'>{ label ?? 'API' }</span> data</p>
              <CircularProgress className={'loading__animation'}/>
            </div>
        }
        {
          // On successfully data eval render child component.
          status === 'success' && children
        }
        {
          (status === 'error' && errorInfo) && errorLog({errorInfo})
        }
      </div>
  )
}

export default AsyncWrapper;
