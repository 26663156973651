import React, { useState, useEffect } from "react";
import {simpleFetch, yearList} from "../../utils/helpsers";
import Grid from '@mui/material/Grid';
import DataGridTable from "../../components/Table/DataGridTable";
import LayoutPage from "../../layout/LayoutPage";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

/**
 *
 * @param {object} data
 *  The columns/rows fetched by district.
 * @returns {Object}
 *  The same object structure but updated date values.
 */
const normalizeData = (data) => {
    data[0].columns = data[0].columns.map((item) => {
        if (item.field === "election_date") {
            return { ...item, type: "date" };
        } else {
            return item;
        }
    });

    data[0].rows = data[0].rows.map((item) => ({
        ...item,
        election_date: new Date(item.election_date),
        source: item.source ? item.source["apa_ref"] : null,
    }));

    return data[0];
};

function DataViewer() {
    // Data to feed select fields.
    const [districtOptions, setDistrictOptions] = useState([]);
    //const [comparisonGroupOptions, setComparisonGroupOptions] = useState([]);

    // Object value/label from select component
    const [districtInfo, setDistrictInfo] = useState(null);
    const [year, setYear] = useState({});

    const [displayHvd, setDisplayHvd] = useState();
    const [metadataHvd, setMetadataHvd] = useState();
    const [storageHvd, setStorageHvd] = useState();

    const [viewer, setViewer] = useState();

    // User session metadata and data
    const { user } = useAuth0();
    const metadata = user[`${process.env.REACT_APP_AUTH0_KEY}/district`];

    const history = useHistory();

    const [sessionType, setSessionType] = useState();

    useEffect(() => {
        if (metadata) {
            const userSessionType = metadata.hasOwnProperty("sessionType")
                ? metadata.sessionType
                : "forbidden";
            setSessionType(userSessionType);

            switch (userSessionType) {
                case "district-access":
                    const districtData = {
                        value: parseInt(metadata.districtIrn),
                        label: metadata.districtLabel,
                    };
                    setDistrictInfo(districtData);
                    break;
                case "full-access":
                    window.localStorage.setItem("session-type", "full-access");
                    break;
                case 'multiple-district-access':
                    window.localStorage.setItem('session-type', 'multiple-district-access');
                    break;
                case "forbidden":
                    history.push("/AccessDenied");
                    break;
                default:
                    history.push("/AccessDenied");
                    break;
            }
        } else {
            history.push("/AccessDenied");
        }
    }, [history, user, metadata]);
    useEffect(() => {
        // Get districts data
        simpleFetch(
            `${process.env.REACT_APP_API_URL}/hvd_district?order=label`)
            .then((data) => setDistrictOptions(data));
    }, []);

    useEffect(() => {
        if (districtInfo) {
            let ref = districtInfo.value;

            let urlDisplay = `${process.env.REACT_APP_API_URL}/rpc/hvd_display`;
            let urlMetadata =
                `${process.env.REACT_APP_API_URL}/rpc/hvd_metadata?_year=` + year.value;
            let urlStorage =
                `${process.env.REACT_APP_API_URL}/rpc/hvd_storage?_irn=` +
                ref +
                "&_year=" +
                year.value;

            if (Object.keys(year).length > 0) {
              simpleFetch(urlDisplay).then((data) => {
                if (data[0].rows && data[0].columns) {
                  setDisplayHvd(normalizeData(data));
                  setViewer('display');
                }
              });
            }

            if (Object.keys(year).length > 0) {
                simpleFetch(urlMetadata).then((data) => {
                    if (data[0].rows && data[0].columns) {
                        setMetadataHvd(normalizeData(data));
                    }
                });

                if (ref) {
                    simpleFetch(urlStorage).then((data) => {
                        if (data[0].rows && data[0].columns) {
                            setStorageHvd(normalizeData(data));
                        }
                    });
                }
            }
        }
    }, [districtInfo, year]);

    return (
        <LayoutPage
            pageTitle="Data Viewer"
            description="Shows the raw data in the database"
            type="dashboard"
            customClass="data-viewer"
        >
            <div className="header__bottom">
                <div className="layout-wrapper">
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="data-filters__item filter-district"
                        >
                            {
                                sessionType === "full-access" || sessionType === "multiple-district-access" ? (
                                    <Autocomplete
                                        id="referenceDistrict"
                                        value={districtInfo}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        options={districtOptions}
                                        fullWidth={true}
                                        onChange={(event, newValue) => {
                                            setDistrictInfo(newValue);
                                        }}
                                        renderInput={(params) =>
                                          <TextField {...params} label="Reference district" required={true}/>
                                        }
                                        style={{marginTop: 20}}
                                    />
                                ) : (
                                    <>
                                        <div className="label">Reference district</div>
                                        <div className="district-name">
                                            {districtInfo && districtInfo.label}
                                        </div>
                                    </>
                                )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="data-filters__item filter-group-type-options"
                        >
                            <Autocomplete
                              id="year"
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              options={yearList}
                              fullWidth={true}
                              onChange={(event, newValue) => {
                                  setYear(newValue);
                              }}
                              renderInput={(params) =>
                                <TextField {...params} label={'Year'} required={true}/>
                              }
                              style={{marginTop: 20}}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <section className="levy__cards layout-wrapper">
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    {
                        (displayHvd || metadataHvd || storageHvd) &&

                            <Grid item xs={12} >
                                <div className={"data-viewer__options groups-buttons"}>
                                    {
                                        displayHvd && Object.keys(year).length > 0  &&
                                        <Button variant="text"
                                            onClick={(e) => {
                                                setViewer('display');
                                                let buttons = document.querySelectorAll('.data-viewer__btn');
                                                buttons.forEach(button => button.classList.remove('active'));
                                                e.currentTarget.classList.add('active');
                                            }}
                                            className="data-viewer__btn groups-buttons__item active">Display</Button>
                                    }
                                    {
                                        metadataHvd &&
                                        <Button variant="text"
                                            onClick={(e) => {
                                                setViewer('storage');
                                                let buttons = document.querySelectorAll('.data-viewer__btn');
                                                buttons.forEach(button => button.classList.remove('active'));
                                                e.currentTarget.classList.add('active');
                                            }}
                                            className="data-viewer__btn groups-buttons__item">Storage</Button>
                                    }
                                    {
                                        storageHvd &&
                                        <Button variant="text"
                                            onClick={(e) => {
                                                setViewer('metadata');
                                                let buttons = document.querySelectorAll('.data-viewer__btn');
                                                buttons.forEach(button => button.classList.remove('active'));
                                                e.currentTarget.classList.add('active');
                                            }}
                                            className="data-viewer__btn groups-buttons__item">Metadata</Button>
                                    }
                                </div>
                            </Grid>
                        }
                    {
                        displayHvd && Object.keys(year).length > 0 && viewer === 'display' && (
                            <Grid item xs={12} className="table-data">
                                <h2>Display</h2>
                                <DataGridTable
                                    columns={displayHvd.columns}
                                    rows={displayHvd.rows}
                                    sortField={"id"}
                                    sortOrder={"asc"}
                                />
                            </Grid>
                        )
                    }
                    {
                        metadataHvd && Object.keys(year).length > 0 && viewer === 'metadata' && (
                            <Grid item xs={12} className="table-data">
                                <h2>Metadata</h2>
                                <DataGridTable
                                    columns={metadataHvd.columns}
                                    rows={metadataHvd.rows}
                                    sortField={"id"}
                                    sortOrder={"asc"}
                                />
                            </Grid>
                        )
                    }
                    {
                        storageHvd && districtInfo && Object.keys(year).length > 0 && viewer === 'storage' && (
                            <Grid item xs={12} className="table-data">
                                <h2>Storage</h2>
                                <DataGridTable
                                    columns={storageHvd.columns}
                                    rows={storageHvd.rows}
                                    sortField={"id"}
                                    sortOrder={"asc"}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </section>
            <ReportButtons
                boardType={"Data Viewer"}
                collectedData={{
                    districtInfo: districtInfo,
                    fileYear: year,
                }}
            />
        </LayoutPage>
    );
}

export default DataViewer;
