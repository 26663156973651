import React from "react";
import styles from "./myprofile.module.scss"
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

export const MyProfile = () => {
  const { user } = useAuth0();

  return (user !== undefined &&
    <div className={`${styles.profileWrapper}`}>
      <Link className={styles.profile} to={"/Profile"}>
        <img src={user.picture} className={styles.profileLogo} alt="My Profile Icon" />
        <span className={styles.nickname}>{user.nickname}</span>
      </Link>
    </div>
  );
};
