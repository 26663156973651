import LayoutPage from "../../layout/LayoutPage";
import Container from '@mui/material/Container';

function AccessDenied() {
  return (
    <LayoutPage
      pageTitle="Access Denied"
      description="Deny Access to request data."
      type="basic">
      <Container maxWidth="md">
        <div className="error-container">
          <h1 className="error-title">Access Denied</h1>
        </div>
      </Container>
    </LayoutPage>
  );
}

export default AccessDenied;
