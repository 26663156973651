import React, { useEffect, useState, useCallback } from 'react';
import LayoutPage from "../../layout/LayoutPage";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Select from '../../components/Select/Select';
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import { ReportButtons } from "../../components/BugReport/ReportButtons";
import { useAuth0 } from "@auth0/auth0-react";

const CustomBoards = () => {
  const [boards, setBoards] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const [activeTab, setActiveTab] = useState('my_board');
  const [columns, setColumns] = useState([]);
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  const { user } = useAuth0();

  const tabs = [
    { 'name': 'My Boards', 'value': 'my_board' },
    { 'name': 'Shared with me', 'value': 'shared_with' }
  ];

  const defineColumns = useCallback((tab) => {
    if (tab === 'shared_with') {
      return [
        {
          title: 'Name', field: 'label', editable: 'never',
          render: props => {
            return (
              <a
                href={'/' + props.type.toLowerCase() + '?shared=' + props.uuid}
                target="_blank"
                rel="noopener noreferrer"
                className="btn form-submit"
              >
                {props.label}
              </a>
            );
          },
        },
        { title: 'Type', field: 'type', editable: 'never' },
        { title: 'Owner', field: 'created_by', editable: 'never' }
      ];
    }
    if (tab === 'my_board') {
      return [
        {
          title: 'Name', field: 'label',
          render: props => {
            return (
              <a
                href={'/' + props.type.toLowerCase() + '?shared=' + props.uuid}
                target="_blank"
                rel="noopener noreferrer"
                className="btn form-submit"
              >
                {props.label}
              </a>
            );
          },
        },
        { title: 'Type', field: 'type', editable: 'never' },
        {
          title: 'Shared with', field: 'available_users_share',
          render: props => {
            let available_users = [];

            if (props.available_users_share) {
              props.available_users_share.forEach(availableUser => {
                available_users.push(availableUser.label);
              })
            }

            const listUsers = available_users.map((available_user) =>
              <>
                <p>{available_user}</p>
                <Divider variant="fullWidth" />
              </>
            );

            return (
              listUsers
            );
          },
          editComponent: props => {
            let filteredUsers = users.filter((userF) => userF.group_id === activeUser.group_id ? userF : false);

            let usersFormat = filteredUsers.map((userF) => {
              let userGroup = userGroups.find(userGroup => userGroup.id === userF.group_id);
              return ({ 'value': userF.email, 'label': userF.name + ' @ ' + userGroup.name });
            });

            return (
              <Select
                options={usersFormat}
                id={'shared-with'}
                isMultiple={true}
                defaultValue={props.value}
                onChange={e => {
                  props.onChange(e)
                }}
              />
            );
          }
        }
      ];
    }
  }, [activeUser.group_id, userGroups, users]);

  const getBoards = useCallback(() => {
    if (activeTab === 'shared_with') {
      axios.get(`${process.env.REACT_APP_API_URL}/rpc/hvd_shared_boards?_email=${user.email}`,
        {
          headers: {
           'ngrok-skip-browser-warning': true  
          }
        })
        .then(res => {
          setBoards(res.data);
        });
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}/custom_boards?created_by=eq.${user.email}`,{
        headers: {
          'ngrok-skip-browser-warning': true   
        }
      })
        .then(res => {
          setBoards(res.data);
        });
    }

    axios.get(`${process.env.REACT_APP_API_URL}/groups`, {
      headers: {
        'ngrok-skip-browser-warning': true
      }
    })
      .then(res => {
        return setUserGroups(res.data);
      });
  }, [user, activeTab,]);

  useEffect(() => {
    getBoards();
    axios.get(`${process.env.REACT_APP_API_URL}/users`, {
      headers: {
        'ngrok-skip-browser-warning': true 
      }
    })
      .then(res => {
        setUsers(res.data);

        let currentUser = res.data.find((element, index) => {
          let valid = false;

          if (element.email === user.email) {
            valid = true;
          }

          return valid;
        });

        setActiveUser(currentUser);
      });
  }, [getBoards, user]);

  useEffect(() => {
    setColumns(defineColumns(activeTab));
  }, [activeTab, defineColumns]);


  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve) => {
    //validating the data inputs
    let errorList = [];

    if (errorList.length < 1) {
      axios.put(`${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${newData.uuid}`, newData, {
        headers: {
          'ngrok-skip-browser-warning': true
        }
      })
        .then(response => {
          getBoards();
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Update Failed! ${error.response.data.details}`]);
          }

          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  }

  //function for deleting a row
  const handleRowDelete = (oldData, resolve) => {
    if (oldData.created_by === user.email) {
      axios.delete(`${process.env.REACT_APP_API_URL}/custom_boards?uuid=eq.${oldData.uuid}`,
        {
          headers: {
           'ngrok-skip-browser-warning': true
          }
        })
        .then(response => {
          getBoards();
          resolve();
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            setErrorMessages([`Delete failed! ${error.response.data.details}`]);
          }
          setIserror(true);
          resolve();
        })
    } else {
      setErrorMessages(["Delete failed! Boards can only be delete by their creator"]);
      setIserror(true);
      resolve();

    }
  }

  return (
    <LayoutPage
      pageTitle='Custom Boards'
      description='View, update or delete custom boards.'
      customClass='page-crud'
    >
      <div className="layout-wrapper">
        <div className="groups-buttons">
          {
            tabs.map((tab, key) =>
              <Button
                key={key}
                className={`groups-buttons__item ${key === 0 ? 'active' : ''}`}
                onClick={(e) => {
                  let buttons = document.querySelectorAll('.groups-buttons__item');
                  buttons.forEach(button => button.classList.remove('active'));
                  e.currentTarget.className += " active";
                  setActiveTab(tab.value);
                  // setColumns(defineColumns(tab.value));
                }}
              >
                {tab.name}
              </Button>
            )
          }
        </div>
        <MaterialTable
          title="Custom Boards"
          columns={columns}
          data={boards}
          options={{
            headerStyle: { borderBottomColor: 'rgb(102, 16, 242)', borderBottomWidth: '3px' },
            actionsColumnIndex: -1,
            toolbarButtonAlignment: "left",
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          components={{
            Toolbar: props => (
              <MTableToolbar {...props} classes={{ root: "board-toolbar" }} />
            ),
          }}
          editable={
            activeTab !== 'shared_with' ?
              {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    handleRowUpdate(newData, oldData, resolve);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve)
                  }),
              } : {}
          }
        />

        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
        <ReportButtons boardType={'Groups'} />
      </div>
    </LayoutPage>
  );
}

export default CustomBoards;
