import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const LineChart = (data) => {
  const chartData = {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Historical Data'
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: data.data[0] ? data.data[0].years : [],
      title: {
        text: 'School Cycle'
      }
    },
    // yAxis: {
    //   title: {
    //     text: 'Attendance Rate'
    //   },
    // },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: false
      }
    },
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: '#fff'
        }
      }
    },
    series: data.data
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartData}
    />
  )
}

export default LineChart;
