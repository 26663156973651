import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { deepPurple } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';

const TreeItemStyles = styled((props) => (
  <TreeItem {...props}/>
))(({ theme }) => ({
  ".labelRoot": {
    alignItems: "center",
    padding: useTheme().spacing(0.5, 0),
  },
  ".labelTitle": {
    fontWeight: "bold",
    flexGrow: 1,
    margin: 10,
  },
  ".labelText": {
    fontWeight: "inherit",
    flexGrow: 1,
    margin: 10,
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: "1px dashed rgba(0, 0, 0, 0.4)",
  },
}));

function StyledTreeItem(props) {
  //const classes = useTreeItemStyles(props);
  const { bigBranch, title, subtitle, color, bgColor, ...other} = props;

  return (
    <TreeItemStyles
      onClick={() => {
        window._paq.push([
          "trackEvent",
          "Board Glossary",
          `Clicked: ${title}`,
        ]);
      }}
      label={
        bigBranch ? (
          <div className="labelRoot" >
            <Typography variant="h4" className="labelTitle">
              {title}
            </Typography>
          </div>
        ) : (
          <div className="labelRoot">
            <Typography variant="h6" className="labelTitle">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle1" className="labelText">
                {subtitle}
              </Typography>
            )}
          </div>
        )
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

const TreeViewStyles = styled(TreeView)(({ theme }) => ({
  '&.root': {
    height: "100%",
    flexGrow: 1,
    maxWidth: "100%",
  },
}));

function MinusSquare(props) {
  return (
    <SvgIcon
      style={{
        width: 20,
        height: 20,
        background: deepPurple[300],
        color: "white",
      }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon
      fontSize="inherit"
      style={{
        width: 20,
        height: 20,
        background: deepPurple[300],
        color: "white",
      }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function GroupedTree(props) {
  let { data, groupBy } = props;
  //const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  // Simplest way to fetch the props promises in a functional component.
  useEffect(() => null, [props.data, props.groupBy]);

  if (!Object.keys(data).length || !Object.keys(groupBy).length) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop:"10px" }}>
        <CircularProgress size={80}/>
      </Box>
    );
  }

  return (
    <TreeViewStyles
      className="root"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      expanded={expanded}
      onNodeToggle={handleChange}
      sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {groupBy.map(({ id, items }) => (
        <div key={`group-${id}`}>
          <StyledTreeItem
            key={`tree-branch-${id}`}
            bigBranch={true}
            title={id}
            nodeId={id}
          >
            {items.map((keyItem) => (
              <div
                id={`tree-node-${keyItem}`}
                className="glossary__card-items"
                key={`tree-node-${keyItem}`}
              >
                <StyledTreeItem
                  key={`${data[keyItem].id}`}
                  nodeId={`${data[keyItem].id}`}
                  title={data[keyItem].title}
                >
                  <div className="glossary__description">
                    <span>{data[keyItem].description}</span>
                    <br></br>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          {data[keyItem].rows.map((row, i) => (
                            <TableRow
                              key={`${keyItem}-row-${i}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              className="glossary__row-color"
                            >
                              <TableCell component="th" scope="row">
                                {row[0]}
                              </TableCell>
                              <TableCell align="justify">{row[1]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {data[keyItem].footnote && (
                      <StyledTreeItem
                        key={`${keyItem}-note`}
                        nodeId={`${keyItem}-note`}
                        title={"Note"}
                      >
                        {data[keyItem].footnote}
                      </StyledTreeItem>
                    )}
                  </div>
                </StyledTreeItem>
              </div>
            ))}
          </StyledTreeItem>
        </div>
      ))}
    </TreeViewStyles>
  );
}

export default GroupedTree;
