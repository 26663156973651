import { useEffect } from "react";

export default function HelpDeskReport({ type, onClose }) {
  useEffect(() => {
    (function () {
      window.ybug_settings = {
        id: "604kmc6363bv4093caff",
        hide_launcher: true,
        type: false,
        skip_to: 'feedback',
        username: window.localStorage.getItem('user_email'),
        user: {
          username: window.localStorage.getItem('user_email'),
          sessionType: window.localStorage.getItem('session-type'),
          board: type,
        },
        feedback: {
          email: window.localStorage.getItem('user_email'),
        },
        translate: {
          "feedbackForm.Heading": "Group Admin Support",
          "feedbackForm.TextPlaceholder": "Write a comment or describe a problem and one of our representatives will get in touch with you.",
          "feedbackTypeForm.Subheading": "Do you have any comments or suggestions about this site?",
          "thankYouModal.Text": "One of our representatives will get in touch with you.",
        },
        onclose: () => onClose(),
      }
      const loadYbug = new Promise(resolve => {
        let ybug = document.createElement('script');
        ybug.type = 'text/javascript';
        ybug.async = false;
        ybug.src = 'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js';
        ybug.addEventListener('load', resolve, {
          once: true
        });
        let s = document.getElementsByTagName('script')[0];
        document.getElementById('helpReportContainer').append(ybug, s)
      });
      loadYbug.then(() => window.Ybug.open());
    })();
  }, [type, onClose])

  // Div required to append the Ybug window.
  return <div id="helpReportContainer"></div>;
}
