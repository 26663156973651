import React from "react";
import styles from "../Header/header.module.scss"
import logo from '../../logo-website.png';

const BrandBlock = () => {
  return (
    <div className={styles.branding}>
      <img src={logo} className={styles.brandingLogo} alt="Crocus | Growth towards impact" />
    </div>
  )
}

export default BrandBlock;
