import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { buildTooltip } from "../../utils/helpsers";
import logoSignature from "../../IB_Signature.png";
import './scatter-chart.scss';


const ScatterChart = (props) => {
  let xDescription = props.x.description;
  let yDescription = props.y.description;
  let xAxisTitle = xDescription
    ? `<span>${props.x.label}</span><span class="info-icon" id="scatter-axis-x">ⓘ</span>`
    : props.x.label;
  let yAxisTitle = yDescription
    ? `<span>${props.y.label}</span><span class="info-icon" id="bubble-axis-y">ⓘ</span>`
    : props.y.label;

  useEffect(() => {
    if (xDescription) {
      let xInfoIcon = document.getElementById("scatter-axis-x");
      buildTooltip({ infoIcon: xInfoIcon, info: xDescription });
    }

    if (yDescription) {
      let yInfoIcon = document.getElementById("bubble-axis-y");
      buildTooltip({ infoIcon: yInfoIcon, info: yDescription });
    }
  });

  let logoCredit;
  const ScatterData = {
    chart: {
      type: "scatter",
      height: 620,
      backgroundColor: "rgba(0,0,0,0)",
      plotBorderWidth: 1,
      zoomType: "xy",
      events: {
        render: function () {
          let points = this.series[0].points;
          points.forEach((point) => {
            if (point.selected === true) {
              this.tooltip.refresh(point);
            }
          });
        },
        load: function () {
          if (this.options.chart.forExport) {
            this.renderer.image(`${logoSignature}`, 10, 600, 150, 20).add();
          }
        },
        beforePrint: function () {
          logoCredit = this.renderer
            .image(`${logoSignature}`, 10, 600, 150, 20)
            .add();
          this.print();
        },
        afterPrint: function () {
          logoCredit.element.remove();
        },
      },
      style: {
        fontFamily: ["Titillium Web", "sans-serif"],
      },
      className: 'highcharts--scatter-chart'
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueDescriptionFormat:
          "{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.",
      },
    },
    xAxis: {
      gridLineWidth: 1,
      title: {
        useHTML: true,
        text: xAxisTitle,
      },
      labels: {
        format: "{value}",
      },
      accessibility: {
        rangeDescription: "",
      },
      plotLines: [
        {
          dashStyle: "shortdash",
          value: props.xCustomRange ? props.xCustomRange[0] : 0,
          width: props.xCustomRange ? 2 : 0,
          color: "#00D7BE",
        },
        {
          dashStyle: "shortdash",
          value: props.xCustomRange ? props.xCustomRange[1] : 0,
          width: props.xCustomRange ? 2 : 0,
          color: "#00D7BE",
        },
      ],
    },
    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        useHTML: true,
        text: yAxisTitle,
      },
      labels: {
        format: "{value}",
      },
      maxPadding: 0.2,
      accessibility: {
        rangeDescription: "",
      },
      plotLines: [
        {
          dashStyle: "shortdash",
          value: props.yCustomRange ? props.yCustomRange[0] : 0,
          width: props.yCustomRange ? 2 : 0,
          color: "#00D7BE",
        },
        {
          dashStyle: "shortdash",
          value: props.yCustomRange ? props.yCustomRange[1] : 0,
          width: props.yCustomRange ? 2 : 0,
          color: "#00D7BE",
        },
      ],
    },
    tooltip: {
      useHTML: true,
      headerFormat: "<table>",
      pointFormat:
        '<tr><th colspan="2"><h3>{point.district_name}</h3></th></tr>' +
        "<tr><th>" +
        props.x.label +
        "</th><td>{point.x}</td></tr>" +
        "<tr><th>" +
        props.y.label +
        "</th><td>{point.y}</td></tr>",
      footerFormat: "</table>",
      followPointer: true,
      backgroundColor: "#FFF",
      hideDelay: 0,
    },
    exporting: {
      tableCaption: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              text: "Download PDF document",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  "Downloaded: PDF in Scatter chart",
                ]);
                this.exportChart({
                  type: "application/pdf",
                });
              },
            },
            {
              text: "Download CSV",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  "Downloaded: CSV in Scatter chart",
                ]);
                this.downloadCSV();
              },
            },
            {
              text: "Download XLS",
              onclick: function () {
                window._paq.push([
                  "trackEvent",
                  "Board DistrictExplorer",
                  "Downloaded: XLS in Scatter chart",
                ]);
                this.downloadXLS();
              },
            },
          ],
        },
      },
      chartOptions: {
        chart: {
          backgroundColor: "#fff",
        },
      },
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!item || item instanceof Highcharts.Axis) {
            return props.x.label;
          } else {
            return item.name;
          }
        },
      },
    },
    plotOptions: {
      scatter: {
        marker: {
          states: {
            select: {
              lineColor: "#F5B335",
              fillColor: "#F5B335",
            },
          },
        },
      },
      series: {
        point: {
          events: {
            mouseOver: function () {
              const labels = document.querySelector('.highcharts--scatter-chart div.highcharts-yaxis');
              labels.classList.add('to-back');
            },
            mouseOut: function () {
              const labels = document.querySelector('.highcharts--scatter-chart div.highcharts-yaxis');
              labels.classList.remove('to-back');
            }
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    },
    series: [
      {
        keys: ["district_name"],
        name: "District",
        data: props.options,
        color: "#4646FF",
      },
    ],
  };

  switch (props.options) {
    case "loading":
      return (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop:"10px" }}>
          <CircularProgress size={80}/>
        </Box>
      );
    case "no-data":
      return <div className="no-data">MISSING PARAMETER</div>;
    default:
      return <HighchartsReact highcharts={Highcharts} options={ScatterData} />;
  }
};

export default ScatterChart;
