import React, { useEffect, useRef, useState } from 'react';
import { simpleFetch, getToken } from "../../utils/helpsers";
import CircularProgress from '@mui/material/CircularProgress';
import DefinitionCard from "./DefinitionCard";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    border: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
  input: (provided) => ({
    ...provided,
    color: '#000',
  })
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

export default function DefinitionsCardSelector(props) {
  const { sourceURL } = props;
  const ref = useRef();
  const [variablesGroup, setVariablesGroup] = useState();
  const [currentCard, setCurrentCard] = useState();
  const [showCard, setShowCard] = useState(false);

  useOnClickOutside(ref, () => setShowCard(false));

  const displayCard = (item) => {
    setCurrentCard(DefinitionCard(item));
    setShowCard(true);
  }

  useEffect(() => {
    simpleFetch(sourceURL, getToken())
      .then(response => {
        const data = {};
        response.map(elem => {
          let label = elem.label.substring(elem.label.indexOf('/') + 1);
          if (!(label in data)) {
            data[label] = {
              value: elem.value,
              title: label,
              label: label,
              subtitle: elem.domain,
              description: elem.description,
              rows: [
                ['Data Type', elem.var_type ? elem.var_type : 'No data Type available.'],
                ['Authority', elem.author ? elem.author : 'No source available.'],
                ['Last Update', elem.file_year ? elem.file_year : 0],
                ['Years Available', elem.available_years ? elem.available_years : []],
                [elem.dwh_table === 'calculated' ? 'Formula' : 'Notes', elem.notes ? elem.notes : 'No note available.'],
                ['Source', elem.source ? elem.source.apa_ref ? elem.source.apa_ref : 'No APA reference available.' : 'No source available.'],
              ],
              note: elem.note
            }
          }

          for (let i = 0; i < data[label].rows[3][1].length; i++) {
            if (data[label].rows[3][1][i] === elem.file_year) {
              data[label].rows[3][1][i] = <span> <a href={elem.source.r_page}>{elem.file_year}</a>{i === data[label].rows[3][1].length - 1 ? '' : ','}</span>;
            }
          }


          if (data[label].rows[3][1] < elem.file_year) {
            data[label].rows[3][1] = elem.file_year;
          }

          return data;
        })
        return data;
      })
      .then(data => setVariablesGroup(data))
      .catch(error => alert(error.response.data.details));
  }, [sourceURL]);

  return (
    !variablesGroup ?
      <CircularProgress height={30} width={30} /> :
      <div className={'data-filters__item filter-district'} style={{marginTop:20}}>
          <Autocomplete
              sx={{backgroundColor: "white"}}
              id="glossary-terms"
              className={"definitions__select form-select"}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={Object.values(variablesGroup)}
              fullWidth={true}
              onChange={(event, newValue) => {
                  if(newValue) return displayCard(newValue)
              }}
              renderInput={(params) =>
                  <TextField {...params} label={'Lookup Definitions:'} />
              }
              styles={customStyles}
          />
        {showCard &&
          <div ref={ref} style={{ position: 'absolute', marginTop: '1em', maxWidth: '70%' }}>
            {currentCard}
          </div>}
      </div>
  );

}
